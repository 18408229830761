
import { cesiumService } from "../../cesium/cesium_service_inst";
import { MissionItem, MissionItemType } from "./mission_manager";
const _mission_entitys:Map<string,any> = new Map();
const _mission_floor_entitys:Map<string,any> = new Map();

export default class Mission {
  private _alias: string;
  private _id: string;

  private _account: string;
  
  private _mission: any;
  private _mission_point: any;
  private _cb_mission: any;

  private _main_color: any;
  private _color: any;

  private _mission_items: MissionItem[];

  private _mission_distance = 0;

  constructor(alias: string, account: string, id:any) {
    const Cesium = cesiumService.GetCesium();

    this._mission_items = [];

    this._alias = alias;
    this._id = id;
    this._account = account;
    this._mission = [];
    // const color = Cesium.Color.fromRandom({
    //   maximumAlpha: 1.0,
    //   minimumAlpha: 1.0,
    // });
    const color = Cesium.Color.fromCssColorString('#00B1FE');
    this._main_color = color;
    this._color = new Cesium.ColorMaterialProperty(color);
  }

  private init() {
    
      // this._mission_point.forEach((data: any, index: number) => {
        //   const lnglat = [data.lng, data.lat];
        //   const height = data.display_alt;
        //   const radius = 4;
      //   const units: turf.Units = "meters";
      //   const options = { steps: 10, units: units, properties: undefined };
      //   const circle = turf.circle(lnglat, radius, options);
      //   const circle_positions: any[] = [];
      //   circle.geometry.coordinates[0].forEach((coordinate) => {
      //     circle_positions.push(coordinate[0]);
      //     circle_positions.push(coordinate[1]);
      //     circle_positions.push(height + 1);
      //   });

      //   const hole = turf.circle(lnglat, 3, options);
      //   const hole_positions: any[] = [];
      //   hole.geometry.coordinates[0].forEach((coordinate) => {
      //     hole_positions.push(coordinate[0]);
      //     hole_positions.push(coordinate[1]);
      //     hole_positions.push(height + 1);
      //   });
      //   const hpr = new window.Cesium.HeadingPitchRoll(90, 180, 90);
      //   // const orientation = Cesium.Transforms.headingPitchRollQuaternion(
      //   //   this.test_mission[this.test_mission.length - 1].clone(),
      //   //   hpr
      //   // );
      //   viewer.entities.add({
      //     id: `altitude_${data._id}`,
      //     position: Cesium.Cartesian3.fromDegrees(data.lng, data.lat, data.display_alt),
      //     ellipsoid: {
      //       radii: new Cesium.Cartesian3(2, 2, 2),
      //       material: new Cesium.ColorMaterialProperty(Cesium.Color.MEDIUMBLUE),
      //     },
      //   });
      //   viewer.entities.add({
      //     id: `lnglat_${data._id}`,
      //     position: Cesium.Cartesian3.fromDegrees(data.lng, data.lat, data.display_alt),
      //     polygon: {
      //       hierarchy: {
      //         positions:
      //           Cesium.Cartesian3.fromDegreesArrayHeights(circle_positions),
      //         holes: [
      //           {
      //             positions:
      //               Cesium.Cartesian3.fromDegreesArrayHeights(hole_positions),
      //           },
      //         ],
      //       },
      //       extrudedHeight: height - 1,
      //       perPositionHeight: true,
      //       material: new Cesium.ColorMaterialProperty(
      //         Cesium.Color.BLACK.withAlpha(0.5)
      //       ),
      //       stRotation: Cesium.Math.toRadians(45),
      //     },
      //     // orientation: orientation,
      //   });
      // });
  }

  // DestroySelf() {
  //     const viewer = cesiumService.GetViewer();

  //     this._mission_entity._children.forEach((child:any) => {
  //         viewer.entities.removeById(child._id);
  //     });

  //     viewer.entities.removeById(this._name);
  // }

  // GetColor() {
  //     return this._color.color.getValue().toCssColorString();
  // }
  // SetMissionView() {
  //   const viewer = cesiumService.GetViewer();
  //   const Cesium = cesiumService.GetCesium();
  //   this._cb_mission = this._mission;
  //   let VisibleCheck = true
  //   // 미션이 Unvisible 상태이면 안보이게
  //   store.getters.GetMissionUnvisibleList.forEach((mission:MissionObject)=>{
  //     if(mission.alias == this._alias) {
  //       VisibleCheck = false
  //     }
  //   })
  //   console.log('store.getters.GetDeleteMission', store.getters.GetDeleteMission)
  //   if(store.getters.GetDeleteMission.length == 0 && VisibleCheck) { // 미션을 삭제할 때는 entity 셋팅 안하게
  //     const mission_entity = viewer.entities.getOrCreateEntity(this._alias + "-mission-path");
  //     _mission_entitys.set(this._alias + "-mission-path", mission_entity)
  //     mission_entity.name = this._alias;
  //     mission_entity.polyline = {
  //       positions: this._mission,
  //       material: new Cesium.PolylineGlowMaterialProperty({
  //         glowPower: 0.25,
  //         color: this._main_color,
  //       }),
  //       width: 5,
  //       arcType: Cesium.ArcType.NONE,
  //     };

  //     const mission_floor_entity = viewer.entities.getOrCreateEntity(this._alias + "-mission-floor");
  //     _mission_floor_entitys.set(this._alias + "-mission-floor", mission_floor_entity)
  //     mission_floor_entity.polyline = {
  //       positions: this._mission,
  //       width: 5,
  //       clampToGround: true,
  //     };
  //   }
  // }

  Get() {
    return this;
  }

  GetMission_id() {
    return this._id;
  }

  GetAccount() {
    return this._account;
  }

  GetName() {
    return this._alias;
  }

  GetMissionItems() {
    return this._mission_items;
  }

  GetMissionDistance() {
    return this._mission_distance;
  }

  private setTotalDistance() {
    const Cesium = cesiumService.GetCesium();
    let from:MissionItem|undefined = undefined;
    let to:MissionItem|undefined = undefined;

    let distance = 0;
    this._mission_items.forEach((mission_item:MissionItem, index:number) => {
      if(mission_item.type != MissionItemType.WAYPOINT) return;
      if(from == undefined) {
        from = mission_item;
      }
      else {
        to = mission_item;

        const distance_meters = Cesium.Cartesian3.distance(
          new Cesium.Cartesian3.fromDegrees(
            from.lng,
            from.lat,
            from.agl
          ),
          new Cesium.Cartesian3.fromDegrees(
            to.lng,
            to.lat,
            to.agl
          )
        );

        distance += distance_meters;

        from = mission_item;
      }
    });

    this._mission_distance = distance;
  }

  AddItem(mission_item: MissionItem) {
    this._mission_items.push(mission_item);
    this.AddMission(mission_item);
    this.setTotalDistance();
  }

  // SetSelectedMissionEntities() {
  //   console.log('aaadddsssfff')
  // }

  // ModifyItem(mission_index: number, position: any) {
  //   // console.log(this._mission[mission_index])
  //   this._mission[mission_index] = position;

  //   const Cesium = cesiumService.GetCesium();
  //   const mission_position_cartesian = position.clone();
  //   const mission_position_cartographic = Cesium.Cartographic.fromCartesian(
  //     mission_position_cartesian
  //   );
  //   // console.log(mission_position_cartographic)
  //   this._mission_items[mission_index].lng = Cesium.Math.toDegrees(
  //     mission_position_cartographic.longitude
  //   );
  //   this._mission_items[mission_index].lat = Cesium.Math.toDegrees(
  //     mission_position_cartographic.latitude
  //   );
  //   this._mission_items[mission_index].display_alt =
  //     mission_position_cartographic.height;

  //   this._mission_entity._children.forEach((child: any) => {
  //     if (
  //       child._id.endsWith(`altitude-handle${mission_index + 1}`) ||
  //       child._id.endsWith(`bundle-first${mission_index + 1}`) ||
  //       child._id.endsWith(`bundle-second${mission_index + 1}`)
  //     ) {
  //       child.position = position;
  //     } else if (child._id.endsWith(`lnglat-handle${mission_index + 1}`)) {
  //       const coord = Cesium.Cartographic.fromCartesian(position);

  //       const lng = Cesium.Math.toDegrees(coord.longitude);
  //       const lat = Cesium.Math.toDegrees(coord.latitude);

  //       const lnglat = [lng, lat];
  //       const height = coord.height;

  //       const radius = 4;
  //       const units: turf.Units = "meters";
  //       const options = { steps: 10, units: units, properties: undefined };

  //       const circle = turf.circle(lnglat, radius, options);
  //       const circle_positions: any[] = [];
  //       circle.geometry.coordinates[0].forEach((coordinate) => {
  //         circle_positions.push(coordinate[0]);
  //         circle_positions.push(coordinate[1]);
  //         circle_positions.push(height + 1);
  //       });

  //       const hole = turf.circle(lnglat, 3, options);
  //       const hole_positions: any[] = [];
  //       hole.geometry.coordinates[0].forEach((coordinate) => {
  //         hole_positions.push(coordinate[0]);
  //         hole_positions.push(coordinate[1]);
  //         hole_positions.push(height + 1);
  //       });

  //       child.position = position;
  //       child.polygon = {
  //         // hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(circle_positions),
  //         hierarchy: {
  //           positions:
  //             Cesium.Cartesian3.fromDegreesArrayHeights(circle_positions),
  //           holes: [
  //             {
  //               positions:
  //                 Cesium.Cartesian3.fromDegreesArrayHeights(hole_positions),
  //             },
  //           ],
  //         },
  //         extrudedHeight: height - 1,
  //         perPositionHeight: true,
  //         material: new Cesium.ColorMaterialProperty(
  //           Cesium.Color.BLACK.withAlpha(0.5)
  //         ),
  //         stRotation: Cesium.Math.toRadians(45),
  //       };
  //     }
  //   });
  // }

  private AddMission(mission_item: MissionItem) {
    if (!this._cb_mission) {
      this.init();
    }
    const viewer = cesiumService.GetViewer();
    const Cesium = cesiumService.GetCesium();
    const cartographic = Cesium.Cartographic.fromDegrees(
      mission_item.lng,
      mission_item.lat,
      mission_item.display
    );

    // 동일 좌표 pick 방지
    if (this._mission.length > 0) {
      if (
        Cesium.Cartesian3.distance(
          this._mission[this._mission.length - 1],
          Cesium.Cartographic.toCartesian(cartographic)
        ) < 3
      ) {
        return;
      }
    }
    if(mission_item.type == MissionItemType.WAYPOINT || mission_item.type == MissionItemType.LAND) {
      this._mission.push(Cesium.Cartographic.toCartesian(cartographic));
    }

    // this._mission.push(Cesium.Cartographic.toCartesian(cartographic));
    // // this._mission_entity_floor_positions.push(Cesium.Cartographic.toCartesian(cartographic))

    // // if(this._mission.length == 1)
    // // {
    // //     const child = viewer.entities.getOrCreateEntity(this._name+'-child-ellipsoid-start' + this._mission.length);
    // //     child.position = cesiumService.GetCesium().Cartographic.toCartesian(cartographic);
    // //     child.ellipsoid = {
    // //         radii: new Cesium.Cartesian3(5,5,5),
    // //         material: new Cesium.ColorMaterialProperty(Cesium.Color.RED),
    // //     }
    // //     child.label = {
    // //         text: `Start`,
    // //         font: '16px NotoMedium',
    // //         style: Cesium.LabelStyle.FILL,
    // //         material: this._color,
    // //         // pixelOffset: new Cesium.Cartesian2(100, 0),
    // //         backgroundColor: Cesium.Color.BLACK.withAlpha(0.5),
    // //         showBackground: true,
    // //     }
    // //     child.parent = this._mission_entity;
    // // }
    // // else
    // {
    //   const child = viewer.entities.getOrCreateEntity(
    //     this._name + "-child-altitude-handle" + this._mission.length
    //   );
    //   child.position = cesiumService
    //     .GetCesium()
    //     .Cartographic.toCartesian(cartographic);
    //   child.name = (this._mission.length - 1).toString();
    //   child.ellipsoid = {
    //     radii: new Cesium.Cartesian3(2, 2, 2),
    //     material: this._color,
    //   };
    //   // child.label = {
    //   //     text: this._mission.length + ` | ${mission_item.lng.toFixed(6)}/${mission_item.lat.toFixed(6)}/${mission_item.asl}`,
    //   //     font: '10px NotoMedium',
    //   //     style: Cesium.LabelStyle.FILL,
    //   //     material: this._color,
    //   //     pixelOffset: new Cesium.Cartesian2(100, 0),
    //   //     backgroundColor: Cesium.Color.BLACK.withAlpha(0.5),
    //   //     showBackground: true,
    //   // }
    //   child.parent = this._mission_entity;
    // }

    // // {
    // //     const child = viewer.entities.getOrCreateEntity(this._name+'-child-box' + this._mission.length);
    // //     const new_position = cartographic.clone();
    // //     new_position.height = new_position.height / 2;
    // //     child.position = cesiumService.GetCesium().Cartographic.toCartesian(new_position);
    // //     child.box = {
    // //         dimensions: new Cesium.Cartesian3(1, 1, cartographic.height),
    // //         material: this._color,
    // //     }
    // //     child.parent = this._mission_entity;
    // // }

    // {
    //   const child = viewer.entities.getOrCreateEntity(
    //     this._name + "-child-bundle-first" + this._mission.length
    //   );
    //   child.position = cesiumService
    //     .GetCesium()
    //     .Cartographic.toCartesian(cartographic);
    //   child.ellipse = {
    //     semiMinorAxis: 4.0,
    //     semiMajorAxis: 4.0,
    //     material: new Cesium.ColorMaterialProperty(
    //       Cesium.Color.BLACK.withAlpha(0.3)
    //     ),
    //     outline: true,
    //     outlineColor: this._main_color,
    //     outlineWidth: 3,
    //     height: 0,
    //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, //RELATIVE_TO_GROUND,//CLAMP_TO_GROUND,
    //   };
    //   child.parent = this._mission_entity;
    // }

    // // {
    // //     const child = viewer.entities.getOrCreateEntity(this._name+'-child-bundle-second' + this._mission.length);
    // //     const color = new Cesium.ColorMaterialProperty(Cesium.Color.BLACK.withAlpha(1));
    // //     child.position = cesiumService.GetCesium().Cartographic.toCartesian(cartographic);
    // //     child.ellipse = {
    // //         semiMinorAxis: 4.0,
    // //         semiMajorAxis: 4.0,
    // //         material: color,
    // //     }
    // //     child.parent = this._mission_entity;
    // // }

    // // if (this._mission.length > 1) {

    // //     const pre_coord = Cesium.Cartographic.fromCartesian(this._mission[this._mission.length - 2]).clone();
    // //     const next_coord = Cesium.Cartographic.fromCartesian(this._mission[this._mission.length - 1]).clone();

    // //     const pre_lng = Cesium.Math.toDegrees(pre_coord.longitude);
    // //     const pre_lat = Cesium.Math.toDegrees(pre_coord.latitude);

    // //     const pre = [pre_lng, pre_lat];
    // //     const pre_height = pre_coord.height;

    // //     const next_lng = Cesium.Math.toDegrees(next_coord.longitude);
    // //     const next_lat = Cesium.Math.toDegrees(next_coord.latitude);

    // //     const next = [next_lng, next_lat];
    // //     const next_height = next_coord.height;

    // //     console.log(pre_height, next_height)

    // //     const line_for_offset: any = turf.lineString([pre, next]);
    // //     const offset_1 = turf.lineOffset(line_for_offset,  1, { units: 'meters' })
    // //     const offset_2 = turf.lineOffset(line_for_offset, -1, { units: 'meters' })

    // //     const child = viewer.entities.getOrCreateEntity(this._name+'-child-path' + this._mission.length);
    // //     child.parent = this._mission_entity;
    // //     child.polygon = {
    // //         hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights([
    // //             offset_1.geometry.coordinates[0][0],
    // //             offset_1.geometry.coordinates[0][1],
    // //             pre_height,
    // //             offset_2.geometry.coordinates[0][0],
    // //             offset_2.geometry.coordinates[0][1],
    // //             pre_height,
    // //             offset_2.geometry.coordinates[1][0],
    // //             offset_2.geometry.coordinates[1][1],
    // //             next_height,
    // //             offset_1.geometry.coordinates[1][0],
    // //             offset_1.geometry.coordinates[1][1],
    // //             next_height,
    // //             offset_1.geometry.coordinates[0][0],
    // //             offset_1.geometry.coordinates[0][1],
    // //             pre_height,

    // //         ]),
    // //         // extrudedHeight: (pre_height > next_height) ? next_height - 1 : pre_height - 1,
    // //         // extrudedHeight: 0,
    // //         perPositionHeight: true,
    // //         material: new Cesium.ColorMaterialProperty(Cesium.Color.BLACK.withAlpha(0.5)),
    // //         outline: true,
    // //         outlineColor: Cesium.Color.WHITE,
    // //         closeTop: true,
    // //         closeBottom: true,
    // //     };
    // // }

    // // if (this._mission.length > 1)
    // {
    //   const coord = Cesium.Cartographic.fromCartesian(
    //     this._mission[this._mission.length - 1]
    //   );

    //   const lng = Cesium.Math.toDegrees(coord.longitude);
    //   const lat = Cesium.Math.toDegrees(coord.latitude);
    //   const lnglat = [lng, lat];
    //   const height = coord.height;

    //   const radius = 4;
    //   const units: turf.Units = "meters";
    //   const options = { steps: 10, units: units, properties: undefined };

    //   const circle = turf.circle(lnglat, radius, options);
    //   const circle_positions: any[] = [];
    //   circle.geometry.coordinates[0].forEach((coordinate) => {
    //     circle_positions.push(coordinate[0]);
    //     circle_positions.push(coordinate[1]);
    //     circle_positions.push(height + 1);
    //   });

    //   const hole = turf.circle(lnglat, 3, options);
    //   const hole_positions: any[] = [];
    //   hole.geometry.coordinates[0].forEach((coordinate) => {
    //     hole_positions.push(coordinate[0]);
    //     hole_positions.push(coordinate[1]);
    //     hole_positions.push(height + 1);
    //   });
    //   const hpr = new window.Cesium.HeadingPitchRoll(90, 180, 90);
    //   const orientation = Cesium.Transforms.headingPitchRollQuaternion(
    //     this._mission[this._mission.length - 1].clone(),
    //     hpr
    //   );
    //   const child = viewer.entities.getOrCreateEntity(
    //     this._name + "-child-lnglat-handle" + this._mission.length
    //   );
    //   child.position = cesiumService
    //     .GetCesium()
    //     .Cartographic.toCartesian(cartographic);
    //   child.parent = this._mission_entity;
    //   child.name = (this._mission.length - 1).toString();
    //   child.orientation = orientation;
    //   child.polygon = {
    //     // hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(circle_positions),
    //     hierarchy: {
    //       positions:
    //         Cesium.Cartesian3.fromDegreesArrayHeights(circle_positions),
    //       holes: [
    //         {
    //           positions:
    //             Cesium.Cartesian3.fromDegreesArrayHeights(hole_positions),
    //         },
    //       ],
    //     },
    //     extrudedHeight: height - 1,
    //     perPositionHeight: true,
    //     material: new Cesium.ColorMaterialProperty(
    //       Cesium.Color.RED.withAlpha(0.5)
    //     ),
    //     stRotation: Cesium.Math.toRadians(45),
    //   };
    // }

    return this._mission;
  }
}
