
import DroneTV from "@/components/DroneTV/DroneTV.vue";
import ScreenManager from "@/services/hg/screen_manager";
import store from "@/store";
import { computed, defineComponent } from "vue";
import VueDraggableResizable from "vue-draggable-resizable";

export default defineComponent({
  data() {
    return {
      tv_count: 1 as number,
      max_tv_count: 1 as number,
      expand_width: false as boolean,
    };
  },

  setup() {
    const show_drone_tv = computed<boolean>(() => store.getters.GetShowDroneTV);
    return { show_drone_tv };
  },

  components: { DroneTV, VueDraggableResizable },

  methods: {
    add_tv() {
      if (this.tv_count < this.max_tv_count) {
        this.tv_count++;
      }
    },
    minus_tv() {
      if (this.tv_count > 0) {
        this.tv_count--;
      }
    },
    change_width() {
      this.expand_width = !this.expand_width;
    },
    zindex(id:string) {
      ScreenManager.getInstance().SetZ_Index(id)
    }
  },
});
