
import CesiumScreenSpaceEventService, {
  GCS_FEATURE, GCS_MODE
} from "@/services/cesium/cesium_screen_space_ev_service";
import DroneManager from "@/services/hg/drone_manager";
import MissionManager, { MissionItemType, MissionObject } from "@/services/hg/mission/mission_manager";
import SimulateManager from "@/services/hg/simulate/simulate_manager";
import { DroneMissionSend } from "@/services/ws/ws_hg_server_message_sender";
import store from "@/store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      mouseover_state:false,
      mission_manager: MissionManager.getInstance() as MissionManager,
      tab_open: false,
      group_simulation_state: false,
      };
  },

  setup() {
    const now_selected_drone = computed(() => store.getters.GetNowSelectedDrone);
    const side_mission_tab = computed(() => store.getters.GetSideMissionTab);
    const state = computed(() => store.getters.GetSideMissionTabState);
    const side_mission_Detail_tab = computed(() => store.getters.GetSideMissionDetailTab);
    const missions = computed<MissionObject[]>(() => store.getters.GetMissions);
    const accountID = computed(() => store.getters.GetAccountID);
    return { side_mission_tab, side_mission_Detail_tab, missions, state, now_selected_drone, accountID };
  },

  watch: {
    side_mission_tab: {
      handler(n, o) {
        this.tab_open = n;
      },
    },
  },

  methods: {
    PresetBtn(mission: MissionObject) {
      store.commit("SetMissionData", mission);
      store.commit("SetSideMissionTab", !this.side_mission_tab);
      store.commit("SetSideMissionDetailTab", !this.side_mission_Detail_tab);
      store.commit("SetSelectMissionName", mission.alias);
      MissionManager.getInstance().SetGCSMode(GCS_MODE.MISSION_UPDATE)
      MissionManager.getInstance().SetSelectedMissionEntity(mission.alias);
      MissionManager.getInstance().SetModifyMission(mission.items)
      MissionManager.getInstance().SetMissionAlias(mission.alias)
      CesiumScreenSpaceEventService.getInstance().setMissionMode(MissionItemType.NONE)
      store.commit("SetFacialRecognition", false);
      store.commit("SetSideMenuTab", false);
      store.commit("SetShowSingle", false);
      store.commit("SetLogMessageState", false)
      CesiumScreenSpaceEventService.getInstance().SetMissionCameraMove(mission)
    },
    onClickSelectMission(mission_object: MissionObject) {
      const selected_drone_name:string = store.getters.GetNowSelectedDrone;
      const selected_drone = DroneManager.getInstance().GetDrone(selected_drone_name)
      if(selected_drone) {
        console.log('DroneMissionSend')
        DroneMissionSend(selected_drone, mission_object)
      }
      store.commit("SetAssignedMissionList", {
        drone_name: this.now_selected_drone, mission: mission_object
      });
      store.commit("SetSideMissionTab", false);
      store.commit("SetSideDroneTab", true);
      const current_mission = store.getters.GetMissions
      current_mission.forEach((mission:MissionObject)=>{
        MissionManager.getInstance().SetMissionEntityUnvisible(mission)
      })
    },
    onClickNewMission() {
      CesiumScreenSpaceEventService.getInstance().SetMode(GCS_FEATURE.MISSION);
      MissionManager.getInstance().SetGCSMode(GCS_MODE.MISSION_GENERATE)
      store.commit("SetFacialRecognition", false);
      store.commit("SetSideMenuTab", false);
      store.commit("SetShowSingle", false);
      store.commit('SetLogMessageState', false)
      store.commit("SetSideMissionTab", false);
      store.commit("SetMissionGenerateTab", true);
    },
    onClickAllMissionVisible() {
      MissionManager.getInstance().SetAllMissionView()
      store.commit('SetMissionUnvisibleListChange', [])
    },
    onClickAllMissionsUnvisible() {
      store.getters.GetMissions.forEach((mission:MissionObject) => {
        MissionManager.getInstance().SetMissionEntityUnvisible(mission)
        store.commit('SetMissionUnvisibleListPush', mission)
      })
    },
    onClickMissionView(mission: MissionObject) {
      MissionManager.getInstance().SetMissionEntityView(mission)
      // this.MissionViewState(mission)
    },
    onClickMissionSimulation(mission: MissionObject) {
      console.log('simulation', mission)
      store.commit('SetSimulateTargetMission', mission);
      store.commit("SetModalType", 'simulation')
      store.commit("SetModalWindow", true)
    },
    onClickMissionSimulationStop(mission: MissionObject) {
      SimulateManager.getInstance().DeleteSimulate(`S_${mission.alias}`);
    },
    onClickMissionLocation(mission: MissionObject) {
      CesiumScreenSpaceEventService.getInstance().SetMissionCameraMove(mission)
    },
    MissionViewState(mission: MissionObject) {
      return MissionManager.getInstance().SetMissionEntityState(mission)
    },
    AllMissionViewState(missions:MissionObject[]) {
      return MissionManager.getInstance().SetAllMissionViewState(missions)
    },
    onClickDeleteMission(delete_mission:MissionObject) {
      console.log(delete_mission)
      store.commit("SetDeleteMission", delete_mission)
      store.commit("SetModalType", 'delete_mission')
      store.commit("SetModalWindow", true)
    },
    BackBtn() {
      const current_mission = store.getters.GetMissions
      current_mission.forEach((mission:MissionObject)=>{
        MissionManager.getInstance().SetMissionEntityUnvisible(mission)
      })
      store.commit("SetSideMissionTab", false);
    },
    IsRunningSimulation(mission_alias:string): boolean {
      return SimulateManager.getInstance().IsRunMissionSimulate(`S_${mission_alias}`);
    },
    AllMissionRunSimulate(missions:MissionObject[]) {
      this.group_simulation_state = true
      missions.forEach((mission:MissionObject) => {
        SimulateManager.getInstance().RunMissionSimulate(mission, 5)
      })
    },
    AllMissionStopSimulate(missions:MissionObject[]) {
      this.group_simulation_state = false
      missions.forEach((mission:MissionObject) => {
        SimulateManager.getInstance().DeleteSimulate(`S_${mission.alias}`)
      })
    },
    onMouseOverBtn() {
      this.mouseover_state = true
    },
    onMouseOutBtn() {
      this.mouseover_state = false
    }
  },
});
