
import { GCS_MODE } from "@/services/cesium/cesium_screen_space_ev_service";
import CESIUM_DRONE from "@/services/hg/cesium_drone";
import { E_CAMERA, E_EQUIPMENT, E_GPS, E_LIDAR } from "@/services/hg/drone_equipment_type";
import DroneManager from "@/services/hg/drone_manager";
import MissionManager, { MissionObject } from "@/services/hg/mission/mission_manager";
import SimulateManager from "@/services/hg/simulate/simulate_manager";
import { ChangeMode, DroneMissionSend, DroneMissionStart, DroneMissionStop, DroneMissionSuspend } from "@/services/ws/ws_hg_server_message_sender";
import { SubCommand } from "@/services/ws/ws_hg_server_protocol";
import store from "@/store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      mouseover_state:false,

      tracking_state:false,

      tab_open: false,
      drones: [] as CESIUM_DRONE[],
      drone_mission: computed(() => store.getters.GetAssignedMissionList),
      drone_mission_length: store.getters.GetAssignedMissionList,
      mission_start: false,

      equipment_lidar: E_EQUIPMENT.LIDAR,
      equipment_gps: E_EQUIPMENT.GPS,
      equipment_camera: E_EQUIPMENT.CAMERA,

      equipment_lidar_sf40c: E_LIDAR.SF40C,
      equipment_gps_nomal: E_GPS.NORMAL,
      equipment_gps_mbc_rtk: E_GPS.MBC_RTK,
      equipment_gps_kr_rtk: E_GPS.KR_RTK,
      equipment_camera_usb: E_CAMERA.USB,
      equipment_camera_viewpro: E_CAMERA.VIEWPRO,
      equipment_camera_onvif: E_CAMERA.ONVIF,
    };
  },

  setup() {
    const side_drone_tab = computed(() => store.getters.GetSideDroneTab);
    const store_drones = computed<CESIUM_DRONE[]>(() => store.getters.GetDrones);
    const assigned_mission_list = computed(() => store.getters.GetAssignedMissionList);
    return { side_drone_tab, store_drones, assigned_mission_list };
  },

  updated() {
    const mission_list_container_list = document.querySelectorAll('.mission-list-container');
    mission_list_container_list.forEach(mission_list_container => {
      const current_mission_index = DroneManager.getInstance().GetDrone(mission_list_container.id)?.GetMissionIndex();
      if(current_mission_index != undefined) {
        mission_list_container.scrollTop = 34 * current_mission_index;
      }
    });
  },
  watch: {
    side_drone_tab: {
      handler(n, o) {
        this.tab_open = n;
      },
    },
    store_drones: {
      handler(new_drone_list:CESIUM_DRONE[], o:CESIUM_DRONE[]) {
        this.drones = new_drone_list;
      },
    },
  },

  methods: {
    BackBtn() {
      store.commit("SetSideDroneTab", false);
    },
    onMouseOverBtn() {
      this.mouseover_state = true
    },
    onMouseOutBtn() {
      this.mouseover_state = false
    },
    // onClickSendMissionWS(drone:CESIUM_DRONE, mission:MissionObject|undefined) {
    //   DroneMissionSend(drone, mission)
    // },
    // OnClickDroneState(drone:CESIUM_DRONE) {  
    //   store.commit("SetDroneStateInfo", drone);
    //   store.commit("SetDroneState", true);
    // },
    // onClickGCS(drone:CESIUM_DRONE) {
    //   ChangeMode(SubCommand.SUB_COMMAND_GUIDE_MODE, drone.GetNoti()._index);
    // },
    // onClickController(drone:CESIUM_DRONE) {
    //   console.log(drone.GetState()?._heartbeat._custom_mode);
    //   ChangeMode(SubCommand.SUB_COMMAND_LOITER_MODE, drone.GetNoti()._index);
    // },
    // onClickPreset(drone:CESIUM_DRONE) {
    //   drone.DisplayMe(); 
    //   MissionManager.getInstance().SetMissionEntityVisible()
    //   store.commit("SetNowSelectedDrone", drone.GetNoti()._name);
    //   store.commit("SetSideMissionTabState", 'select');
    //   store.commit("SetSideDroneTab", false);
    //   store.commit("SetSideMissionTab", true);
    // },
    // onClickMissionRemove(drone:CESIUM_DRONE) {
    //   drone.ClearMission();
    // },
    // onClickRunSimulation(drone:CESIUM_DRONE) {
    //   const mission_object:MissionObject|undefined = drone.GetSimulatePathByMission();
    //   if(mission_object)
    //   {
    //     SimulateManager.getInstance().RunDroneMissionSimulate(mission_object);
    //   }
    // },
    // onClickRemoveSimulation(drone:CESIUM_DRONE) {
    //   SimulateManager.getInstance().DeleteSimulate(drone.GetNoti()._name);
    // },
    onClickDroneDetail(drone_name:string) {
      DroneManager.getInstance().SetDroneDetailData(drone_name)
      store.commit("SetSideDroneTab", false)
      store.commit("SetDroneDetailTab", true)
    },
    OnClickLocation(drone:CESIUM_DRONE) {
      drone.DisplayMe();
    },
    OnClickTracking(drone:CESIUM_DRONE) {
      this.tracking_state = !this.tracking_state
      MissionManager.getInstance().SetGCSMode(GCS_MODE.DRONE_TRACKING)
      drone.FollowMe();
    },
    // OnClickMissionStart(drone:CESIUM_DRONE) {
    //   DroneMissionStart(drone)
    // },
    // OnClickMissionStop(drone:CESIUM_DRONE) {
    //   // drone.GetMission()
    //   DroneMissionStop(drone, true)
    //   // drone.ClearMission();
    // },
    // OnClickMissionSuspend(drone:CESIUM_DRONE) {
    //   DroneMissionSuspend(drone)
    // },
  },
});
