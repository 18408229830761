// Read Me !

// [import 하는 법]
// import CustomAlert, {CustomAlertOptions} from "@/services/hg/custom_alert";

// [특정 조건에 의하여 Alert 창 띄워야 하는 상황]
// const options:CustomAlertOptions = {
//     color: 'red', // 원하는 색
//     timeout: 500, // 원하는 딜레이 시간
//     position: 'top right', // 원하는 포지션 (단어를 이어서 쭉 쓰시면 됩니다)
// }

// CustomAlert.getInstance().ShowAlert('Select Drone First', options);

import store from "@/store";

export interface CustomAlertOptions {
    position?: 'center'|'top'|'top right'|'right top'|'right'|'bottom right'|'right bottom'|'bottom'|'bottom left'|'left bottom'|'left'|'top left'|'left top',
    color?: string,
    timeout?: number
}

export default class CustomAlert {
    private static instance:CustomAlert;

    public static getInstance () {
        return this.instance || (this.instance = new this())
    }

    // options가 넘어오지 않았을 경우 default 값
    private default_options:CustomAlertOptions = {
        position:'top',
        color:'error',
        timeout:1000,
    }

    ShowAlert(text:string, options = this.default_options){
        // 옵션을 넘겨줬을 때 빈 값이 있으면 에러나서 삼항연산자로 예외처리
        const position = options.position ? options.position : this.default_options.position;
        const color = options.color ? options.color : this.default_options.color;
        const timeout = options.timeout ? options.timeout : this.default_options.timeout;

        store.commit('SetAlertData', [text, position, color, timeout])
    }
}