import { HG_Struct_Hydrogen_Info } from "@/services/ws/ws_hg_server_packet_interface";
import DEVICE from "./Device";

class DeviceManager {
    private static instance: DeviceManager;

    private _device_list:Map<string, DEVICE> = new Map();
    private _stack_info_queue:HG_Struct_Hydrogen_Info[] = [];

    public static getInstance() {
        return this.instance || (this.instance = new this());
    }

    constructor() {
        // 
    }

    GetDeviceFromDeviceSerial(device_serial:string):DEVICE|undefined {
        return this._device_list.get(device_serial);
    }

    UpdateDeviceList(chunk:number, max_chunk:number, stack_list:HG_Struct_Hydrogen_Info[]) {
        this._stack_info_queue = this._stack_info_queue.concat(stack_list);

        if(chunk == max_chunk) {

            this._stack_info_queue.forEach((stack_info:HG_Struct_Hydrogen_Info) => {
                if(!this._device_list.has(stack_info._device_serial)) {
                    const device:DEVICE = new DEVICE(
                        stack_info._device_serial,
                    )

                    this._device_list.set(stack_info._device_serial, device);
                }
                else {
                    const device:DEVICE|undefined = this._device_list.get(stack_info._device_serial);
                    if(device != undefined) {
                        device.AddStack(stack_info._stack_serial);
                    }
                }
            });

            

            this._stack_info_queue = [];
        }

    }
}

export default DeviceManager;
