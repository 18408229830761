import store from "@/store";
import { cesiumService } from "../../cesium/cesium_service_inst";

import { Easing, Tween } from "@tweenjs/tween.js";
import { parse } from "../../ws/ws_hg_server_message_handler";
import {
  HG_Contents,
  HG_DroneNoti,
  HG_DroneState,
  HG_Header,
  HG_Packet,
} from "../../ws/ws_hg_server_packet_interface";
import { E_MESSAGE_TYPE } from "../../ws/ws_hg_server_protocol";
import { DroneModel } from "../drone_model";
import MissionManager, { MissionItem, MissionItemType, MissionObject } from "../mission/mission_manager";

interface SimulationDroneInfo {
  idx: number;
  noti: HG_DroneNoti;
  state: HG_DroneState;
  paths: MissionItem[];
  originPaths: MissionItem[];
  position: DronePosition;
  speed: number,
  mission_uuid: string|undefined,
}

interface DronePosition {
  lng: number;
  lat: number;
  alt: number;
}

export default class SimulateManager {
  private static instance: SimulateManager;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  private verbose = false;
  private worker_interval_ms = 500;
  private _simulation_list: Map<number, SimulationDroneInfo>;

  private constructor() {
    //
    this._simulation_list = new Map<number, SimulationDroneInfo>();

    this.worker();
  }

  private worker() {
    this._simulation_list.forEach((drone_info: SimulationDroneInfo) => {
      if(drone_info.mission_uuid) {
        if(MissionManager.getInstance().GetMissionFromUUID(drone_info.mission_uuid) == undefined) {
          this.DeleteSimulate(drone_info.noti._name);
        }

        if(MissionManager.getInstance().GetMissionFromAlias(drone_info.noti._name) == undefined) {
          this.DeleteSimulate(drone_info.noti._name);
        }
      }
    });

    this._simulation_list.forEach((drone_info: SimulationDroneInfo) => {
      // make virtual state
      {
        const header: HG_Header = {
          _msg_type: E_MESSAGE_TYPE.STATUS,
          _name: drone_info.noti._name,
        };

        const state: HG_DroneState = drone_info.state;
        state._gps_int._lon = drone_info.position.lng;
        state._gps_int._lat = drone_info.position.lat;
        state._gps_int._alt = drone_info.position.alt;
        state._gps_int._relative_alt = drone_info.position.alt;

        const contents: HG_Contents = {
          _drone_states: drone_info.state,
        };

        const packet: HG_Packet = {
          _header: header,
          _contents: contents,
        };

        parse(JSON.stringify(packet));
      }
    });

    setTimeout(() => {
      this.worker();
    }, this.worker_interval_ms);
  }

  RunMissionSimulate(mission:MissionObject, speed_meter_per_sec:number) {
    if (mission.items.length > 0) {
      mission.items = [...mission.items];
      this.createSimulationDrone(mission, speed_meter_per_sec);
    }
  }

  IsRunMissionSimulate(mission_alias:string) {
    let result = false;
    this._simulation_list.forEach((v,k) => {
      if(v.noti._name == mission_alias)
      {
        result = true;
      }
    });
    return result;
  }

  RunSimulate() {
    const missions: MissionObject[] = [...store.getters.GetMissions];
    // console.log(missions);
    missions.forEach((mission: MissionObject) => {
      if (mission.items.length > 0) {
        mission.items = [...mission.items];
        // console.log(mission)
        this.createSimulationDrone(mission, 5);
      }
    });
  }
  RunDroneMissionSimulate(mission_object:MissionObject) {
    console.log("RunDroneMissionSimulate", mission_object);
    this.createSimulationDrone(mission_object, 5);
  }

  DeleteSimulate(name:string) {
    this._simulation_list.forEach((v,k) => {
      if(v.noti._name == name)
      {
        this._simulation_list.delete(k);
        return; // End of forEach
      }
    });
  }

  IsRunSimulate(name:string):boolean {
    let result = false;
    this._simulation_list.forEach((v,k) => {
      if(v.noti._name == name)
      {
        result = true;
        return; // End of forEach
      }
    });

    return result;
  }

  GetSimulationNotis() {
    const simulation_list: HG_DroneNoti[] = [];
    this._simulation_list.forEach((drone_info) => {
      simulation_list.push(drone_info.noti);
    });

    return simulation_list;
  }

  private createSimulationDrone(mission: MissionObject, speed_meter_per_sec:number) {
    let idx = 1;
    while (this._simulation_list.has(idx)) {
      idx++;
    }

    const noti: HG_DroneNoti = {
      _index: idx,
      _ip: "127.0.0.1",
      _name: `S_${mission.alias}`,
      _equipment: 0,
      _simulate: true,
    };

    let is_exist_coords = false;
    let starting_lon = 0;
    let starting_lat = 0;
    let starting_alt = 0;
    mission.items.forEach((mission_item:MissionItem) => {
      if(is_exist_coords == false && mission_item.type == MissionItemType.WAYPOINT) {
        is_exist_coords = true;
        starting_lon = mission_item.lng;
        starting_lat = mission_item.lat;
        starting_alt = mission_item.display;
      }
    });

    // Filtering only waypoints
    const filtering_mission_items:MissionItem[] = [];
    mission.items.forEach((mission_item:MissionItem) => {
      if(mission_item.type == MissionItemType.WAYPOINT) {
        filtering_mission_items.push(mission_item);
      }
    });

    if(is_exist_coords) {
      // console.log(`Mission ${mission.alias} simulating launched at ${starting_lon},${starting_lat}`);
      const state: HG_DroneState = JSON.parse(JSON.stringify(DroneModel));
      state._gps_int._lon = starting_lon * 1e7;
      state._gps_int._lat = starting_lat * 1e7;
      state._gps_int._alt = starting_alt * 1e3;
      state._gps_int._relative_alt = starting_alt * 1e3;
      

      state.alt_tracking[0]._detect = 1;
      state.alt_tracking[0]._coordinate_alt._lon = starting_lon * 1e7;
      state.alt_tracking[0]._coordinate_alt._lat = starting_lat * 1e7;

      state.alt_tracking[1]._detect = 1;
  
      const drone_info: SimulationDroneInfo = {
        idx: idx,
        noti: noti,
        state: state,
        paths: [...filtering_mission_items],
        originPaths: [...filtering_mission_items],
        position: {
          lng: state._gps_int._lon,
          lat: state._gps_int._lat,
          alt: state._gps_int._alt,
        },
        speed: speed_meter_per_sec,
        mission_uuid: mission._id,
      };

      this._simulation_list.set(idx, drone_info);
      this.move(drone_info);
    }
  }

  private move(drone_info: SimulationDroneInfo) {
    if (this.verbose)
      console.log("move", drone_info, drone_info.paths, drone_info.originPaths);

    if (drone_info.paths.length > 0) {
      const current = drone_info.position;
      const next: MissionItem = drone_info.paths[0];
      const dest: DronePosition = {
        lng: next.lng * 1e7,
        lat: next.lat * 1e7,
        alt: next.display * 1000,
      };

      const meter_per_second = drone_info.speed;
      const Cesium = cesiumService.GetCesium();
      const cartesian3_current = new Cesium.Cartesian3.fromDegrees(
        current.lng / 1e7,
        current.lat / 1e7,
        current.alt / 1000
      );
      const cartesian3_dest = new Cesium.Cartesian3.fromDegrees(
        dest.lng / 1e7,
        dest.lat / 1e7,
        dest.alt / 1000
      );
      const distance_meters = Cesium.Cartesian3.distance(
        cartesian3_current,
        cartesian3_dest
      );
      const time_ms = (distance_meters / meter_per_second) * 1000;

      drone_info.state._attitude._yaw = this.calculateBearing(
        Cesium.Cartesian3.fromDegrees(
          current.lng / 1e7,
          current.lat / 1e7,
          current.alt / 1000
        ),
        Cesium.Cartesian3.fromDegrees(
          dest.lng / 1e7,
          dest.lat / 1e7,
          dest.alt / 1000
        )
      );

      new Tween(drone_info.position)
        .to(
          {
            lng: dest.lng,
            lat: dest.lat,
            alt: dest.alt,
          },
          time_ms
        )
        .start()
        .easing(Easing.Linear.None)
        .onComplete(() => {
          // setTimeout(() => {
          drone_info.paths.shift();
          // console.log('onComplete',drone_info.position, dest.lng,dest.lat,dest.alt)
          this.move(drone_info);
          // }, 501)
        });
    } else {
      drone_info.paths = [...drone_info.originPaths];
      this.move(drone_info);
    }
  }

  private calculateBearing = (startPoint: any, endPoint: any) => {
    const Cesium = cesiumService.GetCesium();
    const start = Cesium.Cartographic.fromCartesian(startPoint);
    const end = Cesium.Cartographic.fromCartesian(endPoint);

    const y =
      Math.sin(end.longitude - start.longitude) * Math.cos(end.latitude);
    const x =
      Math.cos(start.latitude) * Math.sin(end.latitude) -
      Math.sin(start.latitude) *
        Math.cos(end.latitude) *
        Math.cos(end.longitude - start.longitude);

    const bearing = Math.atan2(y, x);
    const computed_bearing = Cesium.Math.toRadians(
      Cesium.Math.toDegrees(bearing)
    );
    // console.log(computed_bearing);
    return computed_bearing;
    // return Cesium.Math.toDegrees(bearing);
  };
}
