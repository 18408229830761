
import { computed, defineComponent } from "vue";
import store from "@/store";
import MEDUSA_PACKET from "@/services/third_party/neton/medusa_packet_info";
export default defineComponent({
  data() {
    return {
      select: "",
      medusa_items: [] as MEDUSA_PACKET[],
    };
  },

  setup() {
    const showFacialRecognition = computed(
      () => store.getters.GetFacialRecognition
    );
    const store_medusa_items = computed(() => store.getters.GetMedusaItems);
    return { showFacialRecognition, store_medusa_items };
  },
  watch: {
    group() {
      this.showFacialRecognition = false;
    },
    store_medusa_items: {
      handler(n, o) {
        this.medusa_items = n;
        //console.log(this.medusa_items);
      },
    },
  },
  methods: {
    CloseBtn() {
      store.commit("SetFacialRecognition", false);
      store.commit("SetSideMenuTab", true);
    },
    ClearFaceData(){
      this.medusa_items = []
    }
  },
});
