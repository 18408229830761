import store from "@/store";
import * as turf from "@turf/turf";
import { computed } from "vue";
import CESIUM_DRONE from "../hg/cesium_drone";
import CustomAlert, { CustomAlertOptions } from "../hg/custom_alert";
import DroneManager from "../hg/drone_manager";
import DroneVirtualManager from "../hg/drone_virtual_manager";
import MissionWaypointGeneratingManager from "../hg/mission/mission_generating_tool_manager";
import MissionManager, { MissionItem, MissionItemType, MissionObject } from "../hg/mission/mission_manager";
import SingleManager from "../hg/single/single_manager";
import UserSettingManager from "../hg/user_setting/user_setting_manager";
import CesiumScreenSpaceCameraControllerService from "./cesium_screen_space_camera_controller";
import { cesiumService } from "./cesium_service_inst";
export enum GCS_FEATURE {
  NONE = 0,

  NORMAL,
  SINGLE,
  MISSION,
  CVD,

  MAX,
}
export enum MISSION_MODE {
  NONE = 0,

  WAYPOINT_LINE,
  WAYPOINT_SQUARE,
  WAYPOINT_ETC,

  MAX,
}

export enum MISSION_MODE_STATE {
  ON = 0,
  OFF,
  STOP,
}

export enum GCS_MODE {
  NONE = 0,

  NORMAL,
  MISSION_GENERATE,
  MISSION_UPDATE,
  DRONE_TRACKING,

  MAX,
}

export interface GCS_ModeInfo {
  state:boolean,
  mode_type:GCS_MODE,
  drone_name?:string,
}

export default class CesiumScreenSpaceEventService {
  private static instance: CesiumScreenSpaceEventService;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  private _ev_handler: any;
  private _ignore_mouse_move = true;

  private _mission_generator: MissionWaypointGeneratingManager;

  // For Single..
  private _single_entity: any;
  private _single_entity_floor: any;
  private _single_path: any;
  private _single_appending_altitude = 0;
  private _single_description_entity: any;
  private _single_description = "";

  // Creating Virtual Drone..
  private _virtual_drone: any;

  // For Mission..
  private _mission_pointer: any;
  private _mission_path_entity: any;
  private _mission_path_entity_floor: any;
  private _mission_point_path: any;
  private _mission_path: any;
  private _mission_path_entry: any;
  private _mission_path_entry_entity: any;
  private _mission_path_entry_entity_floor: any;

  private mission_item_temp: any;
  private modify_altitude = false;
  private modify_lnglat = false;
  private click_position_cartesian: any;
  private click_position_y = 0;
  private click_position_display = 0;
  private click_position_asl = 0;
  private click_position_agl = 0;
  private click_takeoff_state = false;

  constructor() {
    console.log("Screen Event Handler Launched");
    const viewer = cesiumService.GetViewer();
    const Cesium = cesiumService.GetCesium();

    this._ev_handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

    this._mission_generator = MissionWaypointGeneratingManager.getInstance();

    this.init();
  }

  SetFlyToTargetLineColor() {
    const Cesium = cesiumService.GetCesium();
    
    this._single_entity.polyline.material = new Cesium.PolylineGlowMaterialProperty({
      glowPower: .25,
      color: Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_fly_to_target)
    })
  }
  SetWaypointLineColor() {
    const Cesium = cesiumService.GetCesium();
    
    this._mission_path_entity.polyline.material = new Cesium.PolylineGlowMaterialProperty({
      glowPower: .25,
      color: Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_waypoint)
    })
  }

  private init() {
    const viewer = cesiumService.GetViewer();
    const Cesium = cesiumService.GetCesium();
    this._virtual_drone = viewer.entities.getOrCreateEntity(
      "mouse-follow-virtual-drone"
    );
    this._virtual_drone.show = false;
    this._virtual_drone.model = {
      uri: "/DroneModel/powerpack_drone_rotate0.glb",
      scale: 3.0,
      minimumPixelSize: 50,
      silhouetteColor: Cesium.Color.ORANGE,
      silhouetteSize: 2,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
    };

    this._mission_pointer = viewer.entities.getOrCreateEntity(
      "mouse-follow-mission-pointer"
    );
    this._mission_pointer.show = false;
    this._mission_pointer.model = {
      uri: "/DroneModel/Pointing Arrow.glb",
      scale: 0.2,
      minimumPixelSize: 50,
      // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
    };

    this._mission_path = [];

    this._mission_path_entity =
      viewer.entities.getOrCreateEntity("mission-path");
    this._mission_path_entity.polyline = {
      positions: new Cesium.CallbackProperty(() => {
        return this._mission_path;
      }, false),
      material: new Cesium.PolylineGlowMaterialProperty({
        glowPower: 5.25,
        color: Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_waypoint)
      }),
      width: 5,
      arcType: Cesium.ArcType.NONE,
    };
    this._mission_path_entity_floor =
      viewer.entities.getOrCreateEntity("mission-path-floor");
    this._mission_path_entity_floor.polyline = {
      positions: new Cesium.CallbackProperty(() => {
        return this._mission_path;
      }, false),
      material: new Cesium.ColorMaterialProperty(Cesium.Color.BLUE),
      width: 5,
      clampToGround: true,
    };

    this._mission_path_entry = [];

    this._mission_path_entry_entity =
      viewer.entities.getOrCreateEntity("mission-path-entry");
    this._mission_path_entry_entity.polyline = {
      positions: new Cesium.CallbackProperty(() => {
        return this._mission_path_entry;
      }, false),
      material: new Cesium.PolylineGlowMaterialProperty({
        glowPower: 0.25,
        color: Cesium.Color.fromRandom({
          maximumAlpha: 1.0,
          minimumAlpha: 1.0,
        }),
      }),
      width: 5,
      arcType: Cesium.ArcType.NONE,
    };

    this._mission_path_entry_entity_floor = viewer.entities.getOrCreateEntity(
      "mission-path-entry-floor"
    );
    this._mission_path_entry_entity_floor.polyline = {
      positions: new Cesium.CallbackProperty(() => {
        return this._mission_path_entry;
      }, false),
      material: new Cesium.ColorMaterialProperty(Cesium.Color.YELLOW),
      width: 5,
      clampToGround: true,
    };

    //SINGLE////////////////////////////////////////////////////////////////////////////////////////////////
    this._single_description_entity =
      viewer.entities.getOrCreateEntity("single-description");
    this._single_description_entity.label = {
      text: new Cesium.CallbackProperty(() => {
        return this._single_description;
      }, false),
      font: ".9rem NotoMedium",
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      pixelOffset: new Cesium.Cartesian2(0.0, -0.0),
      outlineColor: Cesium.Color.BLACK,
      fillColor: Cesium.Color.WHITE,
      outlineWidth: 5,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
    };

    this._single_path = [];

    this._single_entity = viewer.entities.getOrCreateEntity("single-path");
    this._single_entity.wall = {
      positions: new Cesium.CallbackProperty(() => {
        return this._single_path;
      }, false),
      material: new Cesium.ColorMaterialProperty(
        Cesium.Color.WHITE.withAlpha(0.3)
      ),
    };
    this._single_entity.polyline = {
      positions: new Cesium.CallbackProperty(() => {
        return this._single_path;
      }, false),
      material: new Cesium.PolylineGlowMaterialProperty({
        glowPower: .25,
        color: Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_fly_to_target)
      }),
      width: 5,
      arcType: Cesium.ArcType.NONE,
    };

    this._single_entity_floor =
      viewer.entities.getOrCreateEntity("single-path-floor");
    this._single_entity_floor.polyline = {
      positions: new Cesium.CallbackProperty(() => {
        return this._single_path;
      }, false),
      material: new Cesium.ColorMaterialProperty(Cesium.Color.WHITE),
      width: 5,
      clampToGround: true,
    };
    this._mission_point_path = [];

    this.setModeSingle();
  }

  DeleteMissionEntityRemove() {
    // 미션 삭제할 때 해당 Entity 지우기
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();
    viewer.entities.removeById(
      store.getters.GetDeleteMission.alias + "-mission-path"
    );
    viewer.entities.removeById(
      store.getters.GetDeleteMission.alias + "-mission-floor"
    );
    store.getters.GetDeleteMission.items.forEach(
      (mission: MissionItem, index: number) => {
        viewer.entities.removeById(
          `altitude${
            mission.type === MissionItemType.LAND
              ? "-land"
              : mission.type === MissionItemType.RTL
              ? "-rtl"
              : ""
          }-${mission.id}`
        );
        viewer.entities.removeById(
          `lnglat${
            mission.type === MissionItemType.LAND
              ? "-land"
              : mission.type === MissionItemType.RTL
              ? "-rtl"
              : ""
          }-${mission.id}`
        );
        viewer.entities.removeById(
          `label${
            mission.type === MissionItemType.LAND
              ? "-land"
              : mission.type === MissionItemType.RTL
              ? "-rtl"
              : ""
          }-${mission.id}`
        );
      }
    );
  }
  SetSelectedDroneMission(a: any, b: any) {
    console.error("Repair me");
  }
  SetMode(feature: GCS_FEATURE) {
    console.log(`Try feature mode change : ${feature}`);
    if (feature <= GCS_FEATURE.NONE || feature >= GCS_FEATURE.MAX) {
      console.error(`Not defined feature : ${feature}`);
    }

    this.setModeDefault();

    switch (feature) {
      case GCS_FEATURE.NORMAL: {
        console.log("NORMAL", feature);
        this.setModeDefault();
        break;
      }

      case GCS_FEATURE.SINGLE: {
        console.log("SINGLE", feature);
        this.setModeSingle();
        break;
      }

      case GCS_FEATURE.MISSION: {
        console.log("MISSION", feature);
        this.setMissionMode(MissionItemType.NONE);
        break;
      }

      case GCS_FEATURE.CVD: {
        console.log("CVD", feature);
        this.setModeCreateVirtualDrone();
        break;
      }

      default: {
        console.error(`Not defined feature : ${feature}`);
        break;
      }
    }
  }
  setModeMissionModify() {
    console.log("Mission Modify");
  }
  setMissionMode(type: MissionItemType) {
    console.log("Mission Mode Start");
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();
    const GCSMode:GCS_ModeInfo = MissionManager.getInstance().GetGCSMode();
    const Paths:MissionItem[] = MissionManager.getInstance().GetModifyMission()
    const MissionName = computed(() => store.getters.GetSelectMissionName);
    this._mission_pointer.show = true;
    if (GCSMode.mode_type == GCS_MODE.MISSION_UPDATE && this._mission_path.length == 0) {
      viewer.entities.removeById(MissionName.value + "-mission-path");
      viewer.entities.removeById(MissionName.value + "-mission-floor");
      this._mission_path_entry = [];
      this._mission_path = [];
      Paths.forEach((data: MissionItem) => {
        if (data.type == MissionItemType.WAYPOINT || data.type == MissionItemType.LAND) {
          this._mission_path.push(Cesium.Cartesian3.fromDegrees(data.lng, data.lat, data.display));
        }
      });
    }
    if (type == MissionItemType.NONE) {
      this._mission_pointer.show = false;
      this.setMissionPoint(Paths);
      console.log("MissionToolType.NONE");
      this._ev_handler.setInputAction((movement: any) => {
        console.log("ScreenSpaceEventType.LEFT_CLICK");
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      this._ev_handler.setInputAction((movement: any) => {
        if (Paths.length > 0) {
          this.click_takeoff_state =
            Paths[0].type === MissionItemType.TAKEOFF || Paths[0].type === MissionItemType.ARM ? true : false;
        }
        const mouse_position = movement.endPosition
          ? movement.endPosition
          : movement.position;
        const feature = viewer.scene.pick(mouse_position);
        const getPickRay = viewer.camera.getPickRay(mouse_position);
        if (feature && feature.id && feature.id._id) {
          const entity_name: string = feature.id._id;
          if (
            !entity_name.endsWith("-mission-floor") &&
            !entity_name.endsWith("-mission-path")
          ) {
            if (entity_name.includes("land") || entity_name.includes("rtl")) {
              console.log("entity_name", feature.id._id);
            } else {
              if (entity_name.includes("altitude")) {
                CesiumScreenSpaceCameraControllerService.getInstance().CameraLock(true);
                CesiumScreenSpaceCameraControllerService.getInstance().EnableRotate(false);
                this.modify_altitude = true;
                this.modify_lnglat = false;
                this.mission_item_temp = feature.id;
                this.click_position_y = mouse_position.y;
                this.click_position_cartesian = getPickRay.origin.clone();
                const mission_position_cartesian = feature.id.position._value;
                const mission_position_cartographic =
                  Cesium.Cartographic.fromCartesian(mission_position_cartesian);
                this.click_position_display =
                  mission_position_cartographic.height;
                Paths.forEach((path: MissionItem) => {
                  if (`altitude-${path.id}` == this.mission_item_temp._id) {
                    this.click_position_asl = path.asl;
                    this.click_position_agl = path.agl;
                  }
                });
                //
              } else if (entity_name.includes("lnglat")) {
                CesiumScreenSpaceCameraControllerService.getInstance().CameraLock(true);
                CesiumScreenSpaceCameraControllerService.getInstance().EnableRotate(false);
                this.modify_altitude = false;
                this.modify_lnglat = true;
                this.mission_item_temp = feature.id;
                this.click_position_cartesian =
                  feature.id.position._value.clone();
              } else if(entity_name.includes("label")) {
                MissionManager.getInstance().SetSelectWaypointList(entity_name)
              } else {
                console.log("None");
                CesiumScreenSpaceCameraControllerService.getInstance().EnableRotate(true);
              }
            }
          }
        } else {
          CesiumScreenSpaceCameraControllerService.getInstance().EnableRotate(true);
        }
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

      this._ev_handler.setInputAction((movement: any) => {
        if (this.mission_item_temp) {
          if (Paths[Paths.length - 1].type == MissionItemType.LAND) { //land 위치 재설정
            const Land_Position: MissionItem = Paths[Paths.length - 1];
            const Last_Position: MissionItem = Paths[Paths.length - 2];
            Land_Position.lat = Last_Position.lat;
            Land_Position.lng = Last_Position.lng;
            this._mission_path[this._mission_path.length - 1] =
              Cesium.Cartesian3.fromDegrees(
                Last_Position.lng,
                Last_Position.lat,
                Land_Position.display
              );
            }
            this.setMissionPoint(Paths);
            this.mission_item_temp = undefined;
            this.modify_altitude = false;
            this.modify_lnglat = false;
        }
        CesiumScreenSpaceCameraControllerService.getInstance().CameraLock(false);
      }, Cesium.ScreenSpaceEventType.LEFT_UP);

      this._ev_handler.setInputAction((movement: any) => {
        const position = viewer.camera.getPickRay(movement.endPosition);
        const mousePosition = viewer.scene.globe.pick(position, viewer.scene);
        this._mission_pointer.position = mousePosition.clone();
        if (this._ignore_mouse_move && this.mission_item_temp) {
          this._ignore_mouse_move = true;
          const mission_position_cartesian =
            this.mission_item_temp.position._value;
          const mission_position_cartographic =
            Cesium.Cartographic.fromCartesian(mission_position_cartesian);
          const mission_zero_position_cartesian = Cesium.Cartesian3.fromRadians(
            mission_position_cartographic.longitude,
            mission_position_cartographic.latitude
          );
          const window_zero_position =
            viewer.scene.cartesianToCanvasCoordinates(
              mission_zero_position_cartesian
            );

          const append_altitude =
            (this.click_position_display /
              (window_zero_position.y - this.click_position_y)) *
            (this.click_position_y - movement.endPosition.y);
          const append_altitude_floor = Math.floor(append_altitude);
          if (this.modify_altitude) {
            const SetHeight =
              Cesium.Cartographic.fromCartesian(mousePosition).height <
              this.click_position_display + append_altitude_floor;
            if (SetHeight) {
              Paths.forEach((data: MissionItem) => {
                if (this.mission_item_temp._id == `altitude-${data.id}`) {
                  data.display =
                    this.click_position_display + append_altitude_floor;
                  data.asl = this.click_position_asl + append_altitude_floor;
                  data.agl = this.click_position_agl + append_altitude_floor;
                  this._mission_path[
                    this.click_takeoff_state ? Number(data.id) - 2 : data.id
                  ] = Cesium.Cartesian3.fromDegrees(
                    data.lng,
                    data.lat,
                    data.display
                  );
                }
              });
            }
          } else if (this.modify_lnglat) {
            viewer.entities.removeById("mission_polyline");
            const origin = viewer.scene.cartesianToCanvasCoordinates(
              this.click_position_cartesian
            );
            const mouse = movement.endPosition;
            const x = mouse.x - origin.x;
            const y = mouse.y - origin.y;
            const radian = Math.atan2(y, x);
            let computed_bearing = Cesium.Math.toDegrees(radian) + 90;
            computed_bearing += Cesium.Math.toDegrees(
              viewer.scene.camera.heading
            );
            const mission_position_cartographic =
              Cesium.Cartographic.fromCartesian(this.click_position_cartesian);
            const mission_position_cartographic_degree_longitude =
              Cesium.Math.toDegrees(mission_position_cartographic.longitude);
            const mission_position_cartographic_degree_latitude =
              Cesium.Math.toDegrees(mission_position_cartographic.latitude);
            const turf_origin: turf.Coord = turf.point([
              mission_position_cartographic_degree_longitude,
              mission_position_cartographic_degree_latitude,
            ]);

            let meter_per_pixel = 1;
            {
              const pixel_per_meters_destination = turf.destination(
                turf_origin.geometry.coordinates,
                1,
                Cesium.Math.toDegrees(viewer.scene.camera.heading) + 90,
                { units: "meters" }
              );
              const right_1_meter_window_position =
                viewer.scene.cartesianToCanvasCoordinates(
                  Cesium.Cartesian3.fromDegrees(
                    pixel_per_meters_destination.geometry.coordinates[0],
                    pixel_per_meters_destination.geometry.coordinates[1],
                    mission_position_cartographic.height
                  )
                );
              meter_per_pixel = Math.floor(
                right_1_meter_window_position.x - origin.x
              );
            }

            const turf_distance =
              Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)) / meter_per_pixel;
            const destination = turf.destination(
              turf_origin.geometry.coordinates,
              turf_distance,
              computed_bearing,
              { units: "meters" }
            );
            const new_position = Cesium.Cartesian3.fromDegrees(
              destination.geometry.coordinates[0],
              destination.geometry.coordinates[1],
              mission_position_cartographic.height
            );

            Paths.forEach((data: MissionItem) => {
              if (this.mission_item_temp._id == `lnglat-${data.id}`) {
                data.lng = destination.geometry.coordinates[0];
                data.lat = destination.geometry.coordinates[1];
                this._mission_path[
                  this.click_takeoff_state ? Number(data.id) - 2 : data.id
                ] = Cesium.Cartesian3.fromDegrees(
                  data.lng,
                  data.lat,
                  data.display
                );
              }
            });
          }
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      this._ev_handler.setInputAction((movement: any) => {
        store.commit("SetMissionModeState", MISSION_MODE_STATE.STOP);
        MissionManager.getInstance().SetWaypointBoxState(false)
        this.SetMissionModeOff(Paths);
        this._mission_path_entry = [];
        this.setMissionMode(MissionItemType.NONE);
        this._mission_pointer.show = false;
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

    } else if (type == MissionItemType.WAYPOINT) {
      this.setMissionPoint(Paths);
      this._ev_handler.setInputAction((point: any) => {
        const pick = viewer.camera.getPickRay(point.endPosition);
        const mouse_position = viewer.scene.globe.pick(pick, viewer.scene);
        if (mouse_position) {
          this._mission_pointer.position = mouse_position.clone();
          const promise = this._mission_generator.GetPath(
            mouse_position,
            MissionItemType.WAYPOINT
          );
          promise.then((paths: MissionItem[]) => {
            this._mission_path_entry = [];
            paths.forEach((path: MissionItem, index: number) => {
              if (path.type == MissionItemType.WAYPOINT ||path.type == MissionItemType.LAND) {
                this._mission_path_entry.push(
                  Cesium.Cartesian3.fromDegrees(
                    path.lng,
                    path.lat,
                    path.display
                  )
                );
              }
            });
          });
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      this._ev_handler.setInputAction((movement: any) => {
        const pick = viewer.camera.getPickRay(movement.position);
        const mouse_position = viewer.scene.globe.pick(pick, viewer.scene);
        if (mouse_position) {
          const promise = this._mission_generator.GetPath(mouse_position,MissionItemType.WAYPOINT);
          promise.then((paths: MissionItem[]) => {
            if (paths.length > 1) {paths.splice(0, 1)}
            let AltitudeMinusCheck = true;
            paths.forEach((path: MissionItem) => {
              if (path.type == MissionItemType.WAYPOINT) {
                if(path.agl < 5) {
                  AltitudeMinusCheck = false // 고도값이 5미만일 경우 미션 반영 안되게
                }
              }
            });
            if (AltitudeMinusCheck){
              MissionManager.getInstance().SetModifyMission(paths)
              const append_paths_entry: any = [];
              const append_point_paths_entry: any = [];
              paths.forEach((path: MissionItem) => {
                if (path.type == MissionItemType.WAYPOINT || path.type == MissionItemType.LAND) {
                append_point_paths_entry.push({lng:path.lng, lat:path.lat, display_alt:path.display});
                append_paths_entry.push(Cesium.Cartesian3.fromDegrees(path.lng, path.lat, path.display));
                }
              });
              this._mission_path = this._mission_path.concat(append_paths_entry);
              this._mission_point_path = this._mission_point_path.concat(append_point_paths_entry);
            } else {
              const alert_options:CustomAlertOptions = {
                position:'top',
                color:'error',
                timeout:3000,
              }
              CustomAlert.getInstance().ShowAlert('Please increase the altitude value to 5 or higher.', alert_options)
            }
          });
        }
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

      this._ev_handler.setInputAction((movement: any) => {
        // 마우스 LEFT UP 할 때 미션 포인트 재설정 
        this.setMissionPoint(Paths);
      }, Cesium.ScreenSpaceEventType.LEFT_UP);

    } else if (type == MissionItemType.TAKEOFF) {
      this.SetMissionTakeOff();
    } else if (type == MissionItemType.LAND) {
      this.SetMissionLand();
    } else if (type == MissionItemType.RTL) {
      this.SetMissionRTL()
    }
  }
  private setMissionPoint(path: any) {
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();
    path.forEach((data: MissionItem, index: number) => {
      if (
        data.type == MissionItemType.WAYPOINT ||
        data.type == MissionItemType.LAND
      ) {
        viewer.entities.removeById(
          `altitude${data.type === MissionItemType.LAND ? "-land" : ""}-${
            data.id
          }`
        );
        viewer.entities.removeById(
          `lnglat${data.type === MissionItemType.LAND ? "-land" : ""}-${
            data.id
          }`
        );
        viewer.entities.removeById(
          `label${data.type === MissionItemType.LAND ? "-land" : ""}-${
            data.id
          }`
        );
        const lnglat = [data.lng, data.lat];
        const height = data.display;
        const radius = 1;
        const units: turf.Units = "meters";
        const options = { steps: 10, units: units, properties: undefined };
        const circle = turf.circle(lnglat, radius, options);
        const circle_positions: any[] = [];
        circle.geometry.coordinates[0].forEach((coordinate) => {
          circle_positions.push(coordinate[0]);
          circle_positions.push(coordinate[1]);
          circle_positions.push(height + 0.25);
        });

        const hole = turf.circle(lnglat, 0.75, options);
        const hole_positions: any[] = [];
        hole.geometry.coordinates[0].forEach((coordinate) => {
          hole_positions.push(coordinate[0]);
          hole_positions.push(coordinate[1]);
          hole_positions.push(height + 0.25);
        });
        const hpr = new window.Cesium.HeadingPitchRoll(90, 180, 90);
        const orientation = Cesium.Transforms.headingPitchRollQuaternion(
          this._mission_path[this._mission_path.length - 1].clone(),
          hpr
        );
        /////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////
        viewer.entities.add({
          id: `label${
            data.type === MissionItemType.LAND ? "-land" : ""
          }-${index}`,
          position: Cesium.Cartesian3.fromDegrees(
            data.lng,
            data.lat,
            data.display
          ),
          label: {
            text: String(Number(data.id)+1),
            font:'15px NotoBold',
            showBackground: true,
            style: 0,
            fillColor: Cesium.Color.fromCssColorString('#343434'),
            backgroundColor: Cesium.Color.fromCssColorString('#b7caf7'),
            pixelOffset: new Cesium.Cartesian2(0, -40),
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 200.0),
          },
        });
        /////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////

        viewer.entities.add({
          id: `altitude${
            data.type === MissionItemType.LAND ? "-land" : ""
          }-${index}`,
          position: Cesium.Cartesian3.fromDegrees(
            data.lng,
            data.lat,
            data.display
          ),
          ellipsoid: {
            radii: new Cesium.Cartesian3(0.5, 0.5, 0.5),
            material:
              data.type == MissionItemType.LAND
                ? new Cesium.ColorMaterialProperty(Cesium.Color.YELLOW)
                : new Cesium.ColorMaterialProperty(Cesium.Color.fromCssColorString(UserSettingManager.getInstance().Get().color_waypoint)), // data.type == MissionItemType.WAYPOINT
          },
        });
        viewer.entities.add({
          id: `lnglat${
            data.type === MissionItemType.LAND ? "-land" : ""
          }-${index}`,
          position: Cesium.Cartesian3.fromDegrees(
            data.lng,
            data.lat,
            data.display
          ),
          polygon: {
            hierarchy: {
              positions:
                Cesium.Cartesian3.fromDegreesArrayHeights(circle_positions),
              holes: [
                {
                  positions:
                    Cesium.Cartesian3.fromDegreesArrayHeights(hole_positions),
                },
              ],
            },
            extrudedHeight: height - 0.25,
            perPositionHeight: true,
            material: new Cesium.ColorMaterialProperty(
              Cesium.Color.BLACK.withAlpha(0.5)
            ),
            stRotation: Cesium.Math.toRadians(45),
          },
          orientation: orientation,
        });
      }
    });
  }
  SetMissionModeOff(mission_items: MissionItem[]) {
    if(mission_items.length > 0) {
      if (
        mission_items[mission_items.length - 1].type == MissionItemType.RTL ||
        mission_items[mission_items.length - 1].type == MissionItemType.LAND
      ) {
        store.commit("SetMissionModeState", MISSION_MODE_STATE.OFF);
      } else {
        store.commit("SetMissionModeState", MISSION_MODE_STATE.STOP);
      }
    }
  }
  SetDeleteMissionItem(mission_item: MissionItem) {
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();
    const Paths:MissionItem[] = MissionManager.getInstance().GetModifyMission();
    const CopyPaths:MissionItem[] = MissionManager.getInstance().GetModifyMission();
    let mission_index = 0;
    this._mission_path = [];
    Paths.forEach((mission: MissionItem, index: number) => {
      viewer.entities.removeById(
        `altitude${
          mission.type === MissionItemType.LAND
            ? "-land"
            : mission.type === MissionItemType.RTL
            ? "-rtl"
            : ""
        }-${mission.id}`
      );
      viewer.entities.removeById(
        `lnglat${
          mission.type === MissionItemType.LAND
            ? "-land"
            : mission.type === MissionItemType.RTL
            ? "-rtl"
            : ""
        }-${mission.id}`
      );
      viewer.entities.removeById(
        `label${
          mission.type === MissionItemType.LAND 
          ? "-land" 
          : mission.type === MissionItemType.RTL 
          ? "-rtl" 
          : ""
        }-${mission.id}`
      );
    });
    this._mission_point_path = [];
    MissionManager.getInstance().ClearModifyMission()
    CopyPaths.forEach((paths_mission_item: MissionItem, index: number) => {
      if (paths_mission_item.id == mission_item.id) {
        console.log("delete", mission_item.id);
      } else {
        const landPosition = Paths[Paths.length -1]
        const mission_set: MissionItem = {
          _id: paths_mission_item._id,
          id: mission_index + "",
          type: paths_mission_item.type,
          lng: paths_mission_item.type == MissionItemType.LAND ? landPosition.lng : paths_mission_item.lng,
          lat: paths_mission_item.type == MissionItemType.LAND ? landPosition.lat : paths_mission_item.lat,
          display: paths_mission_item.type == MissionItemType.LAND ? landPosition.display - landPosition.agl : paths_mission_item.display,
          asl: paths_mission_item.type == MissionItemType.LAND ? landPosition.asl - landPosition.agl : paths_mission_item.asl,
          agl: paths_mission_item.type == MissionItemType.LAND ? landPosition.agl - landPosition.agl : paths_mission_item.agl,
          timeout: paths_mission_item.timeout,
        };
        MissionManager.getInstance().SetModifyMission([mission_set])
        mission_index = mission_index + 1;
        if (
          paths_mission_item.type == MissionItemType.WAYPOINT ||
          paths_mission_item.type == MissionItemType.LAND
        ) {
          const path_convert_cartesian = Cesium.Cartesian3.fromDegrees(
            mission_set.lng,
            mission_set.lat,
            mission_set.display
          );
          this._mission_path.push(path_convert_cartesian);
          this._mission_point_path.push(path_convert_cartesian);
        }
      }
    });
    this.setMissionPoint(Paths);
  }
  SetMissionCameraMove(mission:MissionObject) { //미션 수정모드로 진입 시 화면 이동
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();
    const ITEM = mission.items
    const startPosition = (ITEM[0].type == MissionItemType.TAKEOFF ? ITEM[1] : ITEM[0].type == MissionItemType.ARM) ? ITEM[2] : ITEM[0]
    const lastPosition = (ITEM[ITEM.length-1].type == MissionItemType.LAND || ITEM[ITEM.length-1].type == MissionItemType.RTL) ? ITEM[ITEM.length-2] : ITEM[ITEM.length-1]
    const convert_cartesian3 = Cesium.Cartesian3.fromDegrees(
      (startPosition.lng + lastPosition.lng) /2,
      (startPosition.lat + lastPosition.lat) /2,
      lastPosition.display + 100
    );
    const cartographic = Cesium.Cartographic.fromCartesian(convert_cartesian3);
    // cartographic.height = cartographic.height + 500;
    Cesium.Cartographic.toCartesian(cartographic);
    viewer.camera.setView({
      destination: Cesium.Cartographic.toCartesian(cartographic),
    });
  }
  SetMissionLand() {// 미션 Land 처리
    const Cesium = cesiumService.GetCesium();
    const Paths:MissionItem[] = MissionManager.getInstance().GetModifyMission()
    if (Paths.length == 0) {
      const Options: CustomAlertOptions = {
        position: "top left",
        color: "error",
        timeout: 1000,
      };
      CustomAlert.getInstance().ShowAlert("LAND is not available", Options);
    } else {
      const change_land: MissionItem = {
        id: String(Number(Paths[Paths.length - 1].id) + 1),
        lat: Paths[Paths.length - 1].lat,
        lng: Paths[Paths.length - 1].lng,
        display:
          Paths[Paths.length - 1].display -
          Paths[Paths.length - 1].agl,
        type: MissionItemType.LAND,
        timeout: 0,
        asl:
          Paths[Paths.length - 1].asl -
          Paths[Paths.length - 1].agl,
        agl: 0,
      };
      MissionManager.getInstance().SetModifyMission([change_land])
      const path_convert_cartesian = Cesium.Cartesian3.fromDegrees(
        Paths[Paths.length - 1].lng,
        Paths[Paths.length - 1].lat,
        Paths[Paths.length - 1].display
      );
      this._mission_path.push(path_convert_cartesian);
      store.commit("SetMissionModeState", MISSION_MODE_STATE.OFF); // mission type 변경 못하도록 설정
      this.setMissionMode(MissionItemType.NONE);
      this._mission_path_entry = [];
      this._mission_pointer.show = false;
    }
  }
  SetMissionTakeOff() {// 미션 TakeOff 처리
    const Paths:MissionItem[] = MissionManager.getInstance().GetModifyMission()
    if (Paths.length > 0) {
      const Options: CustomAlertOptions = {
        position: "top left",
        color: "error",
        timeout: 1000,
      };
      CustomAlert.getInstance().ShowAlert("Takeoff is not available", Options);
    } else {
      const arm_path: MissionItem = {
        display: 0,
        asl: 0,
        agl: 0,
        id: `0`,
        lat: 0,
        lng: 0,
        timeout: 2,
        type: MissionItemType.ARM,
      };
      const takeoff_path: MissionItem = {
        display: 0,
        asl: 0,
        agl: 5, // takeoff는 초기좌표에 대한 정보가 없으므로 agl값만 부여
        id: `1`,
        lat: 0,
        lng: 0,
        timeout: 3,
        type: MissionItemType.TAKEOFF,
      };
      MissionManager.getInstance().SetModifyMission([arm_path, takeoff_path])
    }
    console.log("TAKEOFF");
  }
  SetMissionRTL() {// 미션 RTL 처리
    const Paths:MissionItem[] = MissionManager.getInstance().GetModifyMission()
    if (Paths.length == 0) {
      const Options: CustomAlertOptions = {
        position: "top left",
        color: "error",
        timeout: 1000,
      };
      CustomAlert.getInstance().ShowAlert("LAND is not available", Options);
    } else {
      const change_rtl: MissionItem = {
        id: String(Number(Paths[Paths.length - 1].id) + 1),
        lat: Paths[0].lat,
        lng: Paths[0].lng,
        display: Paths[0].display,
        type: MissionItemType.RTL,
        timeout: 0,
        asl: Paths[0].asl,
        agl: Paths[0].agl,
      };
      MissionManager.getInstance().SetModifyMission([change_rtl])
      store.commit("SetMissionModeState", MISSION_MODE_STATE.OFF);
      this.setMissionMode(MissionItemType.NONE);
      this._mission_path_entry = [];
      this._mission_pointer.show = false;
    }
  }
  SetMissionUpdateAlt(number: number, mission_item: MissionItem) {// 미션 Waypoint 창 +,- btn --> Altitude
    const Cesium = cesiumService.GetCesium();
    const Paths:MissionItem[] = MissionManager.getInstance().GetModifyMission()
    if (Paths.length > 0) {
      this.click_takeoff_state =
      Paths[0].type === MissionItemType.ARM ? true : false;
    }
    Paths.forEach((path: MissionItem, index: number) => {
      if (path.id == mission_item.id) {
        if (number == -1 && path.agl <= 0) {
          CustomAlert.getInstance().ShowAlert("Can't modify Altitude");
        } else {
          if (
            path.type == MissionItemType.TAKEOFF ||
            path.type == MissionItemType.WAYPOINT
            ) {
            path.agl = path.agl + number;
            path.asl = path.asl + number;
            path.display = path.display + number;
            this._mission_path[
              this.click_takeoff_state ? Number(path.id) - 2 : path.id
            ] = Cesium.Cartesian3.fromDegrees(path.lng, path.lat, path.display);
            path.type === MissionItemType.TAKEOFF
              ? null
              : this.setMissionPoint(Paths);
          }
        }
      }
    });
  }
  SetMissionUpdateTime(number: number, mission_item: MissionItem) {// 미션 Waypoint 창 +,- btn --> Wait
    const Paths:MissionItem[] = MissionManager.getInstance().GetModifyMission()
    Paths.forEach((path: MissionItem, index: number) => {
      if (path.id == mission_item.id) {
        if (number == -1 && path.timeout <= 0) {
          CustomAlert.getInstance().ShowAlert("Can't modify Wait");
        } else {
          path.timeout = path.timeout + number;
        }
      }
    });
  }
  SetUserSettingApplyInit() {
    this.init()
  }
  private setModeSingle() {
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();

    this._ev_handler.setInputAction((point: any) => {
      this._single_path = [];
      this._single_description = "";
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    this._ev_handler.setInputAction(
      (point: any) => {
        const mouse_position = viewer.camera.pickEllipsoid(point.endPosition);
        if (mouse_position) {
          const pick = viewer.camera.getPickRay(point.endPosition);
          const globe = viewer.scene.globe.pick(pick, viewer.scene);

          const selected_drone:CESIUM_DRONE|undefined = store.getters.GetSingleDrone;
          if(selected_drone) {
            const selected_drone_state = selected_drone.GetState();
            if(selected_drone_state) {
              const drone_position = selected_drone.GetPosition().pos;
  
              const from_cartographic = Cesium.Cartographic.fromCartesian(
                new Cesium.Cartesian3(
                  drone_position.x,
                  drone_position.y,
                  drone_position.z
                )
              );
              const to_cartographic = Cesium.Cartographic.fromCartesian(globe);
              to_cartographic.height = from_cartographic.height;
    
              const from = Cesium.Cartographic.toCartesian(from_cartographic);
              const to = Cesium.Cartographic.toCartesian(to_cartographic);
              this._single_path = [from, to];
    
              this._single_description_entity.position = to;
              if (selected_drone && selected_drone_state) {
                this._single_description = `Right-click to move`;
              }
            }
          }
        }
      },
      Cesium.ScreenSpaceEventType.MOUSE_MOVE,
      Cesium.KeyboardEventModifier.CTRL
    );

    this._ev_handler.setInputAction(
      (point: any) => {
        const mouse_position = viewer.camera.pickEllipsoid(point.endPosition);
        if (mouse_position) {
          const pick = viewer.camera.getPickRay(point.endPosition);
          const globe = viewer.scene.globe.pick(pick, viewer.scene);

          const selected_drone:CESIUM_DRONE|undefined = store.getters.GetSingleDrone;
          if(selected_drone) {
            const selected_drone_state = selected_drone.GetState();
            const drone_position = selected_drone.GetPosition().pos;
  
            const from_cartographic = Cesium.Cartographic.fromCartesian(
              new Cesium.Cartesian3(
                drone_position.x,
                drone_position.y,
                drone_position.z
              )
            );
            const to_cartographic = Cesium.Cartographic.fromCartesian(globe);
            to_cartographic.height =
              from_cartographic.height + this._single_appending_altitude;
  
            const from = Cesium.Cartographic.toCartesian(from_cartographic);
            const to = Cesium.Cartographic.toCartesian(to_cartographic);
            this._single_path = [from, to];
  
            this._single_description_entity.position = to;
  
            if (selected_drone && selected_drone_state) {
              this._single_description = `Right-click to move\n${this._single_appending_altitude} m`;
            }
          }
        }
      },
      Cesium.ScreenSpaceEventType.MOUSE_MOVE,
      Cesium.KeyboardEventModifier.ALT
    );

    this._ev_handler.setInputAction(
      (point: any) => {
        point > 0
          ? this._single_appending_altitude++
          : this._single_appending_altitude--;

        if (this._single_path.length >= 2) {
          const from_cartographic = Cesium.Cartographic.fromCartesian(
            this._single_path[0]
          );
          const to_cartographic = Cesium.Cartographic.fromCartesian(
            this._single_path[1]
          );
          to_cartographic.height =
            from_cartographic.height + this._single_appending_altitude;

          const from = Cesium.Cartographic.toCartesian(from_cartographic);
          const to = Cesium.Cartographic.toCartesian(to_cartographic);
          this._single_path = [from, to];

          this._single_description_entity.position = to;

          const selected_drone:CESIUM_DRONE|undefined = store.getters.GetSingleDrone;
          if(selected_drone) {
            const selected_drone_state = selected_drone?.GetState();
            if (selected_drone_state) {
              this._single_description = `Right-click to move\n${this._single_appending_altitude} m`;
            }
          }
        }
      },
      window.Cesium.ScreenSpaceEventType.WHEEL,
      Cesium.KeyboardEventModifier.ALT
    );

    this._ev_handler.setInputAction(
      () => {
        if (this._single_path.length >= 2) {
          const selected_drone: CESIUM_DRONE | undefined =
            store.getters.GetSingleDrone;
          const selected_drone_state = selected_drone?.GetState();
          if (selected_drone && selected_drone_state) {
            const to_cartographic = Cesium.Cartographic.fromCartesian(
              this._single_path[1]
            );
            const lon = Cesium.Math.toDegrees(to_cartographic.longitude) * 1e7;
            const lat = Cesium.Math.toDegrees(to_cartographic.latitude) * 1e7;
            const alt =
              selected_drone_state._gps_int._relative_alt / 1e3 +
              this._single_appending_altitude;

            SingleManager.getInstance().FlyToTarget(
              selected_drone.GetNoti()._index,
              lat,
              lon,
              alt
            );
          }
        }

        // 항상 현재 Relative 고도를 시작점으로 유지하기 위함
        this._single_appending_altitude = 0;
      },
      window.Cesium.ScreenSpaceEventType.RIGHT_CLICK,
      Cesium.KeyboardEventModifier.ALT
    );

    this._ev_handler.setInputAction(
      () => {
        if (this._single_path.length >= 2) {
          const selected_drone: CESIUM_DRONE | undefined =
            store.getters.GetSingleDrone;
          const selected_drone_state = selected_drone?.GetState();
          if (selected_drone && selected_drone_state) {
            const to_cartographic = Cesium.Cartographic.fromCartesian(
              this._single_path[1]
            );
            const lon = Cesium.Math.toDegrees(to_cartographic.longitude) * 1e7;
            const lat = Cesium.Math.toDegrees(to_cartographic.latitude) * 1e7;
            const alt = selected_drone_state._gps_int._relative_alt / 1e3;

            SingleManager.getInstance().FlyToTarget(
              selected_drone.GetNoti()._index,
              lat,
              lon,
              alt
            );
          }
        }
      },
      window.Cesium.ScreenSpaceEventType.RIGHT_CLICK,
      Cesium.KeyboardEventModifier.CTRL
    );

    this._ev_handler.setInputAction((movement: any) => {

      const pick = viewer.scene.pick(movement.position, 10, 10);
      if(pick && pick.id && pick.id._id) {
        const pick_entity_id:string = pick.id._id;
        const drone = DroneManager.getInstance().GetDrone(pick_entity_id);
        if(drone) {
          SingleManager.getInstance().SetSingleTarget(drone);
        }
      }

      /**
       * Drill Pick 을 통한 선택도 연구 필요
       */
      // const drill_pick = viewer.scene.pick(movement.position, 100, 10, 10);
      // console.log(drill_pick)
      // drill_pick.forEach((pick:any) => {
      //   if(pick && pick.id && pick.id._id) {
      //     console.log(pick.id._id)
      //     const pick_entity_id:string = pick.id._id;
      //     const drone = DroneManager.getInstance().GetDrone(pick_entity_id);
      //     if(drone) {
      //       SingleManager.getInstance().SetSingleTarget(drone);
      //     }
      //   }
      // });

    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  }
  private setModeCreateVirtualDrone() {
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();

    this._virtual_drone.show = true;

    this._ev_handler.setInputAction((point: any) => {
      const mouse_position = viewer.camera.pickEllipsoid(point.endPosition);
      if (mouse_position) {
        const pick = viewer.camera.getPickRay(point.endPosition);
        const globe = viewer.scene.globe.pick(pick, viewer.scene);
        this._virtual_drone.position = globe.clone();
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    this._ev_handler.setInputAction((movement: any) => {
      const pick = viewer.camera.getPickRay(movement.position);
      const globe = viewer.scene.globe.pick(pick, viewer.scene);
      if (globe) {
        const cartographic = Cesium.Cartographic.fromCartesian(globe);
        const longitude = Cesium.Math.toDegrees(cartographic.longitude);
        const latitude = Cesium.Math.toDegrees(cartographic.latitude);
        const alt = Cesium.Cartographic.fromCartesian(globe).height;
        DroneVirtualManager.getInstance().CreateVirtualDrone(
          longitude,
          latitude,
          alt
        );
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    this._ev_handler.setInputAction(() => {
      this.setModeDefault();
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
  }
  private setModeDefault() {
    // All Event Remove
    const viewer = cesiumService.GetViewer();
    const ev_handler = this._ev_handler;
    const Cesium = cesiumService.GetCesium();
    CesiumScreenSpaceCameraControllerService.getInstance().EnableRotate(true);
    
    const key = [
      Cesium.ScreenSpaceEventType.LEFT_DOWN,
      Cesium.ScreenSpaceEventType.LEFT_UP,
      Cesium.ScreenSpaceEventType.LEFT_CLICK,
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK,
      Cesium.ScreenSpaceEventType.RIGHT_DOWN,
      Cesium.ScreenSpaceEventType.RIGHT_UP,
      Cesium.ScreenSpaceEventType.RIGHT_CLICK,
      Cesium.ScreenSpaceEventType.MIDDLE_DOWN,
      Cesium.ScreenSpaceEventType.MIDDLE_UP,
      Cesium.ScreenSpaceEventType.MIDDLE_CLICK,
      Cesium.ScreenSpaceEventType.MOUSE_MOVE,
      Cesium.ScreenSpaceEventType.WHEEL,
      Cesium.ScreenSpaceEventType.PINCH_START,
      Cesium.ScreenSpaceEventType.PINCH_END,
      Cesium.ScreenSpaceEventType.PINCH_MOVE,
    ];

    const modifier = [
      Cesium.KeyboardEventModifier.SHIFT,
      Cesium.KeyboardEventModifier.CTRL,
      Cesium.KeyboardEventModifier.ALT,
    ];

    key.forEach((k) => {
      if (ev_handler.getInputAction(k)) ev_handler.removeInputAction(k);
      modifier.forEach((m) => {
        if (ev_handler.getInputAction(k, m)) ev_handler.removeInputAction(k, m);
      });
    });
    const Paths:MissionItem[] = MissionManager.getInstance().GetModifyMission()
    Paths.forEach((mission: MissionItem, index: number) => {
      viewer.entities.removeById(
        `altitude${
          mission.type === MissionItemType.LAND
            ? "-land"
            : mission.type === MissionItemType.RTL
            ? "-rtl"
            : ""
        }-${mission.id}`
      );
      viewer.entities.removeById(
        `lnglat${
          mission.type === MissionItemType.LAND
            ? "-land"
            : mission.type === MissionItemType.RTL
            ? "-rtl"
            : ""
        }-${mission.id}`
      );
      viewer.entities.removeById(
        `label${
          mission.type === MissionItemType.LAND
            ? "-land"
            : mission.type === MissionItemType.RTL
            ? "-rtl"
            : ""
        }-${mission.id}`
      );
    });

    if (this._virtual_drone) this._virtual_drone.show = false;
    if (this._mission_pointer) this._mission_pointer.show = false;
    this._single_path = [];
    this._mission_path = [];
    this._mission_path_entry = [];
    this._mission_point_path = [];
    MissionManager.getInstance().ClearModifyMission();
  }
      // this._ev_handler.setInputAction((point: any) => {
    //   const pick = viewer.camera.getPickRay(point.endPosition);
    //   let mouse_position = viewer.scene.globe.pick(pick, viewer.scene);
    //   if (mouse_position) {
    //     const cartographic = Cesium.Cartographic.fromCartesian(mouse_position);
    //     const longitude = Cesium.Math.toDegrees(cartographic.longitude);
    //     const latitude = Cesium.Math.toDegrees(cartographic.latitude);

    //     let close_mission_item = undefined;

    //     this._mission_path.forEach((mission_item:any) => {
    //       const item_cartographic = Cesium.Cartographic.fromCartesian(mission_item);

    //       const item_longitude = Cesium.Math.toDegrees(item_cartographic.longitude);
    //       const item_latitude = Cesium.Math.toDegrees(item_cartographic.latitude);

    //       const from = turf.point([longitude, latitude]);
    //       const to = turf.point([item_longitude, item_latitude]);

    //       const distance = turf.distance(from, to, { units: 'meters' });
    //       if(distance < 30)
    //       {
    //         close_mission_item = mission_item;
    //       }
    //     });

    //     if(close_mission_item)
    //     {
    //       mouse_position = close_mission_item;
    //     }

    //     this._mission_pointer.position = mouse_position.clone();

    //     const promise = this._mission_generator.GetPath(mouse_position, MissionItemType.WAYPOINT);
    //     promise.then((paths:MissionItem[]) => {
    //       this._mission_path_entry = [];
    //       paths.forEach((path: MissionItem, index:number) => {
    //         this._mission_path_entry.push(Cesium.Cartesian3.fromDegrees(path.lng, path.lat, path.display_alt));
    //       });
    //     })
    //   }
    // }, Cesium.KeyboardEventModifier.SHIFT, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    // this._ev_handler.setInputAction((movement: any) => {
    //   this.SetSelectedDroneMissionRemove()
    //   const pick = viewer.camera.getPickRay(movement.position);
    //   let mouse_position = viewer.scene.globe.pick(pick, viewer.scene);
    //   if (mouse_position) {
    //     const cartographic = Cesium.Cartographic.fromCartesian(mouse_position);
    //     const longitude = Cesium.Math.toDegrees(cartographic.longitude);
    //     const latitude = Cesium.Math.toDegrees(cartographic.latitude);

    //     let close_mission_item = undefined;

    //     this._mission_path.forEach((mission_item:any) => {
    //       const item_cartographic = Cesium.Cartographic.fromCartesian(mission_item);

    //       const item_longitude = Cesium.Math.toDegrees(item_cartographic.longitude);
    //       const item_latitude = Cesium.Math.toDegrees(item_cartographic.latitude);

    //       const from = turf.point([longitude, latitude]);
    //       const to = turf.point([item_longitude, item_latitude]);

    //       const distance = turf.distance(from, to, { units: 'meters' });
    //       if(distance < 30)
    //       {
    //         close_mission_item = mission_item;
    //       }
    //     });

    //     if(close_mission_item)
    //     {
    //       mouse_position = close_mission_item;
    //     }

    //     this._mission_pointer.position = mouse_position.clone();

    //     const promise = this._mission_generator.GetPath(mouse_position, MissionItemType.WAYPOINT);
    //     promise.then((paths:MissionItem[]) => {
    //       if (paths.length > 1) {
    //         paths.splice(0, 1);
    //       }
    //       store.commit('AddMissionsModeArr', paths);
    //       const append_paths_entry:any = [];
    //       paths.forEach((path: MissionItem) => {
    //         append_paths_entry.push(Cesium.Cartesian3.fromDegrees(path.lng, path.lat, path.display_alt));
    //       });
    //       this._mission_path = this._mission_path.concat(append_paths_entry);
    //     })
    //   }
    // }, Cesium.ScreenSpaceEventType.LEFT_CLICK, Cesium.KeyboardEventModifier.SHIFT);
}
