
import CESIUM_DRONE from "@/services/hg/cesium_drone";
import DroneManager from "@/services/hg/drone_manager";
import SingleManager from "@/services/hg/single/single_manager";
import { DroneMissionStop } from "@/services/ws/ws_hg_server_message_sender";
import store from "@/store";
import axios from "axios";
import { computed, defineComponent } from "vue";

interface FILE_INFO {
  type: number,
  type_name: string,
  name: string,
  ext: string,
  size: number,
  birth: number,
  latest: number,
}

export default defineComponent({
  data() {
    return {
      show: false,
      drones: [] as CESIUM_DRONE[],
      version_list: [] as FILE_INFO[],
      selected_version: new Map() as Map<string,string>,
    }
  },
  setup() {
    const store_show_fc_force_update = computed<boolean>(() => store.getters.GetShowFCForceUpdate);
    const store_drones = computed<CESIUM_DRONE[]>(() => store.getters.GetDrones);
    return {store_show_fc_force_update, store_drones};
  },

  watch: {
    store_show_fc_force_update: {
      handler(val, oldVal) {
        this.show = val;
      },
    },
    store_drones: {
      handler(val, oldVal) {
        this.drones = val.filter((d:CESIUM_DRONE)=> !d.GetNoti()._simulate);
          // this.drones = val;
      },
    }
  },

  mounted() {
    axios.get('https://ota.hogreenair.com/version/all').then((value) => {
      this.version_list = value.data;
    })
  },
  
  methods: {
    SetShow(show:boolean) {
      store.commit('SetShowFCForceUpdate', show);
      this.show = show;
    },

    VersionSelect(device_name:string, file_info:FILE_INFO) {
      this.selected_version.set(device_name, file_info.name);
    },

    GetSelectVersionName(device_name:string) {
      return this.selected_version.get(device_name);
    },

    DevicePerAvailableList(device_type:string|undefined): FILE_INFO[] {
      const list:FILE_INFO[] = [];

      if(device_type) {
        this.version_list.forEach((version:FILE_INFO) => {
          if(device_type.startsWith(version.type_name)) {
            list.push(version);
          }
        });  
      }

      return list;
    },

    UpdateFCController(device_name:string, version_name:string|undefined) {
      if(version_name == undefined) return;
      const target_drone:CESIUM_DRONE|undefined = DroneManager.getInstance().GetDrone(device_name);
      if(target_drone == undefined) return;
      SingleManager.getInstance().Command('hovering', target_drone.GetNoti()._index);
      DroneMissionStop(target_drone, false);
      try {
        axios.post('https://ota.hogreenair.com/client/update', {device_serial: device_name, filename: version_name})
        .then((value) => {
          // 
        })
        .catch((reason) => {
          console.error(reason);
        })
      } catch (error) {
        console.error(error);
      }
    }
  },
});
