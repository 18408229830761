export enum E_EQUIPMENT {
    CAMERA = 1 <<0,
	GPS = 1 << 1,
	LIDAR = 1 << 2,
}

export enum E_LIDAR {
    NONE= 0,
	SF40C,
}

export enum E_GPS {
    NONE= 0,
	NORMAL,
	MBC_RTK,
	KR_RTK,
}

export enum E_CAMERA {
    NONE= 0,
	USB,
	VIEWPRO,
	ONVIF,
}