import { cesiumService } from "./cesium_service_inst";

enum CameraEventType {
  LEFT_DRAG,
  RIGHT_DRAG,
  MIDDLE_DRAG,
  WHEEL,
  PINCH,
}

enum KeyboardEventModifier {
  SHIFT,
  CTRL,
  ALT,
}

interface EventType {
  eventType: CameraEventType,
  modifier?: KeyboardEventModifier,
}

export default class CesiumScreenSpaceCameraControllerService {
  private static instance: CesiumScreenSpaceCameraControllerService;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  private _camera_controller: any;

  constructor() {
    const viewer = cesiumService.GetViewer();
    this._camera_controller = viewer.scene.screenSpaceCameraController;

    // LOOK
    const look:EventType = {eventType: CameraEventType.LEFT_DRAG, modifier: KeyboardEventModifier.SHIFT}
    this._camera_controller.lookEventTypes = look;
    
    // ROTATE
    this._camera_controller.rotateEventTypes = CameraEventType.LEFT_DRAG;
    
    // TILT
    const tilt1:EventType = {eventType: CameraEventType.LEFT_DRAG, modifier: KeyboardEventModifier.CTRL}
    const tilt2:EventType = {eventType: CameraEventType.RIGHT_DRAG}
    this._camera_controller.tiltEventTypes = [CameraEventType.MIDDLE_DRAG, CameraEventType.PINCH, tilt1, tilt2]

    // TRANSLATE
    this._camera_controller.translateEventTypes = CameraEventType.LEFT_DRAG;

    // ZOOM
    this._camera_controller.zoomEventTypes = [CameraEventType.WHEEL, CameraEventType.PINCH]
  }

  CameraLock(lock: boolean) {
    if (lock) {
      this._camera_controller.enableInputs = false;
    } else {
      this._camera_controller.enableInputs = true;
    }
  }
  // 좌클릭 방지
  EnableRotate(lock: boolean) {
    lock
      ? (this._camera_controller.enableRotate = true)
      : (this._camera_controller.enableRotate = false);
  }
  // 우클릭 방지
  EnableTilt(lock: boolean) {
    lock
      ? (this._camera_controller.enableTilt = true)
      : (this._camera_controller.enableTilt = false);
  }
  EnableZoom(lock: boolean) {
    lock
      ? (this._camera_controller.enableZoom = true)
      : (this._camera_controller.enableZoom = false);
  }
  EnableLook(lock: boolean) {
    lock
      ? (this._camera_controller.enableLook = true)
      : (this._camera_controller.enableLook = false);
  }
}
