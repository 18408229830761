import vuetify from '@/plugins/vuetify'; // path to vuetify export
import axios from 'axios';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { cesiumService } from './services/cesium/cesium_service_inst';
import store from './store';

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'https://gcs.hogreenair.com' : '/';
Vue.prototype.$http = axios;

Vue.prototype.$store = store;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$cesiumService = cesiumService;