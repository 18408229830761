export function TimestampToString(timestamp:number) {
    const dt:Date = new Date(timestamp);
    const yyyy = dt.getFullYear().toString().padStart(4, '0');
    const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
    const dd = dt.getDate().toString().padStart(2, '0');
    const hh = dt.getHours().toString().padStart(2, '0');
    const mm = dt.getMinutes().toString().padStart(2, '0');
    const ss = dt.getSeconds().toString().padStart(2, '0');
    return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
    // const ms = dt.getMilliseconds().toString().padStart(3, '0');
    // return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}.${ms}`;
}

export function DateToString(dt:Date) {
    const yyyy = dt.getFullYear().toString().padStart(4, '0');
    const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
    const dd = dt.getDate().toString().padStart(2, '0');
    const hh = dt.getHours().toString().padStart(2, '0');
    const mm = dt.getMinutes().toString().padStart(2, '0');
    const ss = dt.getSeconds().toString().padStart(2, '0');
    const ms = dt.getMilliseconds().toString().padStart(3, '0');
    return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}.${ms}`;
}