var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"navigation","outlined":""}},[_c('v-navigation-drawer',{attrs:{"hide-overlay":"","width":"100%","color":"#000000bf"},model:{value:(_vm.tab_open),callback:function ($$v) {_vm.tab_open=$$v},expression:"tab_open"}},[_c('div',{staticClass:"space"}),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"menu-top"},[_c('div',{staticClass:"user-info-box"},[_c('div',{staticClass:"user-img-box"},[_c('div',{staticClass:"user-img-round"})]),_c('div',{staticClass:"user-info"},[_c('p',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.account_id.toUpperCase()))]),_c('p',{staticClass:"monitor-text"},[_vm._v(" "+_vm._s(_vm.app_type & 4 ? "GCS" : _vm.app_type & 8 ? "Monitor" : "Unknown")+" ")])])])]),_c('div',{staticClass:"divid-line"}),_vm._l((_vm.function_item),function(item){return _c('v-btn',{key:item.title,staticClass:"menu-item-btn",on:{"click":function($event){[
                  item.action === 'showController' ? _vm.OnClickController()
                : item.action === 'Simulation Group' ? _vm.OnClickRunSimulationTestBtn()
                : item.action === 'VirtualDrone' ? _vm.OnClickCreateVirtualDroneTestBtn()
                : item.action === 'LogView' ? _vm.OnClickLogView()
                : item.action === 'NoFlight' ? _vm.OnClickNoFlightZone()
                : item.action === 'Firmware'  ? _vm.OnClickFCForceUpdate() 
                : item.action === 'Setting' ? _vm.OnClickSetting()
                : item.action === 'License' ? _vm.OnClickLicense()
                : item.action === 'H2LogData' ? _vm.OnClickH2LogData()
                : item.action === 'GcsGuide' ? _vm.OnClickGcsGuide()
                : undefined ]}}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"menu-icon",attrs:{"src":require(`@/assets/menu_tab_icon/${item.icon}.png`)}})]),_c('p',{staticClass:"menu-item-text"},[_vm._v(_vm._s(item.title))])])}),_c('div',{staticClass:"divid-line"}),_vm._l((_vm.extension_function),function(item){return _c('v-btn',{key:item.title,staticClass:"menu-item-btn",on:{"click":function($event){[
                item.action === 'DroneTV' ? _vm.OnClickDroneTV()
              : item.action === 'facial Recognition' ? _vm.OnClickFacialRecognition() 
              : item.action === 'ai' ? _vm.OnClickAiExtension() 
              : undefined ]}}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"menu-icon",attrs:{"src":require(`@/assets/menu_tab_icon/${item.icon}.png`)}})]),_c('p',{staticClass:"menu-item-text"},[_vm._v(_vm._s(item.title))])])}),_c('v-btn',{staticClass:"menu-item-btn",on:{"click":function($event){return _vm.OnClickExtensionFunction()}}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"menu-ai-icon",attrs:{"src":require(`@/assets/menu_tab_icon/plus-function.png`)}})]),_c('p',{staticClass:"menu-item-text"},[_vm._v("Extension")])]),_c('div',{staticClass:"menu-bottom-box"},[_c('v-btn',{staticClass:"menu-item-btn menu-bottom"},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"menu-icon",attrs:{"src":require(`@/assets/menu_tab_icon/Guide.png`)}})]),_c('p',{staticClass:"menu-item-text"},[_vm._v("GCS Guide")])]),_c('v-btn',{staticClass:"menu-item-btn menu-bottom",attrs:{"target":"_blank","href":"https://drive.google.com/file/d/1DCrCzvegpvHIZESLj82FAnQ0A02he0Uj/view?usp=share_link"}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"menu-icon",attrs:{"src":require(`@/assets/menu_tab_icon/manual.png`)}})]),_c('p',{staticClass:"menu-item-text"},[_vm._v("GCS Menual")])])],1)],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }