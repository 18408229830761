
import CesiumScreenSpaceEventService, { GCS_FEATURE, GCS_MODE, GCS_ModeInfo, MISSION_MODE_STATE } from "@/services/cesium/cesium_screen_space_ev_service";
import CESIUM_DRONE from "@/services/hg/cesium_drone";
import CustomAlert from "@/services/hg/custom_alert";
import MissionWaypointGeneratingManager from "@/services/hg/mission/mission_generating_tool_manager";
import MissionManager, { MissionItem, MissionObject } from "@/services/hg/mission/mission_manager";
import SimulateManager from "@/services/hg/simulate/simulate_manager";
import SingleManager from "@/services/hg/single/single_manager";
import UserSettingManager from "@/services/hg/user_setting/user_setting_manager";
import store from "@/store";
import { computed, defineComponent } from "vue";
export enum ModalType {
  NONE = 0,
  MISSIONSAVE,
  MISSIONDELETE,
}
export default defineComponent({
  data() {
    return {
      dialog: false,
      input_text: '',
      takeoff_alt: 5,
      simulate_speed: 5,
    }
  },
  setup() {
    const show_modal = computed(() => store.getters.GetModalWindow);
    const modal_type = computed(() => store.getters.GetModalType);
    const mission_packet = computed(() => store.getters.GetMissionPacket);
    const delete_mission = computed(() => store.getters.GetDeleteMission);
    const single_drone = computed<CESIUM_DRONE|undefined>(() => store.getters.GetSingleDrone);
    const simulate_target_mission = computed<MissionObject>(() => store.getters.GetSimulateTargetMission);
    return { show_modal, mission_packet, modal_type, delete_mission, single_drone, simulate_target_mission};
  },
  watch: {
    show_modal: {
      handler(n, o) {
        if(n) {
          this.takeoff_alt = UserSettingManager.getInstance().Get().takeoff_initial;
        }
      },
    },
  },
  
  mounted() {
    window.addEventListener("keyup", (key) => {
      this.SetKeyboardEvent(key)
    })
  },
  methods: {
    SetKeyboardEvent(key:any) {
      if (key.code == 'Escape') {
        this.onClickCloseBtn()
      } else if (key.code == 'Enter' && this.modal_type === 'save_mission') {
        this.onClickNewMissionSaveBtn()
      } else if (key.code == 'Enter' && this.modal_type === 'delete_mission') {
        this.onClickDeleteMission()
      } else if (key.code == 'Enter' && this.modal_type === 'modify_mission') {
        this.onClickModifyNameBtn()
      } else if (key.code == 'Enter' && this.modal_type === 'modify_mission_save') {
        this.onClickModifiedMissionSave()
      }
    },
    onClickCloseBtn() {
      this.input_text = ''
      store.commit("SetModalWindow", false);
    },
    onClickDeleteMission() {
      MissionManager.getInstance().SetMissionDelete(store.getters.GetDeleteMission)
      CesiumScreenSpaceEventService.getInstance().DeleteMissionEntityRemove()
      store.commit("SetModalWindow", false);
      store.commit("SetSideMissionDetailTab", false);
      store.commit("SetSideMissionTab", true);
    },
    onClickNewMissionSaveBtn() {
      if (this.input_text == '') {
        CustomAlert.getInstance().ShowAlert('Input the mission name')
      } else {
        MissionManager.getInstance().SetGCSMode(GCS_MODE.NORMAL)
        const NewMissionPacket:MissionObject = {
          account: store.getters.GetLoginData.id,
          alias: this.input_text,
          items: this.mission_packet.items
        }
        MissionManager.getInstance().SetMissionSendToWAS(NewMissionPacket),
        this.input_text = ''
        store.commit("SetMissionPacket", {
          alias: "",
          items: {},
        });
        store.commit("SetModalWindow", false);
        store.commit("SetMissionModeState", MISSION_MODE_STATE.ON);
        CesiumScreenSpaceEventService.getInstance().SetMode(GCS_FEATURE.NORMAL);
        store.commit("SetMissionGenerateTab", false);
        store.commit("SetSideMissionTab", true);
        store.commit("SetWayPointBox", false);
        store.commit("SetMissionData", []);
        store.commit("SetShowCommandSingle", false);
      }
    },
    onClickModifyNameBtn() {
      if (this.input_text == '') {
        CustomAlert.getInstance().ShowAlert('Input the mission name')
      } else {
        store.commit("SetEditMissionName", this.input_text);
        store.commit("SetModalWindow", false);
        this.input_text = ''
      }
    },
    onClickModifiedMissionSave() {
      MissionManager.getInstance().SetGCSMode(GCS_MODE.NORMAL)
      const ModifiedMission:MissionItem[] = MissionManager.getInstance().GetModifyMission();
      const ModifiedMissionPacket:MissionObject|undefined = MissionManager.getInstance().SetMissionPacket(ModifiedMission);
      if(ModifiedMissionPacket) {
        MissionManager.getInstance().SetMissionModify(ModifiedMissionPacket);
        SimulateManager.getInstance().DeleteSimulate(ModifiedMissionPacket.alias);
      }
      this.input_text = ''
      store.commit("SetModalWindow", false);
      CesiumScreenSpaceEventService.getInstance().SetMode(GCS_FEATURE.NORMAL);
      MissionWaypointGeneratingManager.getInstance().SetOptsDefault();
      MissionManager.getInstance().SetSelectedMissionEntity('');
      store.commit("SetSideMissionTab", true);
      store.commit("SetWayPointBox", false);
      store.commit("SetSideMissionDetailTab", false);
      store.commit("SetMissionData", []);
      store.commit("SetShowCommandSingle", false);
      store.commit("SetMissionModeState", MISSION_MODE_STATE.ON);
      store.commit("SetEditMissionName", '');
    },
    onClickTakeoffAltitude() {
      //확인 버튼을 눌렀을때만
        // 정수일 경우에만
        const regx = /^[0-9]+$/
        if(regx.test(this.takeoff_alt.toString())) {
        // if (!isNaN(Number(this.input_text))) {
          if(this.single_drone) {
            SingleManager.getInstance().Command(
            this.modal_type,
            this.single_drone.GetNoti()._index,
            Number(this.takeoff_alt)
          );
          store.commit("SetModalWindow", false)
          }
        } else
          CustomAlert.getInstance().ShowAlert(
            "Takeoff altitude is not a number"
          );
      // SetTakeoffAltitude
    },
    onClickSimulation() {
      const regx = /^[0-9]+$/
      if(regx.test(this.simulate_speed.toString())) {
        store.commit("SetModalWindow", false)
        SimulateManager.getInstance().RunMissionSimulate(this.simulate_target_mission, this.simulate_speed);
      }
    }
  },
});
