
import CustomAlert from "@/services/hg/custom_alert";
import LogDataManager, { FC_CSV_FILE, Group_Log, Video_Log } from "@/services/hg/log_data/log_data_manager";
import { LINE_CHART_SERIES_FROM, RANGE_CHART_SERIES_FORM, ST_LOG_FILE_INFO } from "@/services/report/LogFileManager";
import { RequestStackLogFileData, RequestStackLogFileList } from "@/services/ws/ws_hg_server_message_sender";
import { HG_Hydrogen_Log_Info2, HG_LogFileInfo, HG_LogFileItem } from "@/services/ws/ws_hg_server_packet_interface";
import store from "@/store";
import { computed, defineComponent } from "vue";
import VueApexCharts from 'vue-apexcharts';
export default defineComponent({
  data() {
    const dt_today = new Date();
    const dt_before_month = new Date();
    dt_before_month.setFullYear(dt_today.getFullYear(), dt_today.getMonth() - 1, dt_today.getDate());
    return {
      start_date_state: false,
      start_time_state: false,
      end_date_state: false,
      end_time_state: false,
      start_date: `${dt_today.getFullYear()}-${(dt_today.getMonth() + 1).toString().padStart(2, '0')}-${dt_today.getDate().toString().padStart(2, '0')}`,
      start_time: `${dt_today.getHours()-1 < 10 ? '0'+(dt_today.getHours()-1).toString() : (dt_today.getHours()-1).toString()}:${dt_today.getMinutes() < 10 ? '0'+dt_today.getMinutes().toString() : dt_today.getMinutes()}`,
      end_date: `${dt_today.getFullYear()}-${(dt_today.getMonth() + 1).toString().padStart(2, '0')}-${dt_today.getDate().toString().padStart(2, '0')}`,
      end_time: `${dt_today.getHours() < 10 ? '0'+ dt_today.getHours().toString() : dt_today.getHours()}:${dt_today.getMinutes() < 10 ? '0'+dt_today.getMinutes().toString() : dt_today.getMinutes()}`,

      sort_group: ['All'] as any,
      selected_log_type: 'All',
      date_sort: 'up',

      video_log_state: true,
      
      selected_video: {} as Video_Log,
      log_type: 0 as number,
      input_text: '',
      log_group_box_state: 'none',
      selected_top_log_info: {} as any,
      filterList: "All",
      sort: false,
      log_group_lists: [] as any,
      sortKey: "size",
      sortOrder: "asc",
      group_log_data: {} as Group_Log,
      all_log_list: [] as ST_LOG_FILE_INFO[],
      fc_data_series: [{ data: [], }] as any[],
      soar_state_series: [{ data: [], }],
      soar_data_series: [{ data: [], }],
      drone_log_list_filter: [] as ST_LOG_FILE_INFO[],
      search_date: `${dt_today.getFullYear()}-${(dt_today.getMonth() + 1).toString().padStart(2, '0')}-${dt_today.getDate().toString().padStart(2, '0')}`,
      search_start_time: `13:00`,
      search_end_time: `14:00`,
      search_end_date: `${dt_today.getFullYear()}-${(dt_today.getMonth() + 1).toString().padStart(2, '0')}-${dt_today.getDate().toString().padStart(2, '0')}`,
      selectedChartState: 'data',
      line_chart_options: {
        chart: {
          type: 'line',
          toolbar: {
            show: true,
          },
          foreColor: '#fff',
          animations: {
            enabled: true,
          },
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          onDatasetHover: {
            highlightDataSeries: false,
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,
            formatter: function (value:number) {
              const dt = new Date(value);
              const yyyy = dt.getFullYear().toString().padStart(4, '0');
              const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
              const dd = dt.getDate().toString().padStart(2, '0');
              const hh = dt.getHours().toString().padStart(2, '0');
              const mm = dt.getMinutes().toString().padStart(2, '0');
              const ss = dt.getSeconds().toString().padStart(2, '0');
              return `${hh}:${mm}:${ss}`;
            }
          },
        },
        yaxis: {
          min: 0,
        },
        legend: {
          show: true
        },
        stroke: {
          width: 2
        },
        grid: {
          borderColor:'#656565'
        }
      },
      range_chart_options: {
        chart: {
          foreColor: '#ffffff',
          type: 'rangeBar'
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          onDatasetHover: {
            highlightDataSeries: false,
          },
          custom: function({series, seriesIndex, dataPointIndex, w}:any) {
            let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let period = 0;
            if(data.y.length == 2){
              period = (data.y[1] - data.y[0]);
            }
            return '<span style="margin: 10px;">' + '<b>' + data.x + '</b>: ' + period + 'ms' + '</span>';
          },
          x: {
            show: true,
            format: 'yyyy-MM-dd hh:mm:ss',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,

            formatter: function (value:number) {
              const dt = new Date(value);
              const yyyy = dt.getFullYear().toString().padStart(4, '0');
              const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
              const dd = dt.getDate().toString().padStart(2, '0');
              const hh = dt.getHours().toString().padStart(2, '0');
              const mm = dt.getMinutes().toString().padStart(2, '0');
              const ss = dt.getSeconds().toString().padStart(2, '0');
              return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
            }
          },
        },
      },
    };
  },

  setup() {
    const log_file = computed(() => store.getters.GetLogFile);
    const log_file_info = computed<HG_LogFileInfo>(() => store.getters.GetLogFileInfo);
    const log_progress = computed(() => store.getters.GetLogProgress);
    const showLogData = computed(() => store.getters.GetFcLogData);
    const log_list = computed<HG_LogFileItem[]>(() => store.getters.GetLogList);
    const log_save_done_list = computed(() => store.getters.GetLogSaveDoneList);
    const fc_log_data_list = computed(() => store.getters.GetFcLogDataList);
    const h2_log_file_list = computed<ST_LOG_FILE_INFO[]>(() => store.getters.GetH2LogFileList);
    const ReportSoarState = computed<RANGE_CHART_SERIES_FORM[]>(() => store.getters.GetReportSoarState);
    const ReportSoarData = computed<LINE_CHART_SERIES_FROM[]>(() => store.getters.GetReportSoarData);
    return {
      showLogData,
      log_list,
      log_file,
      log_progress,
      log_file_info,
      log_save_done_list,
      h2_log_file_list,
      ReportSoarState, 
      ReportSoarData,
      fc_log_data_list
    };
  },
  components: {apexchart:VueApexCharts},
  watch: {
    group() {
      this.showLogData = false;
    },
    group_log_data: {
      handler(n:Group_Log, o) {
        console.log('Result',)
        // this.log_group_box_state = 'list';
        this.sort_group = ['All']
        this.selected_log_type = 'All'
        if(n.fc || n.hydrogen || n.video) {
          this.log_group_box_state = 'list';
          if(n.fc) this.sort_group.push('FC Data')
          if(n.hydrogen) this.sort_group.push('Hydrogen Data')
          if(n.video) this.sort_group.push('Video Data')
        } else {
          this.log_group_box_state = 'none'
        }
        const chart:any = this.$refs.fc_log_data;
        if(chart) chart.updateSeries(n.fc, true)
        if(n.fc) this.fc_data_series = n.fc;
      },
    },
    start_date: {
      handler(n, o) {
        this.end_date = n
      },
    },
    start_time: {
      handler(n, o) {
        this.end_time = `${Number(n.split(':')[0])+1}:${n.split(':')[1]}`
      },
    },
    end_date: {
      handler(n, o) {
        this.start_date = n
      },
    },
    end_time: {
      handler(n, o) {
        this.start_time = `${Number(n.split(':')[0])-1}:${n.split(':')[1]}`
      },
    },
    fc_log_data_list: {
      handler(n, o) {
        this.log_group_lists = n;
        this.log_group_box_state = 'list';
        console.log('LOGDATA', n)
      },
    },
    h2_log_file_list: {
      handler(n, o) {
        this.all_log_list = n;
      },
    },
    ReportSoarState: {
      handler(val, oldVal) {
        const chart:any = this.$refs.chart_soar_state;
        if(chart) chart.updateSeries([{data:val}], true)
        this.soar_state_series = [{data:val}];
      },
    },
    ReportSoarData: {
      handler(val, oldVal) {
        const chart:any = this.$refs.chart_soar_data;
        if(chart) chart.updateSeries(val, true)
        this.soar_data_series = val;
      },
    },
    all_log_lists: {
      handler(n, o) {
        console.log('sEs')
      },
    },
    showLogData: {
      handler(n, o) {
        if(n) { RequestStackLogFileList() }
        // 
      },
    },
    
  },
  filters: {
    LogSizeConverter: function (file_size_byte: any) {
      const MbSize = (file_size_byte / 1024 ** 2).toFixed(0);
      const KbSize = file_size_byte / 1024;
      if (Number(MbSize) > 0) return MbSize + "MB";
      else return KbSize.toFixed(0) + "KB";
    },
  },
  computed: {
    sorted_log_list() {
      let sorted: any = this.log_list.slice();
      sorted.sort((a: any, b: any) => {
        let modifier = 1;
        if (this.sortOrder === "desc") modifier = -1;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });

      return sorted;
    },
  },  
  methods: {
    onClickChangeLogType(type:number) {
      this.log_type = type
      console.log('CG', this.fc_data_series)
    },
    onClickSendH2List(list:ST_LOG_FILE_INFO) {
      this.selected_top_log_info = list;
      const send_file_name_list:HG_Hydrogen_Log_Info2[] = [];
      this.log_group_box_state='chart';
      send_file_name_list.push({
          _filename: list.file_name,
        })
      RequestStackLogFileData(send_file_name_list)
    },
    async onClickAllLogDataLoad() {
      //국제 표준 기준인 ISO로 보내줘야함
      
      const split_start_date = new Date(this.start_date)
      const split_end_date = new Date(this.end_date)
      const split_start_time = this.start_time.split(":")
      const split_end_time = this.end_time.split(":")

      const start_date = `${split_start_date.getFullYear()}-${(split_start_date.getMonth()+1).toString().padStart(2, '0')}-${split_start_date.getDate().toString().padStart(2, '0')} ${split_start_time[0]}:${split_start_time[1]}:00`
      const end_date = `${split_end_date.getFullYear()}-${(split_end_date.getMonth()+1).toString().padStart(2, '0')}-${split_end_date.getDate().toString().padStart(2, '0')} ${split_end_time[0]}:${split_end_time[1]}:00`

      const time_gap = Math.abs(new Date(end_date).getTime() - new Date(start_date).getTime()) // 밀리초단위
      const maximum_time = 60  //최대 시간 설정 기간 1시간
      const convert_minute = 60*1000  //밀리초 단위 - 분단위로 변경
      // console.log('DATE 연산', Math.abs(split_start_date - split_end_date))
      if(this.input_text != '') {
        if(time_gap/convert_minute <= maximum_time && time_gap/convert_minute >0) {
          this.log_group_box_state = 'load';
          const LogData = LogDataManager.getInstance().AsyncLogDataGroup(this.input_text, new Date(start_date).toISOString(), new Date(end_date).toISOString())
          await LogData.then((data) => {
            this.group_log_data = data
          })
        } else {
          CustomAlert.getInstance().ShowAlert('Please set the time interval to a maximum of 1 hour.')
        }
      } else {
        CustomAlert.getInstance().ShowAlert('Please enter the drone name')
      }
    },

    CloseBtn() {
      store.commit("SetFcLogData", false);
    },
    LogDateConverter: function (timestamp_sec: number) {
      const js_date = new Date(timestamp_sec * 1000);
      const year = js_date.getFullYear();
      const month =
        js_date.getMonth() + 1 < 10
          ? `0${js_date.getMonth() + 1}`
          : js_date.getMonth() + 1;
      const day =
        js_date.getDate() < 10 ? `0${js_date.getMonth()}` : js_date.getDate();
      const Hour =
        js_date.getHours() < 10 ? `0${js_date.getHours()}` : js_date.getHours();
      const Minutes =
        js_date.getMinutes() < 10
          ? `0${js_date.getMinutes()}`
          : js_date.getMinutes();
      const Seconds =
        js_date.getSeconds() < 10
          ? `0${js_date.getSeconds()}`
          : js_date.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        Hour +
        ":" +
        Minutes +
        ":" +
        Seconds
      );
    },
    onClickCSVDownload(fc_data:FC_CSV_FILE[]) {
      LogDataManager.getInstance().DownloadCSV(fc_data)
    },
    DateFormat_full(dt:Date) {
      const yyyy = dt.getFullYear().toString().padStart(4, '0');
      const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
      const dd = dt.getDate().toString().padStart(2, '0');
      const hh = dt.getHours().toString().padStart(2, '0');
      const mm = dt.getMinutes().toString().padStart(2, '0');
      const ss = dt.getSeconds().toString().padStart(2, '0');
      return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
    },
    onClickVideoPlay(list:Video_Log) {
      this.selected_top_log_info = list;
      this.log_group_box_state = 'video'
      this.selected_video = list
    },
    onClickLogVideoBack() {
      this.log_group_box_state = 'list'
    },
    onClickChartState(state:string) {
      this.selectedChartState = state
    },
    onClickSortLogType(type:string) {
      type == 'video' ? this.video_log_state = !this.video_log_state : null
    },
    onClickSelectLogType(name:string) {
      this.selected_log_type = name
    },
    onClickDateSort() {
      this.date_sort == 'up' ? this.date_sort = 'down' : this.date_sort = 'up'
    },
    TimeSort(sort_type:string) {
      sort_type == 'up' ? this.date_sort = 'down' : this.date_sort = 'up'
      if(this.group_log_data.video) {
        if(sort_type == 'up') {
          let a_convert:any = ''
          let b_convert:any = ''
          this.group_log_data.video.sort(function(a, b) {
            a_convert = new Date(a.birth_time)
            b_convert = new Date(b.birth_time)
            const a_yyyy = a_convert.getFullYear().toString().padStart(4, '0');
            const a_MM = (a_convert.getMonth() + 1).toString().padStart(2, '0');
            const a_dd = a_convert.getDate().toString().padStart(2, '0');
            const a_hh = a_convert.getHours().toString().padStart(2, '0');
            const a_mm = a_convert.getMinutes().toString().padStart(2, '0');
            const a_ss = a_convert.getSeconds().toString().padStart(2, '0');
  
            const b_yyyy = b_convert.getFullYear().toString().padStart(4, '0');
            const b_MM = (b_convert.getMonth() + 1).toString().padStart(2, '0');
            const b_dd = b_convert.getDate().toString().padStart(2, '0');
            const b_hh = b_convert.getHours().toString().padStart(2, '0');
            const b_mm = b_convert.getMinutes().toString().padStart(2, '0');
            const b_ss = b_convert.getSeconds().toString().padStart(2, '0');
              return Number(new Date(`${a_yyyy}-${a_MM}-${a_dd} ${a_hh}:${a_mm}:${a_ss}`)) - Number(new Date(`${b_yyyy}-${b_MM}-${b_dd} ${b_hh}:${b_mm}:${b_ss}`));
          });
          this.date_sort = 'down'
        } else {
          this.group_log_data.video.reverse();
          this.date_sort = 'up'
        }
      }
      if(this.group_log_data.hydrogen) {
        if(sort_type == 'up') {
          let a_convert:any = ''
          let b_convert:any = ''
          this.group_log_data.hydrogen.sort(function(a, b) {
            a_convert = new Date(a.connect_datetime)
            b_convert = new Date(b.connect_datetime)
            const a_yyyy = a_convert.getFullYear().toString().padStart(4, '0');
            const a_MM = (a_convert.getMonth() + 1).toString().padStart(2, '0');
            const a_dd = a_convert.getDate().toString().padStart(2, '0');
            const a_hh = a_convert.getHours().toString().padStart(2, '0');
            const a_mm = a_convert.getMinutes().toString().padStart(2, '0');
            const a_ss = a_convert.getSeconds().toString().padStart(2, '0');
  
            const b_yyyy = b_convert.getFullYear().toString().padStart(4, '0');
            const b_MM = (b_convert.getMonth() + 1).toString().padStart(2, '0');
            const b_dd = b_convert.getDate().toString().padStart(2, '0');
            const b_hh = b_convert.getHours().toString().padStart(2, '0');
            const b_mm = b_convert.getMinutes().toString().padStart(2, '0');
            const b_ss = b_convert.getSeconds().toString().padStart(2, '0');
              return Number(new Date(`${a_yyyy}-${a_MM}-${a_dd} ${a_hh}:${a_mm}:${a_ss}`)) - Number(new Date(`${b_yyyy}-${b_MM}-${b_dd} ${b_hh}:${b_mm}:${b_ss}`));
          });
          this.date_sort = 'down'
        } else {
          this.group_log_data.hydrogen.reverse();
          this.date_sort = 'up'
        }
      }
      
    },
  },
});
