import { E_AI_TYPE } from "@/services/ws/ws_hg_server_protocol";
import store from "@/store";

export interface HG_Ai_Image_Data {
    _sequence: number,
    _max_chunk: number,
    _chunk_num: number,
    _image: string,
}

export interface HG_Ai_Meta_Data {
    _info: any,
    _owner: HG_Ai_Owner_Data,
    _pos: HG_Ai_Meta_Pos,
}

export interface HG_Ai_Meta_Pos {
    _abs_alt: number,
    _lat: number,
    _lon: number,
    _rel_alt: number,
}

export interface HG_Ai_Owner_Data {
    _device:string,
    _group:string,
    _index:number,
    _type:number,
}

export interface HG_Ai_Data {
    _image : string,
    _latitude: number,
    _longitude: number,
    _abs_alt: number,
    _rel_alt: number,
    _sequence: number,
    _type: E_AI_TYPE,
}

class ExtensionAiManager {
    private static instance: ExtensionAiManager;

    private ai_people_counting_image:string[] = [];
    private ai_vehicle_image:string[] = [];
    private ai_fence_detect_image:string[] = [];

    private ai_people_counting_sequence:number|undefined;
    private ai_vehicle_sequence:number|undefined;
    private ai_fence_detect_sequence:number|undefined;
    
    private ai_data:HG_Ai_Data|undefined;

    private ai_people_counting_arr:HG_Ai_Data[] = [];
    private ai_vehicle_arr:HG_Ai_Data[] = [];
    private ai_fence_detect_arr:HG_Ai_Data[] = [];

    private ai_people_counting_meta:HG_Ai_Meta_Data|undefined;
    private ai_vehicle_meta:HG_Ai_Meta_Data|undefined;
    private ai_fence_detect_meta:HG_Ai_Meta_Data|undefined;

    public static getInstance() {
        return this.instance || (this.instance = new this());
    }

    constructor() {
        // 
    }

    SetAiImage(image:HG_Ai_Image_Data, owner:HG_Ai_Owner_Data) {
        if(owner._type == E_AI_TYPE.PEOPLE_COUNTING ) {
            this.ai_people_counting_image.push(image._image),
            this.ai_people_counting_sequence = image._sequence
        }
        if(owner._type == E_AI_TYPE.VEHICLE_TYPE ) {
            this.ai_vehicle_image.push(image._image)
            this.ai_vehicle_sequence = image._sequence
        }
        
        if(owner._type == E_AI_TYPE.FENCE_DETECT ) {
            this.ai_fence_detect_image.push(image._image)
            this.ai_fence_detect_sequence = image._sequence
        }
    }

    SetAiMeta(info:any, owner:HG_Ai_Owner_Data, pos:HG_Ai_Meta_Pos) {
        const meta_data:HG_Ai_Meta_Data = {
            _info:info,
            _owner:owner,
            _pos:pos,
        }
        if(owner._type == E_AI_TYPE.PEOPLE_COUNTING) {
            this.ai_people_counting_meta = meta_data
        }

        if(owner._type == E_AI_TYPE.VEHICLE_TYPE) {
            this.ai_vehicle_meta = meta_data
        }

        if(owner._type == E_AI_TYPE.FENCE_DETECT) {
            this.ai_fence_detect_meta = meta_data
        }
    }

    SetAiDataArr(type:E_AI_TYPE) {
        if(type == E_AI_TYPE.PEOPLE_COUNTING) {
            if(this.ai_people_counting_meta) {
                const Sequence = this.ai_people_counting_sequence == this.ai_people_counting_meta._info._sequence ? this.ai_people_counting_meta._info._sequence : null
                const arr:HG_Ai_Data={
                    _image : this.ai_people_counting_image.join(''),
                    _latitude: this.ai_people_counting_meta?._pos._lat,
                    _longitude: this.ai_people_counting_meta?._pos._lon,
                    _abs_alt: this.ai_people_counting_meta?._pos._abs_alt,
                    _rel_alt: this.ai_people_counting_meta?._pos._rel_alt,
                    _type: this.ai_people_counting_meta?._owner._type,
                    _sequence: Sequence,
                }
                if(this.ai_people_counting_arr.length >= 50) {
                    this.ai_people_counting_arr.splice(this.ai_people_counting_arr.length-1)
                }
                this.ai_people_counting_arr.unshift(arr)
                store.commit('SetAiImagePeopleCounting', this.ai_people_counting_arr)
                this.ai_people_counting_image = []
            }
        }
        if(type == E_AI_TYPE.VEHICLE_TYPE) {
            if(this.ai_vehicle_meta) {
                console.log('Ve', this.ai_vehicle_sequence , this.ai_vehicle_meta._info._sequence)
                const Sequence = this.ai_vehicle_sequence == this.ai_vehicle_meta._info._sequence ? this.ai_vehicle_meta._info._sequence : null
                const arr:HG_Ai_Data={
                    _image : this.ai_vehicle_image.join(''),
                    _latitude: this.ai_vehicle_meta?._pos._lat,
                    _longitude: this.ai_vehicle_meta?._pos._lon,
                    _abs_alt: this.ai_vehicle_meta?._pos._abs_alt,
                    _rel_alt: this.ai_vehicle_meta?._pos._rel_alt,
                    _type: this.ai_vehicle_meta?._owner._type,
                    _sequence: Sequence,
                }
                if(this.ai_vehicle_arr.length >= 50) {
                    this.ai_vehicle_arr.splice(this.ai_vehicle_arr.length-1)
                }
                this.ai_vehicle_arr.unshift(arr)
                store.commit('SetAiImageVehicle', this.ai_vehicle_arr)
                this.ai_vehicle_image = []
            }
        }
        if(type == E_AI_TYPE.FENCE_DETECT) {
            if(this.ai_fence_detect_meta) {
                const Sequence = this.ai_fence_detect_sequence == this.ai_fence_detect_meta._info._sequence ? this.ai_fence_detect_meta._info._sequence : null
                const arr:HG_Ai_Data={
                    _image : this.ai_fence_detect_image.join(''),
                    _latitude: this.ai_fence_detect_meta?._pos._lat,
                    _longitude: this.ai_fence_detect_meta?._pos._lon,
                    _abs_alt: this.ai_fence_detect_meta?._pos._abs_alt,
                    _rel_alt: this.ai_fence_detect_meta?._pos._rel_alt,
                    _type: this.ai_fence_detect_meta?._owner._type,
                    _sequence: Sequence,
                }
                if(this.ai_fence_detect_arr.length >= 50) {
                    this.ai_fence_detect_arr.splice(this.ai_fence_detect_arr.length-1)
                }
                this.ai_fence_detect_arr.unshift(arr)
                store.commit('SetAiImageFenceDetect', this.ai_fence_detect_arr)
                this.ai_fence_detect_image = []
            }
        }
    }
}

export default ExtensionAiManager;
