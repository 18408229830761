import { update } from "@tweenjs/tween.js";

import MissionManager from "../hg/mission/mission_manager";
import NoFlightZone from "../hg/no_fllight_zone";
import CesiumScreenSpaceCameraControllerService from "./cesium_screen_space_camera_controller";
import CesiumScreenSpaceEventService from "./cesium_screen_space_ev_service";
import LiveFlightsManager from "../third_party/live_flights/live_flights_manager";
import UserSettingManager from "../hg/user_setting/user_setting_manager";
import { USER_SETTINGS } from "../hg/user_setting/user_setting_protocol";

declare global {
    interface Window {
        vw: any;
        Cesium: any;
        CesiumMath: any;
        ws3d: any;
    }
}

window.vw = window.vw || null;
window.Cesium = window.Cesium || null;
window.CesiumMath = window.CesiumMath || null;
window.ws3d = window.ws3d || null;

export default class CesiumService {
    private static instance:CesiumService;

    public static getInstance () {
        return this.instance || (this.instance = new this())
    }

    private _cesium:any = undefined;
    private _viewer:any = undefined;

    // private _start_camera_lng = 126.8590156177093;
    // private _start_camera_lat = 35.23121445680937;
    private _start_camera_lng = 126.86212992818808;
    private _start_camera_lat = 35.21337282305974;
    private _start_camera_alt = 500;

    constructor() {
        this._cesium = window.Cesium;
        this.initClass();
    }

    isReady() {
        return this._viewer != undefined && this._cesium != undefined
    }

    initClass() {
        const map = document.getElementById('map');

        if (map) {
            if (window.vw) {
                this.vwmap();
            }
            else {
                this.cmap();
            }
        }
        else {
            setTimeout(() => {
                this.initClass();
            }, 500)
        }
    }

    GetCesium() {
        return this._cesium;
    }

    GetViewer() {
        return this._viewer;
    }

    private setViewer(viewer: any) {
        this._viewer = viewer;
    }

    cmap() {
        const cesium_viewer = new window.Cesium.Viewer('map', {
            scene3DOnly: true,
            sceneMode : window.Cesium.SceneMode.SCENE3D,
            terrainProvider: window.Cesium.createWorldTerrain(),

            animation: false,
            baseLayerPicker: false,
            fullscreenButton: false,
            vrButton: false,
            geocoder: false,
            homeButton: false,
            infoBox: false,
            sceneModePicker: false,
            selectionIndicator: false,
            timeline: false,
            navigationHelpButton: false,
            navigationInstructionsInitiallyVisible: false,
            cesiumWidget: false,
        });

        this.setViewer(cesium_viewer);

        cesium_viewer.scene.primitives.add(new window.Cesium.createOsmBuildings())
        cesium_viewer.camera.setView({
            // destination : window.Cesium.Cartesian3.fromDegrees(this._start_camera_lng, this._start_camera_lat, this._start_camera_alt)
            destination : window.Cesium.Cartesian3.fromRadians(2.2181234777505563, 0.6544637930422742, 1027.0276333294203),
            orientation : {
                heading : 0.27701425422436454,
                pitch : -0.4673194875024165,
                roll : 0.0
            }
        });

        const func_init = this.init;

        func_init(window.Cesium, cesium_viewer, cesium_viewer.scene);
    }

    vwmap() {
        const user_setting:USER_SETTINGS = UserSettingManager.getInstance().Get();
        const mapOptions = new window.vw.MapOptions(
            window.vw.BasemapType.GRAPHIC,
            "",
            window.vw.DensityType.FULL,
            window.vw.DensityType.FULL,
            false,
            new window.vw.CameraPosition(
                new window.vw.CoordZ(0,0,0),
                new window.vw.Direction(0,0,0)
            ),
            new window.vw.CameraPosition(
                new window.vw.CoordZ(user_setting.home_position.x, user_setting.home_position.y, user_setting.home_position.z),
                new window.vw.Direction(
                    window.Cesium.Math.toDegrees(user_setting.home_position.h), 
                    window.Cesium.Math.toDegrees(user_setting.home_position.p), 
                    user_setting.home_position.r)
            )
        );

        const vmap = new window.vw.Map("map", mapOptions);

        this.setViewer(vmap._wsViewer);

        // const display_none_vworld_elemenets: string[] = ['naviRotate', 'naviZoomPannel', 'coordinatesContainer2', 'coordinatesContainer'];
        const display_none_vworld_elemenets: string[] = ['naviRotate', 'naviZoomPannel', 'coordinatesContainer2'];
        // const display_none_vworld_elemenets: string[] = ['naviRotate', 'naviZoomPannel'];
        display_none_vworld_elemenets.forEach(display_none_vworld_elemenet => {
            const element = document.getElementById(display_none_vworld_elemenet)
            if (element) {
                element.style.display = "none";
            }
        });

        // vmap.setLogoVisible(false);

        const func_init = this.init;

        window.vw.ws3dInitCallBack = function () {
            const Cesium = window.Cesium;
            const viewer = window.ws3d.viewer;
            const scene = window.ws3d.viewer.scene;

            func_init(Cesium, viewer, scene)
        }
    }

    init(Cesium:any, viewer:any, scene:any) {
        console.log('Cesium Viewer init');

        // const promise = new Cesium.GeoJsonDataSource.load('Res/area4326_copy.json');
        // promise.then(function (dataSource:any) {
        //     viewer.dataSources.add(dataSource);
        //     const entities = dataSource.entities.values;
        //     for (let i = 0; i < entities.length; i++) {
        //         const entity = entities[i];

        //         const color = Cesium.Color.fromHsl(0.6 - entity._properties.A16.getValue() * 0.5, 1.0, 0.5);
        //         // entity.fill = new Cesium.ColorMaterialProperty(color);
        //         // entity.stroke = Cesium.Color.HOTPINK;
        //         entity.polygon.outline = false;
        //         // entity.polygon.material = new Cesium.ColorMaterialProperty(color);
        //         entity.polygon.material = Cesium.Color.ROYALBLUE;
        //         entity.polygon.extrudedHeight = entity._properties.A16.getValue() ? entity._properties.A16.getValue() * 1 : 3;
        //     }
        // })


        // viewer.scene.debugShowFramesPerSecond = true;

        // entity or primitive object always on terrain
        viewer.scene.globe.depthTestAgainstTerrain = true;

        // viewer.targetFrameRate = 120;
        // viewer.useDefaultRenderLoop = false;

        // 초기 맵 각도 및 위치 설정 코드
        const user_setting:USER_SETTINGS = UserSettingManager.getInstance().Get();
        viewer.camera.setView({
            // 맵 위치 설정
            destination : window.Cesium.Cartesian3.fromDegrees(user_setting.home_position.x, user_setting.home_position.y, user_setting.home_position.z),

            // 맵 각도 설정
            orientation : {
                heading : user_setting.home_position.h,
                pitch : user_setting.home_position.p,
                roll : user_setting.home_position.r,
            }
        });
        // viewer.camera.setView({
        //     // destination : window.Cesium.Cartesian3.fromDegrees(this._start_camera_lng, this._start_camera_lat, this._start_camera_alt)

        //     // 맵 위치 설정
        //     destination : window.Cesium.Cartesian3.fromDegrees(127.08911371398723, 37.498013185445686, 1027.0276333294203),
        //     // destination : window.Cesium.Cartesian3.fromRadians(2.2181234777505563, 0.6544637930422742, 1027.0276333294203),

        //     // 맵 각도 설정
        //     orientation : {
        //         heading : 0.27701425422436454,
        //         pitch : -0.4673194875024165,
        //         roll : 0.0
        //     }
        // });

        LiveFlightsManager.getInstance();

        CesiumScreenSpaceEventService.getInstance();
        CesiumScreenSpaceCameraControllerService.getInstance();

        MissionManager.getInstance();

        NoFlightZone.getInstance();


        // const layers = [{layer : 'Base', tileType : 'png'}, 
        // {layer : 'gray', tileType : 'png'},
        // {layer : 'midnight', tileType : 'png'},
        // {layer : 'Hybrid', tileType : 'png'},
        // {layer : 'Satellite', tileType : 'jpeg'} ]

        // const selLayer = layers[4];

        // const vworld_key = '10202587-27DB-3B4B-B845-A86100E998A1';

        // const wmts = new Cesium.WebMapTileServiceImageryProvider({
        //     url : `http://api.vworld.kr/req/wmts/1.0.0/${vworld_key}/${selLayer.layer}/{TileMatrix}/{TileRow}/{TileCol}.${selLayer.tileType}`,
        //     // url : `http://api.vworld.kr/req/wmts/1.0.0/10202587-27DB-3B4B-B845-A86100E998A1/Satellite/{TileMatrix}/{TileRow}/{TileCol}.jpeg`,
        //     // url : 'http://api.vworld.kr/req/wmts/1.0.0/10202587-27DB-3B4B-B845-A86100E998A1/Satellite/{z}/{y}/{x}.jpeg',
        //     layer : 'Satellite',
        //     style : 'default',
        //     maximumLevel: 19,
        //     credit : new Cesium.Credit('VWorld Korea')
        // });
        // viewer.imageryLayers.addImageryProvider(wmts);

        // const tween_fps = 20;
        // let tween_update = false;
        // setInterval(() => {
        //     tween_update = true;
        // }, 1000 / tween_fps)

        // scene.preUpdate.addEventListener(() => {
        //     if(tween_update) 
        //     {
        //         update(); // Tween js update !!
        //         tween_update = false;
        //     }
        // })

        scene.preUpdate.addEventListener(() => {
            update(); // Tween js update !!
        })

        // scene.preUpdate.addEventListener(() => {
        //     console.time('SceneUpdate')
        // })

        // scene.postUpdate.addEventListener(() => {
        //     console.timeEnd('SceneUpdate')
        // })

        // scene.preRender.addEventListener(() => {
        //     console.time('SceneRender')
        // })

        // scene.postRender.addEventListener(() => {
        //     console.timeEnd('SceneRender')
        // })
    }
}