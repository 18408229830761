
import { WEBRTC, ChannelInfo } from '@/services/hg/drone_tv/webrtc';
import { ptz_home, ptz_move, ptz_stop } from "@/services/hg/drone_tv/gimbal_control";
import { computed, defineComponent } from "vue";
import store from '@/store';
import { event } from 'jquery';

export default defineComponent({
  name: 'DroneTVComponent',
  props: {
    video_tag_key: {
      type: String,
      required: true
    },
    expand_width: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      dt: new WEBRTC(),
      screen_size: 1,
      selected_channel: {} as ChannelInfo,
      channel_box_state: false,
      channel_list: [] as ChannelInfo[],
      description: undefined as undefined | string,
      is_show_ptz: false,
      target_index: 0,
      is_press: false,
      is_continuous: false,
      ptz_command: '',
      continuous_active_time_ms: 180,
      to: undefined as any,
    };
  },

  setup() {
    const channels = computed(() => store.getters.GetDroneTvChannel)
    return {channels}
  },

  mounted() {
    const video_element = document.getElementById(this.video_tag_key);
    if (video_element) {
      this.dt.SetVideoElement(video_element);
    }

    // this.onClickchannellist()
  },

  beforeDestroy() {
    this.dt.UserStop();
  },

  methods: {
    onClickRemove(info:any) {
      if(info.target._prevClass == 'v-btn__content' || info.target._prevClass == 'screen-icon') {
        return
      } else {
        this.channel_box_state = false
      }
    },
    home() {
      ptz_home(this.target_index);
    },
    mouse_down(dir: string) {
      this.ptz_command = dir;
      this.is_press = true;
      this.to = setTimeout(() => {
        if (this.is_press) {
          this.is_continuous = true;
          console.log('continuous move', this.ptz_command, this.target_index)
          ptz_move(this.ptz_command, this.target_index, true);
        }
      }, this.continuous_active_time_ms)
    },
    mouse_up() {
      clearTimeout(this.to);
      this.is_press = false;

      if (this.is_continuous) {
        this.is_continuous = false;
        ptz_stop(this.target_index);
      }
      else {
        console.log('move', this.ptz_command, this.target_index)
        ptz_move(this.ptz_command, this.target_index);
      }
    },
    playUrl() {
      const url = prompt('Input rtsp url', 'rtsp://125.136.130.45:40000/ds-test')
      if (url) {
        this.description = url;
        this.dt.PlayFromUrl(url);
        this.is_show_ptz = false;
      }
    },
    full_screen(e: any) {
      const fullscreenTarget = document.getElementById(this.video_tag_key);

      if (fullscreenTarget) {
        if (fullscreenTarget.requestFullscreen) {
          fullscreenTarget.requestFullscreen();
        }
      }
    },
    onClickchannellist() {
      this.channel_box_state = !this.channel_box_state
      // this.channels.forEach((channel:ChannelInfo) => {
      //   console.log(channel)
      // })
      this.channel_list = this.channels;
    },
    onClickClose() {
      store.commit('SetShowDroneTV', false)
    },
    change_channel(channel: ChannelInfo) {
      if (channel) {
        this.description = `[${channel.index}]${channel.psn}: ${channel.des}`;
        this.dt.PlayFromUrl(channel.rtspurl);
        this.is_show_ptz = true;
        this.target_index = channel.index;
        this.selected_channel = channel
      } else {
        this.description = undefined;
        this.dt.UserStop();
        this.is_show_ptz = false;
      }
      this.channel_box_state = false
    },
    ScreenSize(size:number) {
      this.screen_size = size
    }
  },
});
