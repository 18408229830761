
import Function from "@/components/extension/Function.vue";
import ScreenManager from "@/services/hg/screen_manager";
import store from "@/store";
import { computed, defineComponent } from "vue";
import VueDraggableResizable from "vue-draggable-resizable";

export default defineComponent({
  data() {
    return {
      resize_w:300,
      resize_h:280,
      min_w:300,
      min_h:280,
      max_w:600,
      max_h:560,
      flex_direction:'row',
      box_size_w:'100%',
      box_size_h:'100%',
      box_count: 1 as number,
      box_count_list_state: false,
      box_count_list: [
        {
          name: '1 * 1',
          count: 1
        },
        {
          name: '2 * 1',
          count: 2
        },
        {
          name: '2 * 2',
          count: 4
        },
      ],
    };
  },

  setup() {
    const extension_function_state = computed<boolean>(() => store.getters.GetExtensionFunctionState);

    return { extension_function_state};
  },

  components: { Function, VueDraggableResizable },

  methods: {
    onClickClose() {
      store.commit('SetExtensionFunctionState', false)
    },
    onClickBoxCountListState() {
      this.box_count_list_state = !this.box_count_list_state
    },
    ChangeBoxCount(list:any) {
      this.box_count = list.count
      this.box_count_list_state = false
      if(list.count == 1) {
        this.flex_direction = 'row'
        this.resize_w = 300
        this.resize_h = 280
        this.max_w = 600
        this.max_h = 560
        this.min_w = 300
        this.min_h = 280
        this.box_size_w = '100%'
        this.box_size_h = '100%'
      } else if (list.count == 2) {
        this.flex_direction = 'column'
        this.resize_w = 300
        this.resize_h = 560
        this.max_w = 600
        this.max_h = 1120
        this.min_w = 300
        this.min_h = 560
        this.box_size_w = '100%'
        this.box_size_h = '50%'
      } else if (list.count == 4) {
        this.flex_direction = 'row'
        this.resize_w = 600
        this.resize_h = 560
        this.max_w = 900
        this.max_h = 840
        this.min_w = 600
        this.min_h = 560
        this.box_size_w = '50%'
        this.box_size_h = '50%'
      }
    },
    onResize: function (x:number, y:number, width:number, height:number) {
      this.resize_h = height;
      this.resize_w = width;
      if(this.box_count == 4) {
        if(width <= 600) {
          this.resize_w = 600
        } else {
          this.resize_w = width;
        }
        if(height <= 560) {
          this.resize_h = 560
        } else {
          this.resize_w = width;
        }
      } 
    },
    zindex(id:string) {
      ScreenManager.getInstance().SetZ_Index(id)
    }
  },
});
