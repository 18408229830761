import store from "@/store";

import router from "@/router";
import CesiumScreenSpaceEventService from "@/services/cesium/cesium_screen_space_ev_service";
import axios from "axios";
import MissionManager from "../mission/mission_manager";
import { USER_SETTINGS } from "./user_setting_protocol";

export default class UserSettingManager {
    private static instance:UserSettingManager;

    private _settings:USER_SETTINGS = {
        account: store.getters.GetLoginData.id,
        home_position: {
            h: 0.27701425422436365,
            p: -0.46731948750241603,
            r: 6.283185307179586,
            x: 127.08911371398723,
            y: 37.498013185445686,
            z: 1027.0276333294203,
            // x: -3055900.3182343147,
            // y: 4042220.654307164,
            // z: 3862014.340198126,
        },
        // 127.08911371398723, 37.498013185445686, 1027.0276333294203
        takeoff_initial: 5,
        takeoff_confirm: true,
        color_waypoint: '#00B1FE',
        color_mission: '#00B1FE',
        color_fly_to_target: '#00B1FE',
        color_drone_altitude: '#00B1FE',

        yaw_speed: 200,
    };

    public static getInstance () {
        return this.instance || (this.instance = new this())
    }

    private constructor() {
        this.refresh(true);
    }

    UpsertUserSetting(user_setting:USER_SETTINGS) {
        axios.put('/gcs/usersetting', user_setting).then((res) => {
            if(res.data.state) {
                this.refresh(false);
            }
        })
    }

    SetDefault() {
        const user_setting = this._settings;
        axios.delete('/gcs/usersetting', {data: user_setting}).then((res) => {
            if(res.data.state) {
                this._settings = {
                    account: store.getters.GetLoginData.id,
                    home_position: {
                        h: 0.27701425422436365,
                        p: -0.46731948750241603,
                        r: 6.283185307179586,
                        x: 127.08911371398723,
                        y: 37.498013185445686,
                        z: 1027.0276333294203,
                    },
                    takeoff_initial: 5,
                    takeoff_confirm: true,
                    color_waypoint: '#00B1FE',
                    color_mission: '#00B1FE',
                    color_fly_to_target: '#00B1FE',
                    color_drone_altitude: '#00B1FE',

                    yaw_speed: 200,
                };

                this.refresh(false);
            }
        })
    }

    private refresh(init:boolean) {
        axios.get(`/gcs/usersetting/${store.getters.GetLoginData.id}`)
        .then((res) => {
            if(res.status == axios.HttpStatusCode.Ok) {
                res.data.data.forEach((user_setting: USER_SETTINGS) => {
                    this._settings = user_setting;
                });

                this.set(this._settings);
            }
            else {
                console.error(res);
            }
        })
        .finally(() => {
            if(init) {
                router.push('/g');
            }
            else {
                CesiumScreenSpaceEventService.getInstance().SetFlyToTargetLineColor();
                CesiumScreenSpaceEventService.getInstance().SetWaypointLineColor();
                MissionManager.getInstance().SetMissionLineColor();
            }
            
        }) 
    }

    Get(): USER_SETTINGS {
        return this._settings;
    }

    private set(settings:USER_SETTINGS) {
        this._settings = settings;
    }
}