import store from '@/store'
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import MapView from '../views/MapView.vue'

Vue.use(VueRouter)

const authGuard = (to:Route, from:Route, next:NavigationGuardNext) => {
  if (store.getters.GetAuthState) {
    return next()
  }
  else {
    return next('/')
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: LoginView,
  },
  {
    path: '/g',
    name: 'g',
    component: MapView,
    beforeEnter: authGuard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
