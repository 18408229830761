
import CesiumScreenSpaceEventService, { GCS_FEATURE, GCS_MODE, MISSION_MODE_STATE } from "@/services/cesium/cesium_screen_space_ev_service";
import MissionWaypointGeneratingManager from "@/services/hg/mission/mission_generating_tool_manager";
import MissionManager, { MissionItem, MissionItemType } from "@/services/hg/mission/mission_manager";
import store from "@/store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      mission_arr: [] as MissionItem[],
      tab_open: false,
      modify_name: '',
      modify_state: false,
      panel: [],
    };
  },
  setup() {
    
    const side_mission_Detail_tab = computed(
      () => store.getters.GetSideMissionDetailTab
    );
    const side_mission_tab = computed(() => store.getters.GetSideMissionTab);
    const mission = computed(() => MissionManager.getInstance().GetModifyMission());
    const mission_alias = computed(() => MissionManager.getInstance().GetMissionAlias());
    const edit_mission_name = computed(() => store.getters.GetEditMissionName);
    const waypoint_index_list = MissionManager.getInstance().GetSelectWaypointList()
    return { side_mission_Detail_tab, mission, side_mission_tab, edit_mission_name, waypoint_index_list, mission_alias };
  },

  watch: {
    mission: {
      handler(n, o) {
        this.mission_arr = n
      },
    },
    side_mission_Detail_tab: {
      handler(n, o) {
        this.tab_open = n;
        this.modify_state = false
      },
    },
    waypoint_index_list: {
      handler(n, o) {
        this.panel = n
        const scroll_y:any = this.$refs.scroll_container
        scroll_y.scrollTop = n[0]*60
      }
    }
  },

  methods: {    
    // onClickModifyMission() {
    //   MissionManager.getInstance().SetGCSMode(GCS_MODE.MISSION_UPDATE)
    //   MissionManager.getInstance().SetSelectedMissionEntity(this.mission.alias);
    //   CesiumScreenSpaceEventService.getInstance().setMissionMode(MissionItemType.NONE)
    //   this.modify_state = true;
    //   store.commit("SetFacialRecognition", false);
    //   store.commit("SetSideMenuTab", false);
    //   store.commit("SetShowSingle", false);
    //   store.commit("SetShowCommandSingle", true);
    //   store.commit("SetLogMessageState", false)
    //   CesiumScreenSpaceEventService.getInstance().SetMissionCameraMove(this.mission)
    // },
    // onClickModifyMissionSave() {
    //   const mission_modify_arr :MissionItem[] = MissionManager.getInstance().GetModifyMission()
    //   store.commit("SetMissionPacket", { 
    //     _id: this.mission._id, 
    //     alias: this.edit_mission_name === '' ? this.mission.alias : this.edit_mission_name, 
    //     items: mission_modify_arr
    //   })
    //   store.commit("SetModalType", 'modify_mission_save')
    //   store.commit("SetModalWindow", true)
    // },
    onClickModifyMissionSave() {
      // const mission_modify_arr :MissionItem[] = MissionManager.getInstance().GetModifyMission()
      // MissionManager.getInstance().SetMissionPacket(mission_modify_arr)
      store.commit("SetModalType", 'modify_mission_save')
      store.commit("SetModalWindow", true)
    },
    onClickEditMissionName() {
      store.commit("SetModalType", 'modify_mission_name')
      store.commit("SetModalWindow", true)
    },
    altSelect(num: number, index: number) {
      // num === 1
      //   ? (this.mission.waypoint[0][index][0].selectAlt = "alt")
      //   : num === 2
      //     ? (this.mission.waypoint[0][index][0].selectAlt = "absolalt")
      //     : null;
    },
    setNumber(num: number, index: number, pm: string) {
      // const set =
      //   num === 1 ? "alt" : num === 2 ? "absolalt" : num === 3 ? "wait" : null;
      // const setNum = this.mission.waypoint[0][index][0][`${set}`];
      // this.mission.waypoint[0][index][0][`${set}`] =
      //   pm === "plus" ? setNum + 1 : setNum - 1;
    },
    onClickDelete(mission_item:MissionItem) {
      if(mission_item.type == MissionItemType.ARM) {
        CesiumScreenSpaceEventService.getInstance().SetDeleteMissionItem(mission_item) // ARM
        CesiumScreenSpaceEventService.getInstance().SetDeleteMissionItem(this.mission[0]) // TAKEOFF
      } else if(mission_item.type == MissionItemType.TAKEOFF) {
        CesiumScreenSpaceEventService.getInstance().SetDeleteMissionItem(mission_item) // TAKEOFF
        CesiumScreenSpaceEventService.getInstance().SetDeleteMissionItem(this.mission[0]) // ARM
      } else {
        CesiumScreenSpaceEventService.getInstance().SetDeleteMissionItem(mission_item)
      }
    },
    back() {
      store.commit("SetSideMissionTab", true);
      store.commit("SetSideMissionDetailTab", false);
      store.commit("SetMissionData", []);
      store.commit("SetEditMissionName", '');
      CesiumScreenSpaceEventService.getInstance().SetMode(GCS_FEATURE.NORMAL);
      MissionWaypointGeneratingManager.getInstance().SetOptsDefault();
      store.commit("SetMissionModeState", MISSION_MODE_STATE.OFF);
      MissionManager.getInstance().SetWaypointBoxState(false)
      MissionManager.getInstance().SetGCSMode(GCS_MODE.NORMAL)
      MissionManager.getInstance().SetMissionEntityVisible()
    },
    setUpdateAlt(num: number, mission: MissionItem, type:string) {
      //content == 1 --> Altitude
      if(type == 'Altitude'){
        CesiumScreenSpaceEventService.getInstance().SetMissionUpdateAlt(num, mission)
      //content == 2 --> Wait
      } else if(type == 'Wait') {
        CesiumScreenSpaceEventService.getInstance().SetMissionUpdateTime(num, mission)
      }
    },
    WaypointLocation(lng:number, lat:number, alt:number) {
      MissionManager.getInstance().WaypointLocation(lng, lat, alt)
    },
  },
});
