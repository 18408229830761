// singletone class
// author jhlee

import store from "@/store"
import MEDUSA_PACKET from "./medusa_packet_info"


export default class MedusaManager {

    private static instance:MedusaManager;

    public static getInstance () {
        return this.instance || (this.instance = new this())
    }

    private constructor(){
        this.init()
    }

    private worker_interval_ms = 500
    private ws:WebSocket|null = null

    private init() {
        this.connect()
    }

    private connect()
    {
        const server_url = 'wss://gcs.hogreenair.com/neton'

        this.ws = new WebSocket(server_url);
        
        this.ws.onopen = (e:any) => {
            console.log('NetOn websocket onopen : ', e)
        }

        this.ws.onmessage = (e:any) => {
            const medusa_item:MEDUSA_PACKET = JSON.parse(e.data);
            
            if(medusa_item.RegisterFlag && medusa_item.MatchRate > 0)
            {
                store.commit('SetMedusaItems', medusa_item);
            }
        }

        this.ws.onerror = (e:any) => {
            console.log('NetOn websocket onerror : ', e)
        }

        this.ws.onclose = (e:any) => {
            console.log('NetOn websocket close : ', e)

            this.ws = null
            this.connect()
        }
    }
}