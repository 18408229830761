import { HG_Hydrogen_Data, HG_Struct_Hydrogen_Info } from "@/services/ws/ws_hg_server_packet_interface";
import store from "@/store";
import STACK, { STACK_CONN_TYPE } from "./Stack";
import { STACK_FAULT } from "./StackFaults";

export interface NAV_STACK_INFO {
    stack_serial: string,
    connect_state: number,
    error_state: number,
}

class StackManager {
    private static instance: StackManager;

    private _stack_list:Map<string, STACK> = new Map();
    private _stack_info_queue:HG_Struct_Hydrogen_Info[] = [];

    private _stack_note_queue:HG_Hydrogen_Data[] = [];

    private _running_count = 0;
    private _warning_count = 0;
    private _error_count = 0;
    private _offline_count = 0;

    public static getInstance() {
        return this.instance || (this.instance = new this());
    }

    constructor() {
        this.worker();
    }

    private worker() {
        setTimeout(() => {
            store.commit('SetStacks', Array.from(this._stack_list.values()));
            // store.commit('SetStackNavList', this.getStackNavigationList());
            this.worker();
        }, 500)
    }

    private getStackNavigationList(): NAV_STACK_INFO[] {
        const nav_stack_infos:NAV_STACK_INFO[] = [];
        this._stack_list.forEach((stack:STACK) => {
            nav_stack_infos.push(
                {
                    stack_serial: stack.Get().stack_serial,
                    connect_state: stack.Get().connect_state,
                    error_state: stack.Get().error_state,
                }
            )
            
        });
        return nav_stack_infos;
    }

    GetStackFaultList(stack_serial:string):STACK_FAULT[] {
        const stack = this._stack_list.get(stack_serial)
        if(stack != undefined) {
            return stack.GetFaults();
        }
        return [];
    }

    GetStackFromStackSerial(stack_serial:string):STACK|undefined {
        return this._stack_list.get(stack_serial);
    }

    GetStackFromDeviceSerial(device_serial:string):STACK[] {
        const ret:STACK[] = [];

        this._stack_list.forEach((stack:STACK) => {
            if(stack.Get().edge_serial == device_serial) {
                ret.push(stack);
            }
        });

        return ret;
        // return this._stack_list.get(device_serial);
    }

    GetStackStateCount() {
        return {
            run: this._running_count,
            warning: this._warning_count,
            error: this._error_count,
            offline: this._offline_count,
        };
    }

    UpdateStackList(chunk:number, max_chunk:number, stack_list:HG_Struct_Hydrogen_Info[]) {
        this._stack_info_queue = this._stack_info_queue.concat(stack_list);

        if(chunk == max_chunk) {
            // console.log(this._stack_info_queue)
            let running_count = 0;
            let warning_count = 0;
            let error_count = 0;
            let offline_count = 0;

            this._stack_info_queue.forEach((stack_info:HG_Struct_Hydrogen_Info) => {
                if(!this._stack_list.has(stack_info._stack_serial)) {
                    const stack:STACK = new STACK(
                        stack_info._stack_serial,
                        stack_info._model_type,
                        stack_info._use_case,
                        stack_info._alias,
                        stack_info._group_id,
                        stack_info._group_index,
                        stack_info._detect,
                        stack_info._error,
                        stack_info._device_serial,
                        stack_info._connection_type,
                        stack_info._run_state,
                        stack_info._last_uptime,
                    )

                    this._stack_list.set(stack_info._stack_serial, stack);
                }
                else {
                    const stack:STACK|undefined = this._stack_list.get(stack_info._stack_serial);
                    if(stack != undefined) {
                        stack.Set(
                            stack_info._model_type,
                            stack_info._use_case,
                            stack_info._alias,
                            stack_info._group_id,
                            stack_info._group_index,
                            stack_info._detect,
                            stack_info._error,
                            stack_info._device_serial,
                            stack_info._connection_type,
                            stack_info._run_state,
                            stack_info._last_uptime,
                        )
                    }
                }

                const connect_state = stack_info._detect;
                const error_state = stack_info._error;
                if(connect_state) {
                    if(error_state == 0) {
                        running_count++;
                    }
                    else if(0 < error_state && error_state < 3) {
                        warning_count++;
                    }
                    else if(2 < error_state && error_state < 5) {
                        error_count++;
                    }
                }
                else {
                    offline_count++;
                }
            });

            this._running_count = running_count;
            this._warning_count = warning_count;
            this._error_count = error_count;
            this._offline_count = offline_count;

            // store.commit('SetStacks', Array.from(this._stack_list.values()));

            this._stack_info_queue = [];
        }

    }

    UpdateStackNoteList(chunk:number, max_chunk:number, stack_note_list:HG_Hydrogen_Data[]) {
        this._stack_note_queue = this._stack_note_queue.concat(stack_note_list);

        if(chunk == max_chunk) {

            this._stack_note_queue.forEach((stack_note:HG_Hydrogen_Data) => {
                if(this._stack_list.has(stack_note._serial_num)) {
                    const stack:STACK|undefined = this._stack_list.get(stack_note._serial_num);
                    if(stack != undefined) {
                        stack.SetNote(stack_note._data);
                    }
                }
            });

            this._stack_note_queue = [];
        }

    }

    UpdateStackData(serial:string, data:string) {
        if(this._stack_list.has(serial)) {
            const stack:STACK|undefined = this._stack_list.get(serial);
            if(stack != undefined) {
                if(stack.Get().connection_type == STACK_CONN_TYPE.UART) {
                    stack.SetDataFromUart(data);
                }
                else if(stack.Get().connection_type == STACK_CONN_TYPE.NETWORK) {
                    stack.SetDataFormNetwork(data);
                }
            }
        }
    }
}

export default StackManager;
