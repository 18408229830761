
import store from "@/store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
    };
  },

  setup() {
    const license_state = computed(() => store.getters.GetLicenseState);
    return { license_state };
  },
  watch: {
    group() {
      this.license_state = false;
    },
  },
  methods: {
    CloseBtn() {
      store.commit("SetLicenseState", false);
    },
  },
});
