import { StackFaults } from "./hyd_fault_file";

export function FaultParser(
  command:string,
  flag:string,
  raw:string
) {
  const parsed_raw_array:object[] = [];    

  const hexstr_to_decimal = parseInt(raw, 16); // F -> 15, hex string -> decimal value

  StackFaults.forEach((fault) => { // fault code -> hex 값이지만 콘솔은 decimal로 나옴
    if (
      fault.command == Number(command) &&
      fault.flag == flag &&
      hexstr_to_decimal & fault.code
    ) {
      parsed_raw_array.push(fault);
    }
  });

  return parsed_raw_array;
}

export function CommandParser(line:string) {
  const parsed_data_list:object[] = [];

  let command = "";
  let data = "";

  const line_split = line.split(":");

  //console.log('line -> ', line);

  if (
    line_split != undefined &&
    line_split.length == 2 &&
    line_split[0].substring(0, 1) == ">"
  ) {
    command = line_split[0].slice(-3);
    data = line_split[1];
  } else {
    console.log("Invalid Line Data -> ", line);
    return parsed_data_list;
  }

  // Old 80x Parser Logic
  if (command == "310") {
    parsed_data_list.push({ key: "Serial", value: data });
  } else if (command == "318") {
    parsed_data_list.push({ key: "SoftwareVersion", value: data });
  } else if (command == "320") {
    if (data.split(",").length == 2) {
      const run_hours = data.split(",")[0];
      const total_run_energy = data.split(",")[1];

      parsed_data_list.push({ key: "RunHours", value: run_hours });
      parsed_data_list.push({ key: "TotalRunEnergy", value: total_run_energy });
    }
  } else if (command == "338") {
    if (data.split(",").length == 4) {
      const output_power = data.split(",")[0];
      const output_voltage = data.split(",")[1];
      const output_current = data.split(",")[2];
      const anode_pressure = data.split(",")[3];

      parsed_data_list.push({ key: "OutputPower", value: output_power });
      parsed_data_list.push({ key: "OutputVoltage", value: output_voltage });
      parsed_data_list.push({ key: "OutputCurrent", value: output_current });
      parsed_data_list.push({ key: "AnodePressure", value: anode_pressure });
    }
  } else if (command == "348") {
    if (data.split(",").length == 4) {
      const outlet_temperature = data.split(",")[0];
      const inlet_temperature = data.split(",")[1];
      const dcdc_voltage_set_point = data.split(",")[2];
      const dcdc_current_limit = data.split(",")[3];

      parsed_data_list.push({
        key: "OutletTemperature",
        value: outlet_temperature,
      });
      parsed_data_list.push({
        key: "InletTemperature",
        value: inlet_temperature,
      });
      parsed_data_list.push({
        key: "DCDCVoltageSetPoint",
        value: dcdc_voltage_set_point,
      });
      parsed_data_list.push({
        key: "DCDCCurrentLimit",
        value: dcdc_current_limit,
      });
    }
  } else if (command == "358") {
    if (data.split(",").length == 2) {
      const louver_position = data.split(",")[0];
      const fan_sp_duty = data.split(",")[1];

      parsed_data_list.push({ key: "LouverPosition", value: louver_position });
      parsed_data_list.push({ key: "FanSPDuty", value: fan_sp_duty });
    }
  } else if (command == "368") {
    if (data.length == 24) {
      const state_information = data.substring(0, 8);
      const load_logic = data.substring(8, 16);
      const output_bits = data.substring(16);

      parsed_data_list.push({
        key: "StateInformation_Inactive",
        value: state_information.substring(0, 1),
      });
      parsed_data_list.push({
        key: "StateInformation_Run",
        value: state_information.substring(1, 2),
      });
      parsed_data_list.push({
        key: "StateInformation_Standby",
        value: state_information.substring(2, 3),
      });
      parsed_data_list.push({
        key: "StateInformation_Fault",
        value: state_information.substring(3, 4),
      });

      parsed_data_list.push({
        key: "LoadLogic_DCDCDisabled",
        value: load_logic.substring(0, 1),
      });
      parsed_data_list.push({
        key: "LoadLogic_OnLoad",
        value: load_logic.substring(1, 2),
      });
      parsed_data_list.push({
        key: "LoadLogic_FanPulse",
        value: load_logic.substring(2, 3),
      });
      parsed_data_list.push({
        key: "LoadLogic_Serated",
        value: load_logic.substring(3, 4),
      });

      parsed_data_list.push({
        key: "OutputBits_SV01",
        value: output_bits.substring(0, 1),
      });
      parsed_data_list.push({
        key: "OutputBits_SV02",
        value: output_bits.substring(1, 2),
      });
      parsed_data_list.push({
        key: "OutputBits_SV04",
        value: output_bits.substring(2, 3),
      });
      parsed_data_list.push({
        key: "OutputBits_LouverOpen",
        value: output_bits.substring(3, 4),
      });
      parsed_data_list.push({
        key: "OutputBits_DCDCEnable",
        value: output_bits.substring(4, 5),
      });
      parsed_data_list.push({
        key: "OutputBits_PowerFromStack",
        value: output_bits.substring(5, 6),
      });
      parsed_data_list.push({
        key: "OutputBits_PowerFromExternal",
        value: output_bits.substring(6, 7),
      });
    }
  } else if (command == "800") {
    // 2.4kw 문서에 해당 데이터 없음 (80x에 있음)
    if ((data.includes("+") || data, data.split(".").length == 3)) {
      const enable = data.substring(0, 1);
      const run_enable = data.substring(1, 2);
      const status = data.substring(2, 3);
      const regulator_pressure = data.substring(3, data.length - 1);

      parsed_data_list.push({ key: "Enable_NotUse", value: enable });
      parsed_data_list.push({ key: "RunEnable_NotUse", value: run_enable });
      parsed_data_list.push({ key: "Status_NotUse", value: status });
      parsed_data_list.push({
        key: "RegulatorPressure_NotUse",
        value: regulator_pressure,
      });
    }
  } else if (command == "400") {
    // Old 2.4kw Parser Logic
    if (data.split(",").length == 7) {
      const cyclic_counter = data.split(",")[0];
      const fcpm_state = data.split(",")[1];
      const tank_level = data.split(",")[2]; // stack에서 읽어서 주는 tank level (stack 2개 부터는 한 개는 더미데이터?를 뿌리는 / 수소가 많이 있는데도 꺼질수도 있어서)
      const battery_voltage = data.split(",")[3];
      const output_power = data.split(",")[4];
      const spm_input_power = data.split(",")[5];
      const battery_power = data.split(",")[6];

      const hex_to_decimal_state = parseInt(fcpm_state, 16);

      parsed_data_list.push({ key: "CyclicCounter", value: cyclic_counter });
      parsed_data_list.push({
        key: "FCPMState_Run",
        value: hex_to_decimal_state & 8 ? "1" : "0",
      });
      parsed_data_list.push({
        key: "FCPMState_Sleep",
        value: hex_to_decimal_state & 4 ? "1" : "0",
      });
      parsed_data_list.push({
        key: "FCPMState_DST",
        value: hex_to_decimal_state & 2 ? "1" : "0",
      });
      parsed_data_list.push({
        key: "FCPMState_DC",
        value: hex_to_decimal_state & 1 ? "1" : "0",
      });
      parsed_data_list.push({ key: "TankLevel", value: tank_level });
      parsed_data_list.push({ key: "BatteryVoltage", value: battery_voltage });
      parsed_data_list.push({ key: "OutputPower", value: output_power });
      parsed_data_list.push({ key: "SPMInputPower", value: spm_input_power });
      parsed_data_list.push({ key: "BatteryPower", value: battery_power });
    }
  }

  return parsed_data_list;
}

// Hydrogen System Data
export function SystemDataParser(line:string) {
  const parsed_data_list:object[] = [];

  let command = "";
  let data = "";

  const line_split = line.split(":");

  if (
    line_split != undefined &&
    line_split.length == 2 &&
    line_split[0].substring(0, 1) == ">"
  ) {
    command = line_split[0].slice(-3);
    data = line_split[1];
  } else {
    console.log("Invalid Line Data -> ", line);
    return parsed_data_list;
  }

  // Hydrogen System Data Parse
  if (command == "200") {
    // length : 6 인데 현재 5로 나옴 -> ',0,0,0,0'

    const gh2_tank1_volume = data.split(",")[1]; // 기체 탱크1의 용량
    const gh2_tank1_level = data.split(",")[2]; // 기체 탱크1의 압력

    const gh2_tank2_volume = data.split(",")[3]; // 기체 탱크2의 용량
    const gh2_tank2_level = data.split(",")[4]; // 기체 탱크2의 압력

    parsed_data_list.push({ key: "GH2Tank1Volume", value: gh2_tank1_volume });
    parsed_data_list.push({ key: "GH2Tank1Level", value: gh2_tank1_level });

    parsed_data_list.push({ key: "GH2Tank2Volume", value: gh2_tank2_volume });
    parsed_data_list.push({ key: "GH2Tank2Level", value: gh2_tank2_level });
  } else if (command == "460") {
    // length : 4
    const voltage1 = data.split(",")[0]; // 액화 제어 시스템 전원 전압

    parsed_data_list.push({ key: "LH2Voltage1", value: voltage1 });
  } else if (command == "400") {
    // length : 4
    const lh2_tank_volume = data.split(",")[2]; // 액화 탱크의 용량
    const lh2_remain_level = data.split(",")[3]; // 액화 탱크의 액화 수소량

    parsed_data_list.push({ key: "LH2TankVolume", value: lh2_tank_volume });
    parsed_data_list.push({ key: "LH2RemainLevel", value: lh2_remain_level });
  } else if (command == "420") {
    // length : 4
    const lh2_pressure2 = data.split(",")[1]; // 액화 유량계 입력측 압력
    const lh2_pressure3 = data.split(",")[2]; // 액화 유량계 출력측 압력

    parsed_data_list.push({ key: "LH2Pressure2", value: lh2_pressure2 });
    parsed_data_list.push({ key: "LH2Pressure3", value: lh2_pressure3 });
  } else if (command == "440") {
    // length : 4

    const lh2_hydrogen_total_use = data.split(",")[0]; // 액화 수소 누적 소모량
    const lh2_hydrogen_short_time_use = data.split(",")[1]; // 액화 수소 순간 소모량

    parsed_data_list.push({
      key: "LH2HydrogenTotalUse",
      value: lh2_hydrogen_total_use,
    });
    parsed_data_list.push({
      key: "LH2HydrogenShortTimeUse",
      value: lh2_hydrogen_short_time_use,
    });
  } else if (command == "600") {
    // length : 4

    const system_temperature1 = data.split(",")[0]; // 액화 제어 시스템의 스택 공급 수소 라인 온도
    const system_temperature3 = data.split(",")[2]; // 액화 제어 시스템 보드 온도

    parsed_data_list.push({
      key: "SystemTemperature1",
      value: system_temperature1,
    });
    parsed_data_list.push({
      key: "SystemTemperature3",
      value: system_temperature3,
    });
  } else if (command == "680") {
    // length : 6

    const mfc_control = data.split(",")[0]; // 유량계 OPEN %
    const heater_control = data.split(",")[4]; // 액화 수소 히터의 제어량을 백분률로 표시

    parsed_data_list.push({
      key: "MfcControl",
      value: mfc_control,
    });
    parsed_data_list.push({
      key: "HeaterControl",
      value: heater_control,
    });
  } else if (command == "6A0") {
    // length : 8

    const fcpm_type = data.split(",")[0]; // 'S' : Soar 2.4Kw / 'F' : FCM 80x
    const fuel_type = data.split(",")[1]; // 'L' : LH2 / 'G' : 'GH2'
    const fcpm_multi = data.split(",")[3]; // MAX는 현재 8 이지만 변경될 수 있음
    const uav_sequence = data.split(",")[6]; // 무시해도 됨

    parsed_data_list.push({
      key: "FcpmType",
      value: fcpm_type,
    });
    parsed_data_list.push({
      key: "FuelType",
      value: fuel_type,
    });
    parsed_data_list.push({
      key: "FcpmMulti",
      value: fcpm_multi,
    });
    parsed_data_list.push({
      key: "UavSequence",
      value: uav_sequence,
    });
  }

  return parsed_data_list;
}