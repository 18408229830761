import { cesiumService } from "@/services/cesium/cesium_service_inst";
import DroneManager from "@/services/hg/drone_manager";
import axios from 'axios';
import * as turf from '@turf/turf';
import CustomAlert, {CustomAlertOptions} from "@/services/hg/custom_alert";

interface FlightInfo_AIRLABS {
    hex: string,
    reg_number: string,
    flag: string,
    lat: number,
    lng: number,
    alt: number,
    dir: number,
    speed: number,
    squawk: string,
    flight_number: string,
    flight_icao: string,
    flight_iata: string,
    dep_icao: string,
    dep_iata: string,
    arr_icao: string,
    arr_iata: string,
    airline_icao: string,
    airline_iata: string,
    aircraft_icao: string,
    updated: number,
    status: string
}

export default class LiveFlightsManager {

    private static instance:LiveFlightsManager

    public static getInstance () {
        return this.instance || (this.instance = new this())
    }

    private flights:FlightInfo_AIRLABS[] = [];
    private worker_interval_ms = 5000
    private entity:any;

    private constructor(){
        this.init();
        // this.worker();
    }

    private init() {
        const viewer = cesiumService.GetViewer();
        this.entity = viewer.entities.getOrCreateEntity('flights-info-entity');
    }

    private worker() {

        this.fetchState();
        this.updater();
        this.remover();
        this.caution();

        setTimeout(() => {
            this.worker()
        }, this.worker_interval_ms)
    }

    private insertOrUpdate(flight:FlightInfo_AIRLABS) {
        const Cesium = cesiumService.GetCesium();
        const viewer = cesiumService.GetViewer();

        const entity_key: string = flight.hex + flight.reg_number;

        let exist = false;
        this.entity._children.forEach((child: any) => {
            if(child._id == entity_key)
            {
                exist = true;
                return;
            }
        });

        let child:any = undefined;
        if(exist)
        {
            child = viewer.entities.getById(entity_key);
        }
        else
        {
            child = viewer.entities.getOrCreateEntity(entity_key);

            child.parent = this.entity;
            child.model = 
            {
                uri: '/DroneModel/Airplane.glb',
                minimumPixelSize: 80,
                silhouetteColor: Cesium.Color.WHITE,
                silhouetteSize: 2,
            }
        }

        if(child)
        {
            child.position = Cesium.Cartesian3.fromDegrees(flight.lng, flight.lat, flight.alt);
            child.orientation = Cesium.Transforms.headingPitchRollQuaternion(
                Cesium.Cartesian3.fromDegrees(flight.lng, flight.lat, flight.alt), 
                new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(flight.dir-90), 0, 0)
            );

            child.name = Date.now(); // 마지막 수신 시각 체크 용도로 활용
        }
    }

    private fetchState() {
        const drone_list = DroneManager.getInstance().GetDroneList();
        const drone_positions:any = [];
        drone_list.forEach(drone => {
            const drone_state = drone.GetState();
            if(drone_state)
            {
                drone_positions.push({
                    lng: drone_state._gps_int._lon / 1e7,
                    lat: drone_state._gps_int._lat / 1e7
                })
            }
        });
        
        // axios.get('/api/flights').then((res:any) => {
        //     res.data.forEach((flight:FlightInfo_AIRLABS, index: number) => {
        //         // this.insertOrUpdate(flight);
        //     })
        // })

        // axios.get('/api/flights/hitmap').then((res:any) => {
        //     store.commit('SetFHM', res.data);
        // })

        // drone_positions.forEach((drone_position:any) => {

        //     axios.get(`/api/flights/nearby?lng=${drone_position.lng}&lat=${drone_position.lat}`).then((res:any) => {
        //         if(res.status == axios.HttpStatusCode.Ok)
        //         {
        //             res.data.forEach((flight:FlightInfo_AIRLABS, index: number) => {
        //                 this.insertOrUpdate(flight);
        //             })
        //         }
        //     })
            
        // });

        axios.post( `/gcs/flights/nearby/v2`, {drone_positions}
        ).then((res:any) => {
            if(res.status == axios.HttpStatusCode.Ok)
            {
                this.flights = res.data;
                // res.data.forEach((flight:FlightInfo_AIRLABS, index: number) => {
                //     this.insertOrUpdate(flight);
                // })
            }
        })

    }

    private updater() {
        this.flights.forEach((flight: FlightInfo_AIRLABS, index: number) => {
            this.insertOrUpdate(flight);
        })
    }

    private remover() {
        const viewer = cesiumService.GetViewer();
        const removed:string[] = [];
        this.entity._children.forEach((child: any) => {
            if(Math.abs(child._name - Date.now()) > (this.worker_interval_ms * 2))
            {
                
                removed.push(child._id);
            }
        });

        removed.forEach(_ => {
            viewer.entities.removeById(_);
        });
    }

    private caution() {
        const Cesium = cesiumService.GetCesium();
        const viewer = cesiumService.GetViewer();
        const drone_list = DroneManager.getInstance().GetDroneList();
        const drones:any = [];
        drone_list.forEach(drone => {
            const drone_state = drone.GetState();
            if(drone_state)
            {
                drones.push({
                    name: drone.GetNoti()._name,
                    lng: drone_state._gps_int._lon / 1e7,
                    lat: drone_state._gps_int._lat / 1e7
                })
            }

        });


        const caution_alert_options:CustomAlertOptions = {
            color: 'error',
            position: 'top',
            timeout: 3000,
        }
        drones.forEach((drone:any) => {
            const from = turf.point([drone.lng, drone.lat]);

            this.flights.forEach(flight => {
                const to = turf.point([flight.lng, flight.lat]);    
                const distance = turf.distance(from, to, {units: 'kilometers'});

                if(distance < 2)
                {
                    const flight_entity = viewer.entities.getOrCreateEntity(flight.hex+flight.reg_number);
                    flight_entity.model.silhouetteColor = Cesium.Color.RED;
                    CustomAlert.getInstance().ShowAlert(`Flight flying arround ${drone.name} : ${distance.toFixed(0)} km`, caution_alert_options);
                }
                
            });
            
        });
    }
}