
import ScreenManager from "@/services/hg/screen_manager";
import store from "@/store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      select: "Heavy Equipment",
      functionList: [
        {
          name: "Heavy Equipment",
          icon: "ai",
          action: "heavy equipment",
        },
        {
          name: "Facial Recognition",
          icon: "ai2",
          action: "",
        },
        {
          name: "People Counting",
          icon: "ai3",
          action: "",
        },
        {
          name: "Abnomal Fence",
          icon: "ai3",
          action: "",
        },
      ],
    };
  },

  setup() {
    const show_extension_function = computed(
      () => store.getters.GetExtensionFunction
    );
    return { show_extension_function };
  },
  watch: {
    group() {
      this.show_extension_function = false;
    },
  },
  methods: {
    CloseBtn() {
      store.commit("SetExtensionFunction", false);
      this.select = "Heavy Equipment";
    },
    onClickfunction(data: any) {
      this.select = data;
    },
    zindex(id:string) {
      ScreenManager.getInstance().SetZ_Index(id)
    }
  },
});
