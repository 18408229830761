import ws from "@/services/ws/ws_hg_server";
import { RequestStackList } from "@/services/ws/ws_hg_server_message_sender";
import {
  HG_Contents,
  HG_Header,
  HG_HydrogenContents,
  HG_Hydrogen_Log_Info,
  HG_LogFileInfo,
  HG_LogFileItem,
  HG_LogList,
  HG_Packet,
  HG_StreamInfo,
  HG_Struct_Hydrogen_Info
} from "@/services/ws/ws_hg_server_packet_interface";
import { E_AI_TYPE, E_APP_TYPE, E_MESSAGE_TYPE } from "@/services/ws/ws_hg_server_protocol";
import store from "@/store/index";
import CustomAlert from "../hg/custom_alert";
import DeviceManager from "../hg/device/DeviceManager";
import DroneManager from "../hg/drone_manager";
import { ChannelInfo } from "../hg/drone_tv/webrtc";
import ExtensionAiManager, { HG_Ai_Image_Data, HG_Ai_Meta_Pos, HG_Ai_Owner_Data } from "../hg/extension_ai/ExtensionAiManager";
import { CommandParser, FaultParser, SystemDataParser } from "../hg/hydrogen/hyd_util";
import SimulateManager from "../hg/simulate/simulate_manager";
import StackManager from "../hg/stack/StackManager";
import UserSettingManager from "../hg/user_setting/user_setting_manager";
import LogFileManager from "../report/LogFileManager";

let bytes: Uint8Array;
let logFile: Blob;
let writePosition = 0;

export function parse(data: string) {
  const packet: HG_Packet = JSON.parse(data);
  const header: HG_Header = packet._header;
  const contents: HG_Contents = packet._contents;
  if (header != undefined && header._msg_type != undefined) {
    const msg_type: E_MESSAGE_TYPE = header._msg_type;
    if (msg_type == E_MESSAGE_TYPE.LOGIN) {
      if (header._index != undefined) {
        if (
          (header._index & E_APP_TYPE.GCS) != 0 ||
          (header._index & E_APP_TYPE.GCS_MONITOR) != 0
          ) {
          console.log(`Login success ${header._index}`);
          // console.log('Hyd Server Not Connected waiting ...', packet)
          store.commit("SetAuthState", true);
          store.commit("SetAppType", header._index);

          UserSettingManager.getInstance();

          // hyd viewer 또는 hyd 일 경우 수소 서버 연결
          if((header._index & E_APP_TYPE.HYDROGEN) != 0 || (header._index & E_APP_TYPE.ADMIN) != 0)
          {
            // 수소 서버 권한이 있는 계정일 경우에만 해당 서버 연결
            ws.getInstance().HydrogenConnectWithAuth();
          }
          else console.log(`This account is not supported Hydrogen.`)

          // TESTESTESTESTESTESTESTESTESTEST
          // const raw_data = StackManager.getInstance().RawDataParse()
          // raw_data.forEach((item:any) => {
          //   const base64decode = atob(String(item.base64));
          //   let addr = '';
          //   let command = '';
          //   let data = '';
          //   let all_t = '';
          //   for(let i = 0; i < base64decode.length; ++i) {
          //       if(i < 2) {
          //           addr += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
          //           all_t += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
          //       }
          //       else if(i < 4) {
          //           command += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
          //           all_t += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
          //       }
          //       else {
          //           data += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
          //           all_t += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
          //       }
          //   }
          //   const hex_command = parseInt(command, 16);
          //   if(command == 'A800') {
          //     console.log('TrueTrueTrueTrueTrueTrueTrueTrueTrueTrueTrueTrueTrueTrueTrueTrueTrue')
          //   } else {
          //     console.log('command', command)
          //   }
          // })

          // console.log('RawData Parse')

        } else if (header._index == E_APP_TYPE.NONE) console.log("GCS test"), CustomAlert.getInstance().ShowAlert(`Login Failed`, {color:'warning'});
        else console.log("GCS Server Login Result Error : ", header._index), CustomAlert.getInstance().ShowAlert(`Login Failed`, {color:'success'});
      }
    }

    if (msg_type == E_MESSAGE_TYPE.LOST_AUTHENTICATION) {
      // 다른 곳에서 GCS 모드로 로그인 하면 해당 웹 강제 새로고침
      window.location.reload();
    }

    if (msg_type == E_MESSAGE_TYPE.STATE_NOTI) {
      if (contents != undefined && contents._drone_noti != undefined) {
        DroneManager.getInstance().UpdateDroneNotiList([
          ...contents._drone_noti,
          ...SimulateManager.getInstance().GetSimulationNotis(),
        ]);
      }
    }

    if (msg_type == E_MESSAGE_TYPE.STATUS) {
      if (
        header._name != undefined &&
        contents != undefined &&
        contents._drone_states != undefined
      ) {
        DroneManager.getInstance().UpdateDroneState(
          header._name,
          contents._drone_states
        );
      }
    }

    if (msg_type == E_MESSAGE_TYPE.DRONE_TV_CHANNEL) {
      if (
        header._index != undefined &&
        contents != undefined &&
        contents._channel_info != undefined
      ) {
        const channel_infos: HG_StreamInfo[] = contents._channel_info;
        const dronetv_channels: ChannelInfo[] = []
        const ai_channels: ChannelInfo[] = []
        channel_infos.forEach((channel_info: HG_StreamInfo) => {
          //드론tv 채널 생성
          let already_insert = false;
          const compare = channel_info.proxy_url ? channel_info.proxy_url : channel_info.src;
          dronetv_channels.forEach((entry:ChannelInfo) => {
            if(entry.rtspurl == compare) {
              already_insert = true;
            }
          });
          if(already_insert == false) {
            dronetv_channels.push({
              index: channel_info.group_index,
              channel_id: channel_info.group_index,
              rtspurl: channel_info.proxy_url ? channel_info.proxy_url : channel_info.src ? channel_info.src : '',
              psn: channel_info.psn ? channel_info.psn : '',
              des: channel_info.description ? channel_info.description : '',
            })
          }
          // AI 채널
          if(channel_info.ai_type != undefined) {
            const type_name:string = channel_info.ai_type == 1 ? 'People Counting' : channel_info.ai_type == 2 ? 'Heavy Equipment' : channel_info.ai_type == 3 ? 'Fence Detection' : 'Unknown';
            ai_channels.push({
                index: channel_info.group_index,
                channel_id: channel_info.group_index,
                rtspurl: channel_info.proxy_url ? channel_info.proxy_url : channel_info.src ? channel_info.src : '',
                psn: channel_info.psn ? `${channel_info.psn}(${type_name})` : '',
                des: channel_info.description ? channel_info.description : '',
                ai_account: channel_info.ai_account ? channel_info.ai_account : '',
                ai_device: channel_info.ai_device ? channel_info.ai_device : '',
                ai_type: channel_info.ai_type ? channel_info.ai_type : E_AI_TYPE.NONE,
                ai_url: channel_info.ai_url ? channel_info.ai_url : '',
              })
          }

          // }
        });
        store.commit('SetAiChannel', ai_channels)
        store.commit('SetDroneTvChannel', dronetv_channels);
      }
    }

    if (msg_type == E_MESSAGE_TYPE.FC_LOG_LIST_REPLY) {
      if (contents._log_list_reply != undefined) {
        const log_list: HG_LogList = contents._log_list_reply;

        if(log_list._chunk_num == 1) {
          store.commit('CleanLogFileList');
        }

        const ignore_0_kb_list:HG_LogFileItem[] = [];

        log_list._file_list.forEach((file_item:HG_LogFileItem) => {
          if(file_item._size != 0) {
            ignore_0_kb_list.push(file_item);
          }
        });
        store.commit("AddLogFileList", ignore_0_kb_list);
      }
    }

    if (msg_type == E_MESSAGE_TYPE.FC_LOG_DATA_REPLY) {
      console.log('MSG_TYPE_FC_LOG_DATA_REPLY', contents._log_data_reply);
      store.commit("SetLogProgress", contents._log_data_reply?._progress);
      const FileInfo:HG_LogFileInfo = store.getters.GetLogFileInfo;
      const DecodedData = atob(contents._log_data_reply?._file_data);
      const binary_string = DecodedData;

      const len = binary_string.length

      if (
        contents._log_data_reply?._chunk_num == 0 && contents._log_data_reply?._file_data == ""
      ) {
        bytes = new Uint8Array(FileInfo._size);
      } else if (contents._log_data_reply?._file_data != "") {
        for (let i = 0; i < len; i++) {
          // console.log(binary_string.charCodeAt(i))
          bytes[writePosition] = binary_string.charCodeAt(i);
          writePosition++;
        }
      } else console.log("None");

      if (
        contents._log_data_reply?._chunk_num ==
          contents._log_data_reply?._max_chunk &&
        contents._log_data_reply?._file_data != ""
      ) {
        // end
        logFile = new Blob([bytes.buffer], {
          type: "application/octet-stream",
        });
        const downloadLink = document.createElement("a");
        downloadLink.download = `${header._name}_${FileInfo._name}.bin`;
        downloadLink.href = window.URL.createObjectURL(logFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        window.URL.revokeObjectURL(downloadLink.href);
        writePosition = 0;
        const LogName = `${header._name}_${contents._log_data_reply?._filename}`;
        store.commit("SetLogProgress", 0);
        store.commit('SetLogSaveDoneList', LogName)
      }
    }

    if (msg_type == E_MESSAGE_TYPE.AI_IMAGE) {
      const Ai_Image:HG_Ai_Image_Data|undefined = contents._image;
      const Ai_Owner:HG_Ai_Owner_Data|undefined = contents._owner;
      if(Ai_Image && Ai_Owner) {
        ExtensionAiManager.getInstance().SetAiImage(Ai_Image, Ai_Owner)
      }
    }

    if (msg_type == E_MESSAGE_TYPE.AI_META) {
      if(contents._info && contents._owner && contents._pos) {
        const Ai_Meta_Info:any|undefined = contents._info
        const Ai_Meta_Owner:HG_Ai_Owner_Data = contents._owner
        const Ai_Meta_Pos:HG_Ai_Meta_Pos = contents._pos
        if(Ai_Meta_Info && Ai_Meta_Owner && Ai_Meta_Pos) {
          ExtensionAiManager.getInstance().SetAiMeta(Ai_Meta_Info, Ai_Meta_Owner, Ai_Meta_Pos)
          ExtensionAiManager.getInstance().SetAiDataArr(Ai_Meta_Owner._type)
        }
      }
    }

  } else {
    console.log(`${data} Not json type`);
  }
}

export function hydrogen_parse(data:any) {
  const packet:any = JSON.parse(data)
  const header:HG_Header = packet._header
  const contents:HG_HydrogenContents = packet._contents
  if(header != undefined && header._msg_type != undefined) {
    const msg_type:E_MESSAGE_TYPE = header._msg_type
    if(msg_type == E_MESSAGE_TYPE.LOGIN) {
      if(header._index != undefined) {
        if(header._index != 0) {// HYD 서버 Response Type 점검 필 
          store.commit("SetStackAuth", true)
          // 데이터 먼저 바로 요청
          RequestStackList()
          // 3초에 1번씩 수소 스택 리스트 요청
          const request_stack_list = setInterval(() => {
            RequestStackList()
          }, 3000)
        }
        else console.log("Hyd Login Result Error : ", header._index)
      }
    }
    else if(msg_type == E_MESSAGE_TYPE.STACK_LIST) {// Stack List
      const chunk_num:number|undefined = contents._chunk_num;
      const max_chunk_num:number|undefined = contents._max_chunk;
      const stack_list:HG_Struct_Hydrogen_Info[]|undefined = contents._stack_list;
      if(chunk_num != undefined && max_chunk_num != undefined && stack_list != undefined) {
        StackManager.getInstance().UpdateStackList(chunk_num, max_chunk_num, stack_list);
        DeviceManager.getInstance().UpdateDeviceList(chunk_num, max_chunk_num, stack_list);
      }
    }
    else if(msg_type == E_MESSAGE_TYPE.HYDROGEN_STATUS) {// 수소 Data, (수소 모니터링 프로그램은 1002로 받음)
      const serial = packet._contents._hydrogen_states._serial_num;
      const line = packet._contents._hydrogen_states._data;
      const command_data = packet._contents._hydrogen_states._data.split(":")[0].slice(-5);
      const system_data_type = command_data.slice(0, 2);
      const command = command_data.slice(-3);
      const stack_data = packet._contents._hydrogen_states._data.split(":")[1];
      console.log('MSG_TYPE_HYDROGEN_STATUS', line)
      if(system_data_type != '1A') {
        if(command == '408' || command == '418' || command == '428' || command == '410' || command == '420' || command == '430') {// fault data 
            const first_flag = stack_data.split(",")[0];
            const second_flag = stack_data.split(",")[1];

            let first_flag_type = null
            let second_flag_type = null

            // 408, 418, 428 -> flag A , B
            if(command == '408' || command == '418' || command == '428') {
              first_flag_type = "A"
              second_flag_type = "B"
            }
            // 410, 420, 430 -> flag C , D
            else {
              first_flag_type = "C"
              second_flag_type = "D"
            }

            const faults = FaultParser(command, first_flag_type, first_flag).concat(FaultParser(command, second_flag_type, second_flag));

            // 현재 드론 개수만큼 foreach 돌려서 각 드론에 달린 스택들중에 받은 serial과 서로 동일한 drone_index를 가진 스택을 찾는 과정
            store.getters.GetStackList.forEach((stack_list_data:HG_Struct_Hydrogen_Info[]) => {
                // 해당 드론의 스택의 개수만큼 foreach
                stack_list_data.forEach((stack_data:HG_Struct_Hydrogen_Info) => {
                    // 어떠한 드론에서 클릭한 스택의 serial과 해당 드론의 스택중 같은 serial이 있는지 비교
                    if(stack_data._stack_serial == serial) {
                        // 있다면 해당 드론의 index를 키 값으로 Map Object 생성 (드론의 입장에서 stack serial를 가져올 수 없어서 드론 인덱스를 활용하여 Object Array 구현)
                        faults.forEach((fault:any) => {
                            store.commit("AddSelectedStackFaultData", {
                                time: new Date().toLocaleString('ko-KR', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' }),
                                drone_index: stack_data._device_serial, // Real Code
                                serial: serial,
                                fault_key:
                                fault.command +
                                fault.flag +
                                "0x" +
                                fault.code.toString(16).padStart(8, "0"),
                                fault: fault, // 기존 fault 작성 데이터 그대로 넣음
                            });
                        });
                    }
                })
            });
        }
        else {// stack information
          // 현재 드론 개수만큼 foreach 돌려서 각 드론에 달린 스택들중에 받은 serial과 서로 동일한 drone_index를 가진 스택을 찾는 과정
          store.getters.GetStackList.forEach((stack_list_data:HG_Struct_Hydrogen_Info[]) => {
              // 해당 드론의 스택의 개수만큼 foreach
              stack_list_data.forEach((stack_data:HG_Struct_Hydrogen_Info) => {
                  // 스택 리스트 안에 해당 serial 스택이 있는지 체크
                  if(stack_data._stack_serial == serial) {
                      // 있다면 해당 드론의 index를 키 값으로 Map Object 생성 (드론의 입장에서 stack serial를 가져올 수 없어서 드론 인덱스를 활용하여 Object Array 구현)
                      CommandParser(line).forEach((parsed_data:any) => {
                          store.commit("AddSelectedStackData", {
                              drone_index: stack_data._device_serial,
                              serial: serial, 
                              command: parsed_data.key,
                              data: parsed_data.value,
                          });
                      });
                  }
              })
          });
      }
      }
      else // '1A'
      {
        // 현재 드론 개수만큼 foreach 돌려서 각 드론에 달린 스택들중에 받은 serial과 서로 동일한 drone_index를 가진 스택을 찾는 과정
        store.getters.GetStackList.forEach((stack_list_data:HG_Struct_Hydrogen_Info[]) => {
            // 해당 드론의 스택의 개수만큼 foreach
            stack_list_data.forEach((stack_data:HG_Struct_Hydrogen_Info) => {
                // 스택 리스트 안에 해당 serial 스택이 있는지 체크
                if(stack_data._stack_serial == serial) {
                    // 있다면 해당 드론의 index를 키 값으로 Map Object 생성 (드론의 입장에서 stack serial를 가져올 수 없어서 드론 인덱스를 활용하여 Object Array 구현)
                    SystemDataParser(line).forEach((parsed_data:any) => {
                        store.commit("AddSelectedStackData", {
                            drone_index: stack_data._device_serial,
                            serial: serial, 
                            command: parsed_data.key,
                            data: parsed_data.value,
                        });
                    });
                }
            })
        });
      } 
    }
    else if(msg_type == E_MESSAGE_TYPE.STACK_SUBSCRIPTION) {
      // const TYPE = contents._hydrogen_states?._data.split(":")[0].slice(-3)
      // // console.log("asd", contents._hydrogen_states?._data)
      // const line_split = contents._hydrogen_states?._data.split(":");
      // console.log("asd", line_split)
      // if(line_split != undefined){
      //   const command = line_split[0].slice(-3);
      //   console.log(command)
      //   if(command == "6A0"){
      //     console.log(contents._hydrogen_states?._data)
      //   }
      // }
      // console.log('STACK_SUBSCRIPTION', contents)
      if(contents._hydrogen_states) {
        const serial_num:string = contents._hydrogen_states._serial_num;
        const data:string = contents._hydrogen_states._data;

        StackManager.getInstance().UpdateStackData(serial_num, data);
      }
    }
    else if(msg_type == E_MESSAGE_TYPE.STACK_LOG_FILE_LIST) {
        const chunk_num:number|undefined = contents._chunk_num;
        const max_chunk_num:number|undefined = contents._max_chunk;
        const file_list:HG_Hydrogen_Log_Info[]|undefined = contents._file_list;
  
        if(chunk_num != undefined && max_chunk_num != undefined && file_list != undefined) {
            LogFileManager.getInstance().UpdateLogFileList(chunk_num, max_chunk_num, file_list);
        }
    }
    else if(msg_type == E_MESSAGE_TYPE.STACK_LOG_FILE_DATA) {
        const chunk_num:number|undefined = contents._chunk_num;
        const max_chunk_num:number|undefined = contents._max_chunk;
        const file_data:string|undefined = contents._file_data;
        const file_name:string|undefined = contents._filename;
        const error:number|undefined = contents._error;
  
        if(chunk_num != undefined && max_chunk_num != undefined && file_data != undefined && file_name != undefined) {
            LogFileManager.getInstance().UpdateLogFileData(chunk_num, max_chunk_num, file_data, file_name);
        }
    }
    else console.log('unknown hyd type : ', msg_type)
  }
  else {
    console.log(`${data} not json type`)
  }
}