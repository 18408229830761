export default class DEVICE {
    private device_serial = '';
    private stack_list:Map<string,string> = new Map<string,string>();
    constructor(device_serial:string) {
        this.device_serial = device_serial;
    }

    AddStack(stack_serial:string) {
        if(this.stack_list.has(stack_serial) == false) {
            this.stack_list.set(stack_serial, stack_serial);    
        }
    }

    Get() {
        return {
            device_serial: this.device_serial,
            stack_list: this.stack_list,
        }
    }
}