
import { computed, defineComponent } from "vue";
import store from "@/store";
export default defineComponent({
  data() {
    return {
      droneAiMission: [
        {
          name: "Drone Surveillance system",
          img: "drone-camera",
          action: "",
        },
        {
          name: "Drone Facial Recognition",
          img: "facial-recsognition",
          action: "",
        },
        {
          name: "Fire Surveillance system",
          img: "fire-surveillance",
          action: "",
        },
      ],
    };
  },

  setup() {
    const show_ai = computed(() => store.getters.GetShowAi);
    const show_menu = computed(() => store.getters.GetSideMissionTab);
    return { show_ai, show_menu };
  },
  methods: {
    BackBtn() {
      store.commit("SetShowAi", !this.show_ai);
      store.commit("SetSideMenuTab", true);
    },
  },
});
