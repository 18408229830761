import { cesiumService } from "../cesium/cesium_service_inst";
import axios from 'axios';

interface Airport {
  name: string,
  iata_code: string,
  icao_code: string,
  lat: number,
  lng: number,
  country_code: string
}

export default class NoFlightZone {
  private static instance: NoFlightZone;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }
  private _airport_entity: any;
  private airports_data:Airport[] = [];

  private _military_entity: any;
  private constructor() {
    axios.get('/gcs/flights/airports').then((res: any) => {
      this.airports_data = res.data;
      this.setData();
    })

    this.init();
    // this.SetShow(false);
  }

  SetShow(show: boolean) {
    this._airport_entity.show = show;
  }

  private setData() {
    const airports_data = this.airports_data;
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();
    //Airport Prohibited area = 9.3km
    const ellipsoid_size = 9300.0;
    airports_data.forEach((data:Airport, index:number) => {
      if(data.country_code != 'KR') return;
      if(!data.name.toLowerCase().includes('airport')) return;
      const child = viewer.entities.add({
        show: true,
        id: data.name+index,
        name: "Dome",
        position: Cesium.Cartesian3.fromDegrees(data.lng, data.lat),

        // 돔
        ellipsoid: {
          radii: new Cesium.Cartesian3(
            ellipsoid_size,
            ellipsoid_size,
            ellipsoid_size
          ),
          maximumCone: Cesium.Math.PI_OVER_TWO,
          material: new Cesium.ColorMaterialProperty(
            Cesium.Color.RED.withAlpha(0.15)
          ),
          // outline: true,
        },

        //원기둥
        // cylinder: {
        //   length: 4000.0,
        //   topRadius: ellipsoid_size,
        //   bottomRadius: ellipsoid_size,
        //   material: new Cesium.ColorMaterialProperty(
        //          Cesium.Color.RED.withAlpha(0.15)
        //        ),
        //   outline: true,
        //   outlineColor: Cesium.Color.FIREBRICK.withAlpha(0.3),
        // },

        // 평면
        // ellipse: {
        //   semiMinorAxis: ellipsoid_size,
        //   semiMajorAxis: ellipsoid_size,
        //   material: new Cesium.ColorMaterialProperty(
        //     Cesium.Color.RED.withAlpha(0.15)
        //   ),
        // },

        // model: {
        //     uri: '/DroneModel/Cesium_Air.glb',
        //     scale: 3.0,
        //     minimumPixelSize: 100,
        //     // silhouetteColor: Cesium.Color.ORANGE,
        //     // silhouetteSize: 5,
        //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        // },
        label: {
          id: data.name,
          font: '16px NotoMedium',
          text: data.name,
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          outlineColor : Cesium.Color.BLACK,
          fillColor: Cesium.Color.YELLOW,
          outlineWidth : 5,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
        }
      });
      child.parent = this._airport_entity;
    });

    // 메모리 해제를 위해 배열 초기화
    airports_data.length = 0;
  }

  private init() {
    const Cesium = cesiumService.GetCesium();
    const viewer = cesiumService.GetViewer();
    this._airport_entity = viewer.entities.getOrCreateEntity("airports-entity");


    viewer.entities.add({
      show: true,
      id: 'factory test deny zone',
      name: "factory test deny zone",

      polygon: {
        hierarchy: Cesium.Cartesian3.fromDegreesArray([
          126.83325483440487, 35.23987582080371,
          126.83319299859295, 35.240113327929436,
          126.83278262434666, 35.2400612754046,
          126.83283331875747, 35.239811088689535,
        ]),
        material: new Cesium.ColorMaterialProperty(
          Cesium.Color.RED.withAlpha(0.2)
        ),
      },

      // position: Cesium.Cartesian3.fromDegrees(126.83301355011477, 35.23996755302004),
      // label: {
      //   font: '16px NotoMedium',
      //   text: 'Do not fly in this red zone',
      //   style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      //   outlineColor : Cesium.Color.BLACK,
      //   fillColor: Cesium.Color.YELLOW,
      //   outlineWidth : 5,
      //   disableDepthTestDistance: Number.POSITIVE_INFINITY,
      //   heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      // }

    });
  }
}
