
import CESIUM_DRONE from "@/services/hg/cesium_drone";
import ScreenManager from "@/services/hg/screen_manager";
import { LINE_CHART_SERIES_FROM, RANGE_CHART_SERIES_FORM, ST_LOG_FILE_INFO } from "@/services/report/LogFileManager";
import { RequestStackLogFileData } from "@/services/ws/ws_hg_server_message_sender";
import { HG_Hydrogen_Log_Info2 } from "@/services/ws/ws_hg_server_packet_interface";
import store from "@/store";
import Vue, { computed } from "vue";
import VueApexCharts from 'vue-apexcharts';
import VueDraggableResizable from "vue-draggable-resizable";

export default Vue.extend({
  data() {
    const dt_today = new Date();
    const dt_before_week = new Date();
    dt_before_week.setFullYear(dt_today.getFullYear(), dt_today.getMonth(), dt_today.getDate() - 7);
    return {
      screen_w : 0,
      screen_h : 0,
      resize_h : 400,
      resize_w : 650,
      resize_state : true,
      search_start_date: `${dt_before_week.getFullYear()}-${(dt_before_week.getMonth() + 1).toString().padStart(2, '0')}-${dt_before_week.getDate().toString().padStart(2, '0')}`,
      search_end_date: `${dt_today.getFullYear()}-${(dt_today.getMonth() + 1).toString().padStart(2, '0')}-${dt_today.getDate().toString().padStart(2, '0')}`,
      drones: [] as CESIUM_DRONE[],
      draggable_state: true,
      search_serial: '',
      selectDroneName: "",
      selectedListName: "",
      selected_files: [] as ST_LOG_FILE_INFO[],
      selectedLogDataState: "state",
      selectDrone: undefined as CESIUM_DRONE|undefined,
      selectStack: undefined as ST_LOG_FILE_INFO|undefined,
      all_log_list: [] as ST_LOG_FILE_INFO[],
      drone_log_list_filter: [] as ST_LOG_FILE_INFO[],
      divided_log_list: [] as any,
      list_number: 0,
      filterList: "All",
      sort: false,
      sortKey: "size",
      sortOrder: "asc",
      access_time_state: 'up',
      recording_time_state: 'up',
      analyze_tab: null,
      soar_state_series: [
        {
          data: [],
        }
      ],
      soar_data_series: [
        {
          data: [],
        }
      ],
      line_chart_options: {
        chart: {
          type: 'line',
          toolbar: {
            show: true,
            // tools: {
            //   download: false,
            //   selection: false,
            //   zoom: false,
            //   zoomin: false,
            //   zoomout: false,
            //   pan: false,
            //   reset: true,
            //   customIcons: []
            // },
          },
          foreColor: '#fff',
          animations: {
            enabled: true,
          },
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          onDatasetHover: {
            highlightDataSeries: false,
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,
            formatter: function (value:number) {
              const dt = new Date(value);
              const yyyy = dt.getFullYear().toString().padStart(4, '0');
              const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
              const dd = dt.getDate().toString().padStart(2, '0');
              const hh = dt.getHours().toString().padStart(2, '0');
              const mm = dt.getMinutes().toString().padStart(2, '0');
              const ss = dt.getSeconds().toString().padStart(2, '0');
              return `${hh}:${mm}:${ss}`;
              // return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
            }
          },
        },
        yaxis: {
          min: 0,
          // title: {
          //   text: 'Output'
          // },
        },
        legend: {
          show: true
        },
        stroke: {
          width: 2
        },
        grid: {
          borderColor:'#656565'
        }
      },
      range_chart_options: {
        chart: {
          // toolbar: {
          //   show: false,
          //   tools: {
          //     download: false,
          //     selection: false,
          //     zoom: false,
          //     zoomin: false,
          //     zoomout: false,
          //     pan: false,
          //     reset: true,
          //     customIcons: []
          //   },
          // },
          foreColor: '#ffffff',
          type: 'rangeBar'
        },
        // tooltip: {
        //   enabled: false,
        //   onDatasetHover: {
        //     highlightDataSeries: false,
        //   },
        // },
        tooltip: {
          enabled: true,
          theme: 'dark',
          onDatasetHover: {
            highlightDataSeries: false,
          },
          custom: function({series, seriesIndex, dataPointIndex, w}:any) {
            let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let period = 0;
            if(data.y.length == 2){
              period = (data.y[1] - data.y[0]);
            }
            return '<span style="margin: 10px;">' + '<b>' + data.x + '</b>: ' + period + 'ms' + '</span>';
          },
          x: {
            show: true,
            format: 'yyyy-MM-dd hh:mm:ss',
            
            // formatter: function(value:number) {
            //   return new Date(value).toLocaleString()
            // }
            // formatter: function (value:number) {
            //   const dt = new Date(value);
            //   const yyyy = dt.getFullYear().toString().padStart(4, '0');
            //   const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
            //   const dd = dt.getDate().toString().padStart(2, '0');
            //   const hh = dt.getHours().toString().padStart(2, '0');
            //   const mm = dt.getMinutes().toString().padStart(2, '0');
            //   const ss = dt.getSeconds().toString().padStart(2, '0');
            //   return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
            // }
          },
          // y: {
          //   // format: 'yyyy-MM-dd hh:mm:ss',
          //   formatter: undefined,
          //   title: {
          //     formatter: function(value:string, { series, seriesIndex, dataPointIndex, w }:any) {
          //       console.log(value, series, seriesIndex, dataPointIndex, w)
          //       return ''
          //     }
          //     // formatter: function(value:string) {
          //     //   console.log(value)
          //     //   return value
          //     // }
          //   },
          // },
          // z: {
          //   formatter: undefined,
          //   title: 'Size: '
          // },
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,

            formatter: function (value:number) {
              const dt = new Date(value);
              const yyyy = dt.getFullYear().toString().padStart(4, '0');
              const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
              const dd = dt.getDate().toString().padStart(2, '0');
              const hh = dt.getHours().toString().padStart(2, '0');
              const mm = dt.getMinutes().toString().padStart(2, '0');
              const ss = dt.getSeconds().toString().padStart(2, '0');
              return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
            }
          },
        },
        // yaxis: {
        //   title: {
        //     text: 'State'
        //   },
        // },
      },
    };
  },

  setup() {
    const showLogData:any = computed(() => store.getters.GetH2LogData);
    const h2_log_file_list = computed<ST_LOG_FILE_INFO[]>(() => store.getters.GetH2LogFileList);
    const store_drones = computed<CESIUM_DRONE[]>(() => store.getters.GetDrones);
    const ReportSoarState = computed<RANGE_CHART_SERIES_FORM[]>(() => store.getters.GetReportSoarState);
    const ReportSoarData = computed<LINE_CHART_SERIES_FROM[]>(() => store.getters.GetReportSoarData);
    return {
      showLogData,
      store_drones,
      h2_log_file_list,
      ReportSoarState,
      ReportSoarData,
    };
  },
  components: {
    apexchart: VueApexCharts,
    VueDraggableResizable,
  },
  mounted() {
    // RequestStackLogFileList();
    this.screen_h = screen.availHeight/2 - this.resize_h/2
    this.screen_w = screen.availWidth/2 - this.resize_w/2
    // console.log(screen.availHeight, screen.availWidth)
    // window.addEventListener("mouseup", (key:any) => {
    //   if(key.target._prevClass.split(" ")[0] == 'handle')  {
    //     this.resize_state = true
    //     console.log('resize up', this.resize_w)
    //   }
    // })
    // window.addEventListener("mousedown", (key:any) => {
    //   console.log('resize down', key)
    //   if(key.target._prevClass.split(" ")[0] == 'handle')  {
    //     this.resize_state = false
    //     console.log('resize down')
    //     //
    //   }
    // })
  },
  watch: {
    group() {
      this.showLogData = false;
    },
    search_serial: {
      handler(n, o) {
        this.SearchSerialLogListSorting(n)
      },
    },

    store_drones: {
      handler(n, o) {
        this.drones = n.filter((d:CESIUM_DRONE)=> !d.GetNoti()._simulate);
      },
    },
    h2_log_file_list: {
      handler(n, o) {
        this.all_log_list = n;
      },
    },
    search_start_date: {
      handler(n, o) {
        this.SelectDrone(this.selectDroneName)
      },
    },
    search_end_date: {
      handler(n, o) {
        this.SelectDrone(this.selectDroneName)
      },
    },
    ReportSoarState: {
      handler(val, oldVal) {
        const chart:any = this.$refs.chart_soar_state;
        if(chart) chart.updateSeries([{data:val}], true)
        this.soar_state_series = [{data:val}];
      },
    },
    ReportSoarData: {
      handler(val, oldVal) {
        const chart:any = this.$refs.chart_soar_data;
        if(chart) chart.updateSeries(val, true)
        this.soar_data_series = val;
      },
    },
  },
  methods: {
    CloseBtn() {
      store.commit("SetH2LogData", false);
      this.selectDroneName = "";
      this.drone_log_list_filter = []
      this.divided_log_list = []
      this.selected_files = []
    },
    SelectDrone(name:string) {
      this.selectDroneName = name
      this.SelectedDroneLogListSorting(name)
      this.TimeSort('up')
    },
    SelectStack(name:string) {
      this.selectDroneName = name
    },
    DateFormat_full(dt:Date) {
      const yyyy = dt.getFullYear().toString().padStart(4, '0');
      const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
      const dd = dt.getDate().toString().padStart(2, '0');
      const hh = dt.getHours().toString().padStart(2, '0');
      const mm = dt.getMinutes().toString().padStart(2, '0');
      const ss = dt.getSeconds().toString().padStart(2, '0');
      return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
    },
    DateFormat_ymd(dt:Date) {
      const yyyy = dt.getFullYear().toString().padStart(4, '0');
      const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
      const dd = dt.getDate().toString().padStart(2, '0');
      return `${yyyy}-${MM}-${dd}`;
    },
    DividedLogList() {
      this.divided_log_list = []
      for(let i=0; i<this.drone_log_list_filter.length; i+=5) {
        const list_5ea = [
          this.drone_log_list_filter[i], 
          this.drone_log_list_filter[i + 1],
          this.drone_log_list_filter[i + 2],
          this.drone_log_list_filter[i + 3],
          this.drone_log_list_filter[i + 4]
        ]
        this.divided_log_list.push(list_5ea);
      }
    },
    SelectedDroneLogListSorting(drone_name:string) {
      this.drone_log_list_filter = this.all_log_list.filter((log => 
        log.device_serial == drone_name && 
        log.model_type == 1 && // model type = 1 -> SOAR 2.4
        ( new Date(this.search_start_date) <= new Date(this.DateFormat_ymd(log.create_datetime)) && new Date(this.search_end_date) >= new Date(this.DateFormat_ymd(log.create_datetime)) )
      ))
      this.DividedLogList()
    },
    SearchSerialLogListSorting(search_text:string) {
      this.drone_log_list_filter = this.all_log_list.filter((log_list:ST_LOG_FILE_INFO) => 
        log_list.stack_serial.includes(search_text) && log_list.device_serial == this.selectDroneName
      )
      this.DividedLogList()
    },
    TimeSort(sort_type:string) {
      if(sort_type == 'up') {
        let a_convert:any = ''
        let b_convert:any = ''
        this.drone_log_list_filter.sort(function(a, b) {
          a_convert = new Date(a.create_datetime)
          b_convert = new Date(b.create_datetime)
          const a_yyyy = a_convert.getFullYear().toString().padStart(4, '0');
          const a_MM = (a_convert.getMonth() + 1).toString().padStart(2, '0');
          const a_dd = a_convert.getDate().toString().padStart(2, '0');
          const a_hh = a_convert.getHours().toString().padStart(2, '0');
          const a_mm = a_convert.getMinutes().toString().padStart(2, '0');
          const a_ss = a_convert.getSeconds().toString().padStart(2, '0');

          const b_yyyy = b_convert.getFullYear().toString().padStart(4, '0');
          const b_MM = (b_convert.getMonth() + 1).toString().padStart(2, '0');
          const b_dd = b_convert.getDate().toString().padStart(2, '0');
          const b_hh = b_convert.getHours().toString().padStart(2, '0');
          const b_mm = b_convert.getMinutes().toString().padStart(2, '0');
          const b_ss = b_convert.getSeconds().toString().padStart(2, '0');
            return Number(new Date(`${a_yyyy}-${a_MM}-${a_dd} ${a_hh}:${a_mm}:${a_ss}`)) - Number(new Date(`${b_yyyy}-${b_MM}-${b_dd} ${b_hh}:${b_mm}:${b_ss}`));
        });
        this.recording_time_state = 'down'
      } else {
        this.drone_log_list_filter.reverse();
        this.recording_time_state = 'up'
      }
      this.DividedLogList()
    },
    ListNumberSet(type:string) {
      if(type == '+') {
        if(this.list_number == (this.divided_log_list.length)){
          console.log('list_number Max')
        } else {
          this.list_number = this.list_number + 1
        }
      } else {
        if(this.list_number == 0) {
          console.log('list_number 0')
        } else {
          this.list_number = this.list_number - 1
        }
      }
    },
    Analyze() {
      const send_file_name_list:HG_Hydrogen_Log_Info2[] = [];
      this.selected_files.forEach((selected_file:ST_LOG_FILE_INFO) => {
        send_file_name_list.push({
          _filename: selected_file.file_name,
        })
      });
      RequestStackLogFileData(send_file_name_list);
    },
    onClickLogListFileCheck(file_info:ST_LOG_FILE_INFO):any {
      // this.search_serial = file_info.stack_serial;
      

      let exist = false;
      this.selected_files.forEach((selected_file:ST_LOG_FILE_INFO, index:number) => {
        if(file_info.file_name == selected_file.file_name) {
          this.selected_files.splice(index, 1);
          exist = true;
          this.Analyze();
        }
      });
      if(exist) {
        return;
      }

      let is_another_stack = false;
      this.selected_files.forEach((selected_file:ST_LOG_FILE_INFO) => {
        if(file_info.stack_serial != selected_file.stack_serial) {
          is_another_stack = true;
        }
      });
      if(is_another_stack) {
        this.selected_files = [];
      }

      if(this.selected_files.length == 2) {
        return
      }

      this.selected_files.push(file_info);

      this.selected_files.sort(function(a:ST_LOG_FILE_INFO, b:ST_LOG_FILE_INFO) {
        if(a.create_datetime > b.create_datetime) return 1;
        if(a.create_datetime < b.create_datetime) return -1;
        return 0;
      })

      this.Analyze();
    },
    SelectedLogFileChcek(file_name:string) {
      let selected_state = false
      this.selected_files.forEach((selected_file:ST_LOG_FILE_INFO)=>{
        if(file_name == selected_file.file_name) selected_state = true
      })
      return selected_state
    },
    onClickLogStateBtn(state:string) {
      this.selectedLogDataState = state
    },
    LogDataRemove(file_info:ST_LOG_FILE_INFO) {
      this.selected_files.forEach((selected_file:ST_LOG_FILE_INFO, index:number) => {
        if(file_info.file_name == selected_file.file_name) this.selected_files.splice(index, 1);
      })
      this.Analyze();
    },
    MouseEventDown() {
      this.draggable_state = false
    },
    MouseEventUp() {
      this.draggable_state = true
    },
    onResize: function (x:number, y:number, width:number, height:number) {
      this.resize_h = height;
      this.resize_w = width;
    },
    zindex(id:string) {
      ScreenManager.getInstance().SetZ_Index(id)
    }
  },
});
