
import CesiumScreenSpaceEventService, { GCS_FEATURE, MISSION_MODE_STATE, GCS_MODE, GCS_ModeInfo } from "@/services/cesium/cesium_screen_space_ev_service";
import CustomAlert from "@/services/hg/custom_alert";
import MissionWaypointGeneratingManager from "@/services/hg/mission/mission_generating_tool_manager";
import MissionManager, { MissionItem, MissionItemType } from "@/services/hg/mission/mission_manager";
import store from "@/store";
import { computed, defineComponent } from "vue";
import WayPointOption from "./WayPointOption.vue";
export default defineComponent({
    data() {
        return {
            show_top_tab: true,
            tab_open: false,
            alias: "Test Mission Generate",
            mission_data: {},
            mission_arr: [] as MissionItem[],
            panel: [],
        };
    },
    setup() {
        const mission_generate_tab = computed(() => store.getters.GetMissionGenerateTab);
        const side_mission_tab = computed(() => store.getters.GetSideMissionTab);
        const mission = computed(() => store.getters.GetMissionData);
        const set_waypoint_box = computed(() => store.getters.GetWayPointBox);
        const generating_missions = computed(() => MissionManager.getInstance().GetModifyMission())
        return { mission_generate_tab, mission, side_mission_tab, generating_missions, set_waypoint_box };
    },
    watch: {
        mission_generate_tab: {
            handler(n, o) {
                this.tab_open = n;
            },
        },
        generating_missions: {
            handler(val, oldVal) {
                this.mission_arr = val;
            },
            deep: true,
        }
    },
    methods: {
        onClickSaveMission() {
          const current_missions = MissionManager.getInstance().GetModifyMission()
            const options: any = {
                color: "red",
                timeout: 1000,
                position: "top right"
            };
            console.log("onClickSaveMission", current_missions);
            if(current_missions.length == 0) {
              CustomAlert.getInstance().ShowAlert("Please, Add waypoint", options)
            } else {
              store.commit("SetMissionPacket", { items: current_missions })
              store.commit("SetModalType", 'save_mission')
              store.commit("SetModalWindow", true)
            }
        },
        setUpdateAlt(num: number, mission: MissionItem, type:string) {
          //content == 1 --> Altitude
          if(type == 'Altitude'){
            CesiumScreenSpaceEventService.getInstance().SetMissionUpdateAlt(num, mission)
          //content == 2 --> Wait
          } else if(type == 'Wait') {
            CesiumScreenSpaceEventService.getInstance().SetMissionUpdateTime(num, mission)
          }
        },
        back() {
          CesiumScreenSpaceEventService.getInstance().SetMode(GCS_FEATURE.NORMAL);
          MissionWaypointGeneratingManager.getInstance().SetOptsDefault(),
          store.commit("SetMissionModeState", MISSION_MODE_STATE.OFF);
          store.commit("SetMissionGenerateTab", false);
          store.commit("SetSideMissionTab", true);
          store.commit("SetMissionData", []);
          store.commit("SetEditMissionName", '');
          MissionManager.getInstance().SetWaypointBoxState(false)
          MissionManager.getInstance().SetGCSMode(GCS_MODE.NORMAL)
          MissionManager.getInstance().SetMissionEntityVisible()
        },
        onClickDelete(mission_item:MissionItem) {
          if(mission_item.type == MissionItemType.ARM) {
            CesiumScreenSpaceEventService.getInstance().SetDeleteMissionItem(mission_item) // ARM
            CesiumScreenSpaceEventService.getInstance().SetDeleteMissionItem(this.mission_arr[0]) // TAKEOFF
          } else if(mission_item.type == MissionItemType.TAKEOFF) {
            CesiumScreenSpaceEventService.getInstance().SetDeleteMissionItem(mission_item) // TAKEOFF
            CesiumScreenSpaceEventService.getInstance().SetDeleteMissionItem(this.mission_arr[0]) // ARM
          } else {
            CesiumScreenSpaceEventService.getInstance().SetDeleteMissionItem(mission_item)
          }
        },
        WaypointLocation(lng:number, lat:number, alt:number) {
          MissionManager.getInstance().WaypointLocation(lng, lat, alt)
        },
    },
});
