
import CesiumScreenSpaceEventService, { GCS_FEATURE, GCS_MODE, GCS_ModeInfo, MISSION_MODE_STATE } from "@/services/cesium/cesium_screen_space_ev_service";
import { cesiumService } from "@/services/cesium/cesium_service_inst";
import CustomAlert from "@/services/hg/custom_alert";
import MissionWaypointGeneratingManager, { WaypointGenerateType } from "@/services/hg/mission/mission_generating_tool_manager";
import MissionManager, { MissionItem, MissionItemType } from "@/services/hg/mission/mission_manager";
import SimulateManager from "@/services/hg/simulate/simulate_manager";
import store from "@/store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      userid: "TestUser",
      right: null,
      mini: true,
      mode: true,
      select_btn: '',
      gcs_mode_info: MissionManager.getInstance().GetGCSMode(),
      functionType: MissionWaypointGeneratingManager.getInstance().GetMissionToolType(),
    };
  },

  setup() {
    const show_top_tab = computed(() => store.getters.GetTopTab);
    const account_id = computed(() => store.getters.GetAccountID);
    const app_type = computed(() => store.getters.GetAppType);
    const mission_mode_state = computed(() => store.getters.GetMissionModeState);
    return { show_top_tab, account_id, app_type, mission_mode_state };
  },
  watch: {
    gcs_mode_info: {
      deep:true,
      handler(n, o) {
        console.log('Top Bar GCS Mode', n)
      },
    },
  },

  filters: {
    LogSizeConverter: function (mode: GCS_MODE) {
      if(mode == GCS_MODE.MISSION_GENERATE) {
        return 'Mission Generate Mode'
      } else if(mode == GCS_MODE.MISSION_UPDATE) {
        return 'Mission Update Mode'
      } else if(mode == GCS_MODE.DRONE_TRACKING) {
        return 'Drone Tracking Mode'
      } else {
        return ''
      }
    },
  },

  methods: {
    OnClickMenu_tab() {
      const side_menu_tab = store.getters.GetSideMenuTab;
      store.commit("SetSideMenuTab", side_menu_tab ? false : true);
    },
    OnClickDrone_tab() {
      const side_drone_tab = store.getters.GetSideDroneTab;
      store.commit("SetSideDroneTab", !side_drone_tab);
    },
    OnClickMission_tab() {
      const side_mission_tab = store.getters.GetSideMissionTab;
      MissionManager.getInstance().SetMissionEntityVisible()
      store.commit("SetSideMissionTab", !side_mission_tab);
      store.commit("SetSideMissionTabState", 'mission');
    },
    OnClickRunSimulationTestBtn() {
      SimulateManager.getInstance().RunSimulate();
    },
    Missionback() {
      store.commit("SetSideMissionTab", true);
      store.commit("SetSideMissionDetailTab", false);
      store.commit("SetMissionData", []);
      store.commit("SetEditMissionName", '');
      CesiumScreenSpaceEventService.getInstance().SetMode(GCS_FEATURE.NORMAL);
      MissionWaypointGeneratingManager.getInstance().SetOptsDefault();
      store.commit("SetMissionModeState", MISSION_MODE_STATE.ON);
      MissionManager.getInstance().SetWaypointBoxState(false)
      MissionManager.getInstance().SetGCSMode(GCS_MODE.NORMAL)
      MissionManager.getInstance().SetMissionEntityVisible()
    },
    onClickModifyMissionSave() {
      // const mission_modify_arr :MissionItem[] = MissionManager.getInstance().GetModifyMission()
      // MissionManager.getInstance().SetMissionPacket(mission_modify_arr)
      store.commit("SetModalType", 'modify_mission_save')
      store.commit("SetModalWindow", true)
    },
    // back() {
    //   MissionManager.getInstance().SetGCSMode(GCS_MODE.NORMAL)
    //   const viewer = cesiumService.GetViewer();
    //   CesiumScreenSpaceEventService.getInstance().SetMode(GCS_FEATURE.NORMAL);
    //   MissionWaypointGeneratingManager.getInstance().SetOptsDefault(); // 미션 tool 옵션 초기화
    //   MissionManager.getInstance().SetSelectedMissionEntity('');
    //   store.commit("SetMissionModeState", MISSION_MODE_STATE.ON);
    //   store.commit("SetSideMissionDetailTab", false);
    //   store.commit("SetShowCommandSingle", false);
    //   store.commit("SetWayPointBox", false);
    //   store.commit("SetMissionData", []);
    //   store.commit("SetMissionGenerateTab", false);
    //   if(this.gcs_mode_info.mode_type == GCS_MODE.DRONE_TRACKING) {
    //     store.commit("SetSideDroneTab", true);
    //   } else {
    //     store.commit("SetSideMissionTab", true);
    //     MissionManager.getInstance().SetMissionEntityVisible()
    //   }
    //   viewer.trackedEntity = undefined
    // },
    onClickMissionSet(mission_tool_type: MissionItemType) {
      const Paths = computed(() => MissionManager.getInstance().GetModifyMission())
      if (Paths.value.length == 0) {
        store.commit('SetMissionModeState', MISSION_MODE_STATE.ON)
      } else {
        const Paths_last_type = Paths.value[Paths.value.length - 1]
        if (Paths_last_type.type == MissionItemType.LAND || Paths_last_type.type == MissionItemType.RTL) {
          CustomAlert.getInstance().ShowAlert('Mission update has been completed')
          store.commit('SetMissionModeState', MISSION_MODE_STATE.OFF)
        } else {
          store.commit('SetMissionModeState', MISSION_MODE_STATE.ON)
        }
      }
      if (this.mission_mode_state == MISSION_MODE_STATE.ON || this.mission_mode_state == MISSION_MODE_STATE.STOP) {
        store.commit('SetMissionModeState', MISSION_MODE_STATE.ON)
        const MissionMode = MissionWaypointGeneratingManager.getInstance()
        const mode_set:MissionItemType = MissionWaypointGeneratingManager.getInstance().GetMissionToolType()
        if (mission_tool_type != MissionItemType.WAYPOINT) {
          MissionMode.SetOptsDefault()
        }
        if (mission_tool_type == MissionItemType.NONE) {
          (MissionMode.SetMissionToolType(MissionItemType.NONE), this.functionType = mission_tool_type)
        } else if (mission_tool_type == MissionItemType.WAYPOINT) {
          CesiumScreenSpaceEventService.getInstance().setMissionMode(MissionItemType.WAYPOINT),
          MissionManager.getInstance().SetWaypointBoxState(true), 
          this.functionType = mission_tool_type, 
          MissionMode.SetMissionToolType(MissionItemType.WAYPOINT),
          mode_set != MissionItemType.AT ? MissionMode.SetType(MissionMode.GetGeneratingSet().type) : null
        } else if (mission_tool_type == MissionItemType.TAKEOFF) {
          CesiumScreenSpaceEventService.getInstance().setMissionMode(MissionItemType.TAKEOFF),
          MissionManager.getInstance().SetWaypointBoxState(false), 
          MissionMode.SetMissionToolType(MissionItemType.TAKEOFF), 
          MissionMode.SetType(WaypointGenerateType.LINE)
        } else if (mission_tool_type == MissionItemType.LAND) {
          CesiumScreenSpaceEventService.getInstance().setMissionMode(MissionItemType.LAND),
          MissionManager.getInstance().SetWaypointBoxState(false), 
          MissionMode.SetMissionToolType(MissionItemType.LAND), 
          MissionMode.SetType(WaypointGenerateType.LINE)
        } else if (mission_tool_type == MissionItemType.RTL) {
          CesiumScreenSpaceEventService.getInstance().setMissionMode(MissionItemType.RTL),
          MissionManager.getInstance().SetWaypointBoxState(false), 
          MissionMode.SetMissionToolType(MissionItemType.RTL), 
          MissionMode.SetType(WaypointGenerateType.LINE)
        }
      } else {
        console.log('reject')
      }
    }
  },
});
