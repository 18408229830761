export const StackFaults = [
    
    // 2.4KW PCM : 408 A, B
    {command: 408, flag: 'A', code: 0x80000000, level: 4, tag: 'StartStateTimeOut', des: 'Start State has taken too long (>60s)'},
    {command: 408, flag: 'A', code: 0x40000000, level: 4, tag: 'StopStateTimeOut', des: 'Stop State has taken too long (>120s)'},
    {command: 408, flag: 'A', code: 0x20000000, level: 4, tag: 'PcmPwrCtrlSafety1Indicator', des: 'The safety 1 is not enabled by the PCM when running'},
    {command: 408, flag: 'A', code: 0x10000000, level: 4, tag: 'PcmPwrCtrlSafety2Indicator', des: 'The safety 2 is not enabled by the PCM when running'},
    {command: 408, flag: 'A', code: 0x08000000, level: 4, tag: 'PcmPwrCtrlSafety1DenyStart', des: 'The safety 1 is not enabled by the PCM when Off (this will deny start)'},
    {command: 408, flag: 'A', code: 0x04000000, level: 4, tag: 'PcmPwrCtrlSafety2DenyStart', des: 'The safety 1 is not enabled by the PCM when Off (this will deny start)'},
    {command: 408, flag: 'A', code: 0x02000000, level: 4, tag: 'PcmPowerGoodFaultInOperation', des: 'When running power good not returned from PCM board'},
    {command: 408, flag: 'A', code: 0x01000000, level: 4, tag: 'PcmPowerGoodFaultStartFailed', des: 'Before starting power good not returned from PCM board'},
    {command: 408, flag: 'A', code: 0x00800000, level: 4, tag: 'PomPowerGoodFaultInOperation', des: 'When running power good not returned from POM board'},
    {command: 408, flag: 'A', code: 0x00400000, level: 4, tag: 'PomPowerGoodFaultStartFailed', des: 'Before starting power good not returned from POM board'},
    {command: 408, flag: 'A', code: 0x00200000, level: 4, tag: 'BatteryUVCritical', des: 'Critical Battery Under Voltage (<44.3V)'},
    {command: 408, flag: 'A', code: 0x00100000, level: 4, tag: 'BatteryUVShutdown', des: 'Shutdown Battery Under Voltage (<45.7V)'},
    {command: 408, flag: 'A', code: 0x00080000, level: 4, tag: 'BatteryUVWarning', des: 'Battery voltage below limit (<50.4V)'},
    {command: 408, flag: 'A', code: 0x00040000, level: 4, tag: 'TankUPShutdown', des: 'Tank Under Pressure Fault (<5.0Bar)'},
    {command: 408, flag: 'A', code: 0x00020000, level: 4, tag: 'TankUPDenyStart', des: 'Tank Under Pressure Deny Start (<6.0Bar)'},
    {command: 408, flag: 'A', code: 0x00010000, level: 4, tag: 'TankLowPressure', des: 'Tank Low Pressure Indicator (<15.0Bar)'},
    {command: 408, flag: 'A', code: 0x00008000, level: 4, tag: 'TankOPShutdown', des: 'Tank Over Pressure Fault (>310.0Bar)'},
    {command: 408, flag: 'A', code: 0x00004000, level: 4, tag: 'TankHighPressure', des: 'Tank High Pressure Indicator (>305.0Bar)'},
    {command: 408, flag: 'A', code: 0x00002000, level: 4, tag: 'SafetyPairFault', des: 'Both safety voltages lost from SPM while SPM is not Of'},
    {command: 408, flag: 'A', code: 0x00001000, level: 4, tag: 'PreStartTimeout', des: 'If the system is still waiting for start event after 60s'},
    {command: 408, flag: 'A', code: 0x00000800, level: 4, tag: 'SPM1NotRunning', des: 'This indicates that SPM is not running when requested'},
    {command: 408, flag: 'A', code: 0x00000400, level: 4, tag: 'SPM2NotRunning', des: 'This indicates that SPM is not running when requested'},
    {command: 408, flag: 'A', code: 0x00000200, level: 4, tag: 'ReadConfigFault', des: 'Configuration Fault – failed to read NVM. The I2C memory device has not provided data.'},
    {command: 408, flag: 'A', code: 0x00000100, level: 4, tag: 'CorruptConfigFault', des: 'Configuration Fault – memory corrupt or not set. The CRC of the data from NVM is incorrect'},
    {command: 408, flag: 'A', code: 0x00000080, level: 4, tag: 'ConfigValueRangeFault', des: 'Configuration Fault – value out of range. A configuration value is out of range.'},
    {command: 408, flag: 'A', code: 0x00000040, level: 4, tag: 'PreStartSleepCommand', des: 'The system is off/idle and has been commanded to go to sleep'},
    {command: 408, flag: 'B', code: 0x00000002, level: 4, tag: 'CanInterSysUnexpectedDlc', des: 'CAN Coms Fault'},
    { command: 408, flag: 'B', code: 0x00000001, level: 4, tag: 'EngineeringFault', des: 'This is to capture issues during development' },
    
    // 2.4KW PCM : 410 C, D
    {command: 410, flag: 'C', code: 0x80000000, level: 4, tag: 'CanInterSysRxOverflow', des: 'CAN Coms Fault'},
    {command: 410, flag: 'C', code: 0x40000000, level: 4, tag: 'CanInterSysFailedInitialisation', des: 'CAN Coms Fault'},
    {command: 410, flag: 'C', code: 0x20000000, level: 4, tag: 'CanCustomerUnexpectedDlc', des: 'CAN Coms Fault'},
    {command: 410, flag: 'C', code: 0x10000000, level: 4, tag: 'CanCustomerRxOverflow', des: 'CAN Coms Fault'},
    {command: 410, flag: 'C', code: 0x08000000, level: 4, tag: 'CanCustomerFailedInitialisation', des: 'CAN Coms Fault'},
    {command: 410, flag: 'C', code: 0x04000000, level: 4, tag: 'RtcLseNotReady', des: 'Real time clock fault'},
    {command: 410, flag: 'C', code: 0x02000000, level: 4, tag: 'RtcRegisterFault', des: 'Real time clock fault'},
    {command: 410, flag: 'C', code: 0x01000000, level: 4, tag: 'InitialisedRtc', des: 'Real time clock fault'},
    {command: 410, flag: 'C', code: 0x00800000, level: 4, tag: 'RtcSetFault', des: 'Real time clock fault'},
    {command: 410, flag: 'C', code: 0x00400000, level: 4, tag: 'ReferenceVoltageHigh', des: 'Sensor reference voltage above limit (>3.35V)'},
    {command: 410, flag: 'C', code: 0x00200000, level: 4, tag: 'ReferenceVoltageLow', des: 'Sensor reference voltage above limit (<3.15V)'},
    {command: 410, flag: 'C', code: 0x00100000, level: 4, tag: 'ReferenceVoltageVeryHigh', des: 'Sensor reference voltage high limit (>3.5V)'},
    {command: 410, flag: 'C', code: 0x00080000, level: 4, tag: 'ReferenceVoltageVeryLow', des: 'Sensor reference voltage Low limit (<3.0V)'},
    {command: 410, flag: 'C', code: 0x00040000, level: 4, tag: 'SerialCustomerRxOverflow', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00020000, level: 4, tag: 'SerialCustomerTxOverflow', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00010000, level: 4, tag: 'SerialCustomerRxOverrun', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00008000, level: 4, tag: 'SerialDebugRxOverflow', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00004000, level: 4, tag: 'SerialDebugTxOverflow', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00002000, level: 4, tag: 'SerialDebugRxOverrun', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00001000, level: 4, tag: 'SerialSpm1RxLineLengthOverflow', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00000800, level: 4, tag: 'SerialSpm1RxLineBufferOverflow', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00000400, level: 4, tag: 'SerialSpm1TxBufferOverflow', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00000200, level: 4, tag: 'SerialSpm1TxExtrainterrupt', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00000100, level: 4, tag: 'SerialSpm1RxOverrun', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00000080, level: 4, tag: 'SerialSpm2RxLineLengthOverflow', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00000040, level: 4, tag: 'SerialSpm2RxLineBufferOverflow', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00000020, level: 4, tag: 'SerialSpm2TxBufferOverflow', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00000010, level: 4, tag: 'SerialSpm2TxExtrainterrupt', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00000008, level: 4, tag: 'SerialSpm2RxOverrun', des: 'Serial Coms Fault'},
    {command: 410, flag: 'C', code: 0x00000004, level: 4, tag: 'I2c2DacsIrqCount', des: 'DAC Fault'},
    {command: 410, flag: 'C', code: 0x00000002, level: 4, tag: 'I2c2DacsTimeout', des: 'DAC Fault'},
    {command: 410, flag: 'C', code: 0x00000001, level: 4, tag: 'TurnaroundTimeWarning', des: 'Coms Fault'},
    {command: 410, flag: 'D', code: 0x80000000, level: 4, tag: 'SoftwareTripShutdown', des: 'This is to capture issues during development'},
    {command: 410, flag: 'D', code: 0x40000000, level: 4, tag: 'TurnaroundTimeFault', des: 'Coms Fault'},
    {command: 410, flag: 'D', code: 0x20000000, level: 4, tag: 'Spm1RxCommsLengthFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x10000000, level: 4, tag: 'Spm1RxCyclicCounterFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x08000000, level: 4, tag: 'Spm1RxLineCounterFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x04000000, level: 4, tag: 'Spm1RxShortLengthFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x02000000, level: 4, tag: 'Spm1RxDecodeEncodeFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x01000000, level: 4, tag: 'Spm1RxecodeCrcFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00800000, level: 4, tag: 'Spm2RxCommsLengthFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00400000, level: 4, tag: 'Spm2RxCyclicCounterFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00200000, level: 4, tag: 'Spm2RxLineCounterFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00100000, level: 4, tag: 'Spm2RxShortLengthFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00080000, level: 4, tag: 'Spm2RxDecodeEncodeFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00040000, level: 4, tag: 'Spm2RxecodeCrcFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00020000, level: 4, tag: 'Spm1TxLengthFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00010000, level: 4, tag: 'Spm1TxEncodeFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00008000, level: 4, tag: 'Spm2TxLengthFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00004000, level: 4, tag: 'Spm2TxEncodeFault', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00002000, level: 4, tag: 'BadSpmIdentity', des: 'SPM Coms Fault'},
    {command: 410, flag: 'D', code: 0x00001000, level: 4, tag: 'I2c1AckFailure', des: 'I2C Fault'},
    {command: 410, flag: 'D', code: 0x00000800, level: 4, tag: 'I2c1ArbitrationLost', des: 'I2C Fault'},
    {command: 410, flag: 'D', code: 0x00000400, level: 4, tag: 'I2c1BusError', des: 'I2C Fault'},
    {command: 410, flag: 'D', code: 0x00000200, level: 4, tag: 'I2c1Timeout', des: 'I2C Fault'},
    {command: 410, flag: 'D', code: 0x00000100, level: 4, tag: 'Adc1BufferOverwritten', des: 'ADC Fault'},
    {command: 410, flag: 'D', code: 0x00000080, level: 4, tag: 'Adc1MonitorHighIrqCount', des: 'ADC Fault'},
    {command: 410, flag: 'D', code: 0x00000040, level: 4, tag: 'Adc1MonitorLowIrqCount', des: 'ADC Fault'},
    {command: 410, flag: 'D', code: 0x00000020, level: 4, tag: 'Adc3BufferOverwritten', des: 'ADC Fault'},
    {command: 410, flag: 'D', code: 0x00000010, level: 4, tag: 'Adc3MonitorHighIrqCount', des: 'ADC Fault'},
    {command: 410, flag: 'D', code: 0x00000008, level: 4, tag: 'Adc3MonitorLowIrqCount', des: 'ADC Fault'},
    {command: 410, flag: 'D', code: 0x00000004, level: 4, tag: 'I2c2DacsAckFailure', des: 'I2C Fault'},
    {command: 410, flag: 'D', code: 0x00000002, level: 4, tag: 'I2c2DacsArbitrationLost', des: 'I2C Fault'},
    { command: 410, flag: 'D', code: 0x00000001, level: 4, tag: 'I2c2DacsBusError', des: 'I2C Fault' },
    
    // 2.4KW SPM1 : 418 A, B
    {command: 418, flag: 'A', code: 0x80000000, level: 4, tag: 'StartStateTimeOut', des: 'Start state has taken too long to complete (>60s)'},
    {command: 418, flag: 'A', code: 0x40000000, level: 4, tag: 'StopStateTimeOut', des: 'Stop state has taken too long to complete (>120s)'},
    {command: 418, flag: 'A', code: 0x20000000, level: 4, tag: 'AirFlowPowerFault', des: 'Fan powered but no electrical output (Fan power >5W)'},
    {command: 418, flag: 'A', code: 0x10000000, level: 4, tag: 'StackUnderVoltage', des: 'Fuel inlet open but no volts on stack (Stack Volt <60V)'},
    {command: 418, flag: 'A', code: 0x08000000, level: 4, tag: 'FanDcdcFaultStartFailed', des: 'DCDC status is fault during start state'},
    {command: 418, flag: 'A', code: 0x04000000, level: 4, tag: 'FanDcdcFaultInOperation', des: 'DCDC status is fault during operation state'},
    {command: 418, flag: 'A', code: 0x02000000, level: 4, tag: 'PowerGoodFaultStartFailed', des: 'DCDC status is fault during start state'},
    {command: 418, flag: 'A', code: 0x01000000, level: 4, tag: 'PowerGoodFaultInOperation', des: 'DCDC status is fault during operation state'},
    {command: 418, flag: 'A', code: 0x00800000, level: 4, tag: 'PcmPwrCtrlLowPower1Indicator', des: 'Low power rail 1 is not enabled'},
    {command: 418, flag: 'A', code: 0x00400000, level: 4, tag: 'PcmPwrCtrlLowPower2Indicator', des: 'Low power rail 2 is not enabled'},
    {command: 418, flag: 'A', code: 0x00200000, level: 4, tag: 'PcmPwrCtrlSafety1Indicator', des: 'Safety 1 is not enabled'},
    {command: 418, flag: 'A', code: 0x00100000, level: 4, tag: 'PcmPwrCtrlSafety2Indicator', des: 'Safety 2 is not enabled'},
    {command: 418, flag: 'A', code: 0x00080000, level: 4, tag: 'PcmPwrCtrlLowPower1DenyStart', des: 'Low power rail 1 is not enabled, before start command'},
    {command: 418, flag: 'A', code: 0x00040000, level: 4, tag: 'PcmPwrCtrlLowPower2DenyStart', des: 'Low power rail 2 is not enabled, before start command'},
    {command: 418, flag: 'A', code: 0x00020000, level: 4, tag: 'PcmPwrCtrlSafety1DenyStart', des: 'Safety 1 is not enabled, before start command'},
    {command: 418, flag: 'A', code: 0x00010000, level: 4, tag: 'PcmPwrCtrlSafety2DenyStart', des: 'Safety 2 is not enabled, before start command'},
    {command: 418, flag: 'A', code: 0x00008000, level: 4, tag: 'FanPulseLoadOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 418, flag: 'A', code: 0x00004000, level: 4, tag: 'StackSensor1OverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 418, flag: 'A', code: 0x00002000, level: 4, tag: 'StackSensor2OverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 418, flag: 'A', code: 0x00001000, level: 4, tag: 'negativeEndPlateHeaterOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 418, flag: 'A', code: 0x00000800, level: 4, tag: 'PositiveEndPlateHeaterOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 418, flag: 'A', code: 0x00000400, level: 4, tag: 'FanDcdcEnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 418, flag: 'A', code: 0x00000200, level: 4, tag: 'FanPulseLoadEnableOutputCheck', des: 'Digital output tell-back not the same as set (externally held: processor not powerful enough to set voltage correctly)'},
    {command: 418, flag: 'A', code: 0x00000100, level: 4, tag: 'LoadDcdcEnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 418, flag: 'A', code: 0x00000080, level: 4, tag: 'StackTxSensor1Fault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 418, flag: 'A', code: 0x00000040, level: 4, tag: 'StackTxSensor2Fault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 418, flag: 'A', code: 0x00000020, level: 4, tag: 'NegativeEndPlateTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 418, flag: 'A', code: 0x00000010, level: 4, tag: 'PositiveEndPlateTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 418, flag: 'A', code: 0x00000008, level: 4, tag: 'AirInletTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 418, flag: 'A', code: 0x00000004, level: 4, tag: 'DisableBatteryOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 418, flag: 'A', code: 0x00000002, level: 4, tag: 'DisableExternalOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 418, flag: 'A', code: 0x00000001, level: 4, tag: 'EnableStackOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 418, flag: 'B', code: 0x80000000, level: 4, tag: 'StackTxSensor1OverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 418, flag: 'B', code: 0x40000000, level: 4, tag: 'StackTxSensor2OverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 418, flag: 'B', code: 0x20000000, level: 4, tag: 'NegativeEndPlateTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 418, flag: 'B', code: 0x10000000, level: 4, tag: 'PositiveEndPlateTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 418, flag: 'B', code: 0x08000000, level: 4, tag: 'AirInletTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 418, flag: 'B', code: 0x04000000, level: 4, tag: 'Rail5V1EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 418, flag: 'B', code: 0x02000000, level: 4, tag: 'Rail5V2EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 418, flag: 'B', code: 0x01000000, level: 4, tag: 'Rail12V1EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 418, flag: 'B', code: 0x00800000, level: 4, tag: 'Rail12V2EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 418, flag: 'B', code: 0x00400000, level: 4, tag: 'StackOverVoltage', des: 'Stack voltage above threshold (>80V)'},
    {command: 418, flag: 'B', code: 0x00200000, level: 4, tag: 'StackUnderVoltage', des: 'Stack voltage below threshold when fuel on and not POC (<36V)'},
    {command: 418, flag: 'B', code: 0x00100000, level: 4, tag: 'StackOutputOverCurrent', des: 'Stack output current above DCDC input max current (>40A)'},
    {command: 418, flag: 'B', code: 0x00080000, level: 4, tag: 'UnexpectedStackOutputCurrent', des: 'Stack output current above threshold when the DCDC is not enabled (>3A)'},
    {command: 418, flag: 'B', code: 0x00040000, level: 4, tag: 'FanPulseOverCurrent', des: 'Current to POC load resistor above threshold (>250A)'},
    {command: 418, flag: 'B', code: 0x00020000, level: 4, tag: 'UnexpectedFanPulseCurrent', des: 'POC load resistor current above threshold when the load resistor is not enabled (>3A)'},
    {command: 418, flag: 'B', code: 0x00010000, level: 4, tag: 'LoadDcdcOutputOverVoltage', des: 'Output voltage is above threshold (>85A)'},
    {command: 418, flag: 'B', code: 0x00008000, level: 4, tag: 'LoadDcdcOutputControlLost', des: 'Output voltage is significantly above set point (>3A than setpoint)'},
    {command: 418, flag: 'B', code: 0x00004000, level: 4, tag: 'LoadDcdc1OuputCheck', des: 'SPM 1 Output voltage below setpoint (difference >2V) AND output current is below limit (difference >3A). Implies a DCDC output power fault'},
    {command: 418, flag: 'B', code: 0x00002000, level: 4, tag: 'LoadDcdc2OuputCheck', des: 'SPM2 Output voltage below setpoint (difference >2V) AND output current is below limit (difference >3A). Implies a DCDC output power fault'},
    {command: 418, flag: 'B', code: 0x00001000, level: 4, tag: 'BalanceOfPlantUVWarningA', des: 'BOP voltage below limit (<35V)'},
    {command: 418, flag: 'B', code: 0x00000800, level: 4, tag: 'BalanceOfPlantUVDenyStart', des: 'BOP Under Voltage before start command (<35V) (this will deny start)'},
    {command: 418, flag: 'B', code: 0x00000400, level: 4, tag: 'BalanceOfPlantUVWarningB', des: 'BOP Under Voltage warning flag (<30V)'},
    {command: 418, flag: 'B', code: 0x00000200, level: 4, tag: 'FanEscTelemetryCrcFault', des: 'Fan ESC telemetry fault'},
    {command: 418, flag: 'B', code: 0x00000100, level: 4, tag: 'FanEscTelemetryNoMessages', des: 'No communications from the fan ESC'},
    {command: 418, flag: 'B', code: 0x00000080, level: 4, tag: 'HighFanPulseCurrentAbort', des: 'POC aborted due to higher than expected current (<45V)'},
    {command: 418, flag: 'B', code: 0x00000040, level: 4, tag: 'SafetyPairFault', des: 'Both safety voltages lost from SPM while SPM is operating'},
    {command: 418, flag: 'B', code: 0x00000020, level: 4, tag: 'SelfArbitrate', des: 'Communications from PCM lost'},
    {command: 418, flag: 'B', code: 0x00000010, level: 4, tag: 'PcmCommsLostShutdown', des: 'Cyclic Counter lost'},
    {command: 418, flag: 'B', code: 0x00000008, level: 4, tag: 'FanEscTelemetryStartFailed', des: 'Esc Telemetry failed during start up'},
    {command: 418, flag: 'B', code: 0x00000004, level: 4, tag: 'MaxCooling', des: 'Fans are at their maximum set point'},
    { command: 418, flag: 'B', code: 0x00000001, level: 4, tag: 'EngineeringFault', des: 'Development warning flag' },
    
    // 2.4KW SPM1 : 420 C, D
    {command: 420, flag: 'D', code: 0x80000000, level: 4, tag: 'SoftwareTripShutdown', des: 'Software Shutdown'},
    {command: 420, flag: 'D', code: 0x40000000, level: 4, tag: 'Adc12BufferOverwritten', des: 'ADC Buffer fault'},
    {command: 420, flag: 'D', code: 0x20000000, level: 4, tag: 'PcmRxCommsLengthFault', des: 'PCM Coms Fault'},
    {command: 420, flag: 'D', code: 0x10000000, level: 4, tag: 'PcmRxCyclicCounterFault', des: 'PCM Coms Fault'},
    {command: 420, flag: 'D', code: 0x08000000, level: 4, tag: 'PcmRxLineCounterFault', des: 'PCM Coms Fault'},
    {command: 420, flag: 'D', code: 0x04000000, level: 4, tag: 'PcmRxShortLengthFault', des: 'PCM Coms Fault'},
    {command: 420, flag: 'D', code: 0x02000000, level: 4, tag: 'PcmRxDecodeEncodeFault', des: 'PCM Coms Fault'},
    {command: 420, flag: 'D', code: 0x01000000, level: 4, tag: 'PcmRxecodeCrcFault', des: 'PCM Coms Fault'},
    {command: 420, flag: 'D', code: 0x00800000, level: 4, tag: 'PcmTxLengthFault', des: 'PCM Coms Fault'},
    {command: 420, flag: 'D', code: 0x00400000, level: 4, tag: 'PcmTxEncodeFault', des: 'PCM Coms Fault'},
    {command: 420, flag: 'D', code: 0x00200000, level: 4, tag: 'I2c1AckFailure', des: 'I2C fault'},
    {command: 420, flag: 'D', code: 0x00100000, level: 4, tag: 'I2c1ArbitrationLost', des: 'I2C fault'},
    {command: 420, flag: 'D', code: 0x00080000, level: 4, tag: 'I2c1BusError', des: 'I2C fault'},
    {command: 420, flag: 'D', code: 0x00040000, level: 4, tag: 'I2c1Timeout', des: 'I2C fault'},
    {command: 420, flag: 'D', code: 0x00020000, level: 4, tag: 'Adc12MonitorHighIrqCount', des: 'ADC High fault'},
    {command: 420, flag: 'D', code: 0x00010000, level: 4, tag: 'Adc12MonitorLowIrqCount', des: 'ADC Low fault'},
    {command: 420, flag: 'D', code: 0x00008000, level: 4, tag: 'I2c2DacsAckFailure', des: 'I2C fault'},
    {command: 420, flag: 'D', code: 0x00004000, level: 4, tag: 'I2c2DacsArbitrationLost', des: 'I2C fault'},
    {command: 420, flag: 'D', code: 0x00002000, level: 4, tag: 'I2c2DacsBusError', des: 'I2C fault'},
    {command: 420, flag: 'D', code: 0x00001000, level: 4, tag: 'I2c2DacsIrqCount', des: 'I2C fault'},
    {command: 420, flag: 'D', code: 0x00000800, level: 4, tag: 'I2c2DacsTimeout', des: 'I2C fault'},
    {command: 420, flag: 'D', code: 0x00000400, level: 4, tag: 'TurnaroundTimeWarning', des: 'Coms Fault'},
    {command: 420, flag: 'D', code: 0x00000200, level: 4, tag: 'TurnaroundTimeFault', des: 'Coms Fault'},
    {command: 420, flag: 'D', code: 0x00000100, level: 4, tag: 'Adc3BufferOverwritten', des: 'ADC Buffer fault'},
    {command: 420, flag: 'D', code: 0x00000080, level: 4, tag: 'Adc3MonitorHighIrqCount', des: 'ADC High fault'},
    {command: 420, flag: 'D', code: 0x00000040, level: 4, tag: 'Adc3MonitorLowIrqCount', des: 'ADC Low fault'},
    {command: 420, flag: 'D', code: 0x00000020, level: 4, tag: 'ReferenceVoltageHigh', des: 'Sensor reference voltage above limit (>3.35V)'},
    {command: 420, flag: 'D', code: 0x00000010, level: 4, tag: 'ReferenceVoltageLow', des: 'Sensor reference voltage above limit (<3.15V)'},
    {command: 420, flag: 'D', code: 0x00000008, level: 4, tag: 'ReferenceVoltageVeryHigh', des: 'Sensor reference voltage high limit (>3.5V)'},
    {command: 420, flag: 'D', code: 0x00000004, level: 4, tag: 'ReferenceVoltageVeryLow', des: 'Sensor reference voltage Low limit (<3.0V)'},

    // 2.4KW SPM2 : 428 A, B
    {command: 428, flag: 'A', code: 0x80000000, level: 4, tag: 'StartStateTimeOut', des: 'Start state has taken too long to complete (>60s)'},
    {command: 428, flag: 'A', code: 0x40000000, level: 4, tag: 'StopStateTimeOut', des: 'Stop state has taken too long to complete (>120s)'},
    {command: 428, flag: 'A', code: 0x20000000, level: 4, tag: 'AirFlowPowerFault', des: 'Fan powered but no electrical output (Fan power >5W)'},
    {command: 428, flag: 'A', code: 0x10000000, level: 4, tag: 'StackUnderVoltage', des: 'Fuel inlet open but no volts on stack (Stack Volt <60V)'},
    {command: 428, flag: 'A', code: 0x08000000, level: 4, tag: 'FanDcdcFaultStartFailed', des: 'DCDC status is fault during start state'},
    {command: 428, flag: 'A', code: 0x04000000, level: 4, tag: 'FanDcdcFaultInOperation', des: 'DCDC status is fault during operation state'},
    {command: 428, flag: 'A', code: 0x02000000, level: 4, tag: 'PowerGoodFaultStartFailed', des: 'DCDC status is fault during start state'},
    {command: 428, flag: 'A', code: 0x01000000, level: 4, tag: 'PowerGoodFaultInOperation', des: 'DCDC status is fault during operation state'},
    {command: 428, flag: 'A', code: 0x00800000, level: 4, tag: 'PcmPwrCtrlLowPower1Indicator', des: 'Low power rail 1 is not enabled'},
    {command: 428, flag: 'A', code: 0x00400000, level: 4, tag: 'PcmPwrCtrlLowPower2Indicator', des: 'Low power rail 2 is not enabled'},
    {command: 428, flag: 'A', code: 0x00200000, level: 4, tag: 'PcmPwrCtrlSafety1Indicator', des: 'Safety 1 is not enabled'},
    {command: 428, flag: 'A', code: 0x00100000, level: 4, tag: 'PcmPwrCtrlSafety2Indicator', des: 'Safety 2 is not enabled'},
    {command: 428, flag: 'A', code: 0x00080000, level: 4, tag: 'PcmPwrCtrlLowPower1DenyStart', des: 'Low power rail 1 is not enabled, before start command'},
    {command: 428, flag: 'A', code: 0x00040000, level: 4, tag: 'PcmPwrCtrlLowPower2DenyStart', des: 'Low power rail 2 is not enabled, before start command'},
    {command: 428, flag: 'A', code: 0x00020000, level: 4, tag: 'PcmPwrCtrlSafety1DenyStart', des: 'Safety 1 is not enabled, before start command'},
    {command: 428, flag: 'A', code: 0x00010000, level: 4, tag: 'PcmPwrCtrlSafety2DenyStart', des: 'Safety 2 is not enabled, before start command'},
    {command: 428, flag: 'A', code: 0x00008000, level: 4, tag: 'FanPulseLoadOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 428, flag: 'A', code: 0x00004000, level: 4, tag: 'StackSensor1OverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 428, flag: 'A', code: 0x00002000, level: 4, tag: 'StackSensor2OverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 428, flag: 'A', code: 0x00001000, level: 4, tag: 'negativeEndPlateHeaterOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 428, flag: 'A', code: 0x00000800, level: 4, tag: 'PositiveEndPlateHeaterOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 428, flag: 'A', code: 0x00000400, level: 4, tag: 'FanDcdcEnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 428, flag: 'A', code: 0x00000200, level: 4, tag: 'FanPulseLoadEnableOutputCheck', des: 'Digital output tell-back not the same as set (externally held: processor not powerful enough to set voltage correctly)'},
    {command: 428, flag: 'A', code: 0x00000100, level: 4, tag: 'LoadDcdcEnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 428, flag: 'A', code: 0x00000080, level: 4, tag: 'StackTxSensor1Fault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 428, flag: 'A', code: 0x00000040, level: 4, tag: 'StackTxSensor2Fault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 428, flag: 'A', code: 0x00000020, level: 4, tag: 'NegativeEndPlateTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 428, flag: 'A', code: 0x00000010, level: 4, tag: 'PositiveEndPlateTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 428, flag: 'A', code: 0x00000008, level: 4, tag: 'AirInletTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 428, flag: 'A', code: 0x00000004, level: 4, tag: 'DisableBatteryOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 428, flag: 'A', code: 0x00000002, level: 4, tag: 'DisableExternalOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 428, flag: 'A', code: 0x00000001, level: 4, tag: 'EnableStackOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 428, flag: 'B', code: 0x80000000, level: 4, tag: 'StackTxSensor1OverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 428, flag: 'B', code: 0x40000000, level: 4, tag: 'StackTxSensor2OverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 428, flag: 'B', code: 0x20000000, level: 4, tag: 'NegativeEndPlateTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 428, flag: 'B', code: 0x10000000, level: 4, tag: 'PositiveEndPlateTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 428, flag: 'B', code: 0x08000000, level: 4, tag: 'AirInletTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 428, flag: 'B', code: 0x04000000, level: 4, tag: 'Rail5V1EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 428, flag: 'B', code: 0x02000000, level: 4, tag: 'Rail5V2EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 428, flag: 'B', code: 0x01000000, level: 4, tag: 'Rail12V1EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 428, flag: 'B', code: 0x00800000, level: 4, tag: 'Rail12V2EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 428, flag: 'B', code: 0x00400000, level: 4, tag: 'StackOverVoltage', des: 'Stack voltage above threshold (>80V)'},
    {command: 428, flag: 'B', code: 0x00200000, level: 4, tag: 'StackUnderVoltage', des: 'Stack voltage below threshold when fuel on and not POC (<36V)'},
    {command: 428, flag: 'B', code: 0x00100000, level: 4, tag: 'StackOutputOverCurrent', des: 'Stack output current above DCDC input max current (>40A)'},
    {command: 428, flag: 'B', code: 0x00080000, level: 4, tag: 'UnexpectedStackOutputCurrent', des: 'Stack output current above threshold when the DCDC is not enabled (>3A)'},
    {command: 428, flag: 'B', code: 0x00040000, level: 4, tag: 'FanPulseOverCurrent', des: 'Current to POC load resistor above threshold (>250A)'},
    {command: 428, flag: 'B', code: 0x00020000, level: 4, tag: 'UnexpectedFanPulseCurrent', des: 'POC load resistor current above threshold when the load resistor is not enabled (>3A)'},
    {command: 428, flag: 'B', code: 0x00010000, level: 4, tag: 'LoadDcdcOutputOverVoltage', des: 'Output voltage is above threshold (>85A)'},
    {command: 428, flag: 'B', code: 0x00008000, level: 4, tag: 'LoadDcdcOutputControlLost', des: 'Output voltage is significantly above set point (>3A than setpoint)'},
    {command: 428, flag: 'B', code: 0x00004000, level: 4, tag: 'LoadDcdc1OuputCheck', des: 'SPM 1 Output voltage below setpoint (difference >2V) AND output current is below limit (difference >3A). Implies a DCDC output power fault'},
    {command: 428, flag: 'B', code: 0x00002000, level: 4, tag: 'LoadDcdc2OuputCheck', des: 'SPM2 Output voltage below setpoint (difference >2V) AND output current is below limit (difference >3A). Implies a DCDC output power fault'},
    {command: 428, flag: 'B', code: 0x00001000, level: 4, tag: 'BalanceOfPlantUVWarningA', des: 'BOP voltage below limit (<35V)'},
    {command: 428, flag: 'B', code: 0x00000800, level: 4, tag: 'BalanceOfPlantUVDenyStart', des: 'BOP Under Voltage before start command (<35V) (this will deny start)'},
    {command: 428, flag: 'B', code: 0x00000400, level: 4, tag: 'BalanceOfPlantUVWarningB', des: 'BOP Under Voltage warning flag (<30V)'},
    {command: 428, flag: 'B', code: 0x00000200, level: 4, tag: 'FanEscTelemetryCrcFault', des: 'Fan ESC telemetry fault'},
    {command: 428, flag: 'B', code: 0x00000100, level: 4, tag: 'FanEscTelemetryNoMessages', des: 'No communications from the fan ESC'},
    {command: 428, flag: 'B', code: 0x00000080, level: 4, tag: 'HighFanPulseCurrentAbort', des: 'POC aborted due to higher than expected current (<45V)'},
    {command: 428, flag: 'B', code: 0x00000040, level: 4, tag: 'SafetyPairFault', des: 'Both safety voltages lost from SPM while SPM is operating'},
    {command: 428, flag: 'B', code: 0x00000020, level: 4, tag: 'SelfArbitrate', des: 'Communications from PCM lost'},
    {command: 428, flag: 'B', code: 0x00000010, level: 4, tag: 'PcmCommsLostShutdown', des: 'Cyclic Counter lost'},
    {command: 428, flag: 'B', code: 0x00000008, level: 4, tag: 'FanEscTelemetryStartFailed', des: 'Esc Telemetry failed during start up'},
    {command: 428, flag: 'B', code: 0x00000004, level: 4, tag: 'MaxCooling', des: 'Fans are at their maximum set point'},
    {command: 428, flag: 'B', code: 0x00000001, level: 4, tag: 'EngineeringFault', des: 'Development warning flag'},

    // 2.4KW SPM2 : 430 C, D
    {command: 430, flag: 'D', code: 0x80000000, level: 4, tag: 'SoftwareTripShutdown', des: 'Software Shutdown'},
    {command: 430, flag: 'D', code: 0x40000000, level: 4, tag: 'Adc12BufferOverwritten', des: 'ADC Buffer fault'},
    {command: 430, flag: 'D', code: 0x20000000, level: 4, tag: 'PcmRxCommsLengthFault', des: 'PCM Coms Fault'},
    {command: 430, flag: 'D', code: 0x10000000, level: 4, tag: 'PcmRxCyclicCounterFault', des: 'PCM Coms Fault'},
    {command: 430, flag: 'D', code: 0x08000000, level: 4, tag: 'PcmRxLineCounterFault', des: 'PCM Coms Fault'},
    {command: 430, flag: 'D', code: 0x04000000, level: 4, tag: 'PcmRxShortLengthFault', des: 'PCM Coms Fault'},
    {command: 430, flag: 'D', code: 0x02000000, level: 4, tag: 'PcmRxDecodeEncodeFault', des: 'PCM Coms Fault'},
    {command: 430, flag: 'D', code: 0x01000000, level: 4, tag: 'PcmRxecodeCrcFault', des: 'PCM Coms Fault'},
    {command: 430, flag: 'D', code: 0x00800000, level: 4, tag: 'PcmTxLengthFault', des: 'PCM Coms Fault'},
    {command: 430, flag: 'D', code: 0x00400000, level: 4, tag: 'PcmTxEncodeFault', des: 'PCM Coms Fault'},
    {command: 430, flag: 'D', code: 0x00200000, level: 4, tag: 'I2c1AckFailure', des: 'I2C fault'},
    {command: 430, flag: 'D', code: 0x00100000, level: 4, tag: 'I2c1ArbitrationLost', des: 'I2C fault'},
    {command: 430, flag: 'D', code: 0x00080000, level: 4, tag: 'I2c1BusError', des: 'I2C fault'},
    {command: 430, flag: 'D', code: 0x00040000, level: 4, tag: 'I2c1Timeout', des: 'I2C fault'},
    {command: 430, flag: 'D', code: 0x00020000, level: 4, tag: 'Adc12MonitorHighIrqCount', des: 'ADC High fault'},
    {command: 430, flag: 'D', code: 0x00010000, level: 4, tag: 'Adc12MonitorLowIrqCount', des: 'ADC Low fault'},
    {command: 430, flag: 'D', code: 0x00008000, level: 4, tag: 'I2c2DacsAckFailure', des: 'I2C fault'},
    {command: 430, flag: 'D', code: 0x00004000, level: 4, tag: 'I2c2DacsArbitrationLost', des: 'I2C fault'},
    {command: 430, flag: 'D', code: 0x00002000, level: 4, tag: 'I2c2DacsBusError', des: 'I2C fault'},
    {command: 430, flag: 'D', code: 0x00001000, level: 4, tag: 'I2c2DacsIrqCount', des: 'I2C fault'},
    {command: 430, flag: 'D', code: 0x00000800, level: 4, tag: 'I2c2DacsTimeout', des: 'I2C fault'},
    {command: 430, flag: 'D', code: 0x00000400, level: 4, tag: 'TurnaroundTimeWarning', des: 'Coms Fault'},
    {command: 430, flag: 'D', code: 0x00000200, level: 4, tag: 'TurnaroundTimeFault', des: 'Coms Fault'},
    {command: 430, flag: 'D', code: 0x00000100, level: 4, tag: 'Adc3BufferOverwritten', des: 'ADC Buffer fault'},
    {command: 430, flag: 'D', code: 0x00000080, level: 4, tag: 'Adc3MonitorHighIrqCount', des: 'ADC High fault'},
    {command: 430, flag: 'D', code: 0x00000040, level: 4, tag: 'Adc3MonitorLowIrqCount', des: 'ADC Low fault'},
    {command: 430, flag: 'D', code: 0x00000020, level: 4, tag: 'ReferenceVoltageHigh', des: 'Sensor reference voltage above limit (>3.35V)'},
    {command: 430, flag: 'D', code: 0x00000010, level: 4, tag: 'ReferenceVoltageLow', des: 'Sensor reference voltage above limit (<3.15V)'},
    {command: 430, flag: 'D', code: 0x00000008, level: 4, tag: 'ReferenceVoltageVeryHigh', des: 'Sensor reference voltage high limit (>3.5V)'},
    {command: 430, flag: 'D', code: 0x00000004, level: 4, tag: 'ReferenceVoltageVeryLow', des: 'Sensor reference voltage Low limit (<3.0V)'},
]