import { HG_DroneState } from "../ws/ws_hg_server_packet_interface"

// eslint-disable-next-line
export let DroneModel:HG_DroneState = {
    _attitude: {
      _pitch: 0,
      _pitchspeed: 0,
      _roll: 0,
      _rollspeed: 0,
      _yaw: 0,
      _yawspeed: 0,
    },
    _avoidance_State: {
      _coordinate: {
        _alt: 0,
        _lat: 0,
        _lon: 0,
      },
      _state: 0,
    },
    _battery_status: {
      _battery_function: 0,
      _battery_remaining: 0,
      _charge_state: 0,
      _current_battery: 0,
      _current_consumed: 0,
      _energy_consumed: 0,
      _fault_bitmask: 0,
      _id: 0,
      _mode: 0,
      _temperature: 0,
      _time_remaining: 0,
      _type: 0,
      _voltages1: 0,
      _voltages2: 0,
      _voltages3: 0,
      _voltages4: 0,
      _voltages5: 0,
      _voltages6: 0,
      _voltages7: 0,
      _voltages8: 0,
      _voltages9: 0,
      _voltages10: 0,
      _voltages_ext1: 0,
      _voltages_ext2: 0,
      _voltages_ext3: 0,
      _voltages_ext4: 0,
    },
    _gps_int: {
      _alt: 0,
      _hdg: 0,
      _lat: 0,
      _lon: 0,
      _relative_alt: 0,
      _vx: 0,
      _vy: 0,
      _vz: 0,
    },
    _gps_raw_int: {
      _alt: 0,
      _alt_ellipsoid: 0,
      _cog: 0,
      _eph: 0,
      _epv: 0,
      _fix_type: 0,
      _h_acc: 0,
      _hdg_acc: 0,
      _lat: 0,
      _lon: 0,
      _satellites_visible: 0,
      _v_acc: 0,
      _vel: 0,
      _vel_acc: 0,
      _yaw: 0,
    },
    _heartbeat: {
      _autopilot: 0,
      _base_mode: 0,
      _custom_mode: 0,
      _mavlink_version: 0,
      _system_status: 0,
      _type: 0,
    },
    _status_text: {
      _chunk_seq: 0,
      _id: 0,
      _severity: 0,
      _text: '',
    },
    _vfr_hud: {
      _airspeed: 0,
      _alt: 0,
      _climb: 0,
      _groundspeed: 0,
      _heading: 0,
      _throttle: 0,
    },
    alt_tracking: [
      {
        _detect: 1,
        _coordinate_alt: {
          _lat: 0,
          _lon: 0,
          _alt: 0,
          _relative_alt: 0,
          _abs_alt: 0,
          _abs_ellipsoid: 0,
        }
      },
      {
        _detect: 0,
        _coordinate_alt: {
          _lat: 0,
          _lon: 0,
          _alt: 0,
          _relative_alt: 0,
          _abs_alt: 0,
          _abs_ellipsoid: 0,
        }
      }
    ]
  }