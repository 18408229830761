import { ST_LOG_FILE_INFO } from "@/services/report/LogFileManager";
import { HG_DroneState } from "@/services/ws/ws_hg_server_packet_interface";
import store from "@/store";
import axios from "axios";


export interface Group_Log {
    fc?:any[],
    video?:Video_Log[],
    hydrogen?:ST_LOG_FILE_INFO[],
}

export interface Video_Log {
    birth_time: string,
    device_serial: string,
    drone_conn_time: number,
    idx: number,
    stream_url: string,
    thumbnail_url: string,
    video_length: string,
}

export interface FC_Log {
    __v: number,
    _datetime: string,
    _device_serial: string,
    _id: string,
    _status: HG_DroneState,
}

export interface FC_CSV_FILE {
    _date: string,
    _altitude: number,
    _longitude: number,
    _latitude: number,
    _pitch: number,
    _roll: number,
    _yaw: number,
}

export interface CHRAT_SERIES_FORM {
    x: Date,
    y: number
}
class LogDataManager {
    private static instance: LogDataManager;

    private log_data_state:boolean;
    private group_log_list:Group_Log[];
    private video_log_data:Video_Log[];
    private fc_log_data:any[];
    private sorted_h2_log_list:ST_LOG_FILE_INFO[];
    private fc_csv_data:FC_CSV_FILE[];

    public static getInstance() {
        return this.instance || (this.instance = new this());
    }

    constructor() {
        this.log_data_state = false
        this.group_log_list = []
        this.video_log_data = []
        this.fc_log_data = []
        this.sorted_h2_log_list = []
        this.fc_csv_data = []
    }

    SetLogDataState(state:boolean) {
        this.log_data_state = state
    }
    GetLogDataState() {
        return this.log_data_state
    }

    SetAiImage() {
        console.log('SetLogManager')
    }
    AxiosVideoLogData() {
        if(this.video_log_data) {
            null
            console.log('Axios res False', this.video_log_data)
            return this.video_log_data
        } else {
            axios.get(`/vms/list/D-4`).then((res) => {
                this.video_log_data = res.data
                console.log('Axios res True')
                return this.video_log_data
            })
        }
    }

    async AsyncLogDataGroup(drone_name:string, start_date:string, end_date:string) {

        this.sorted_h2_log_list = []
        this.video_log_data = []

        const setStartDate_Time = new Date(start_date).getTime()
        const setEndDate_Time = new Date(end_date).getTime()
        
        const h2_log_list = store.getters.GetH2LogFileList

        await h2_log_list.forEach((list:ST_LOG_FILE_INFO) => {

            const setH2LogDate_Time = new Date(list.connect_datetime).getTime()

            // if(drone_name == list.device_serial) {
            if(list.device_serial == drone_name) { // TEST때문에 ED-00000002 넣어둠
                if(setH2LogDate_Time >= setStartDate_Time && setH2LogDate_Time <= setEndDate_Time) {
                    this.sorted_h2_log_list.push(list)
                }
            }
        })
        await axios.get(`/gcs/drone/${drone_name}/${start_date}/${end_date}`).then((res) => {
            this.fc_log_data = this.SetFcDataParse(res.data.data)
        })
        // await axios.get(`/vms/list/${drone_name}`).then((res) => {
        await axios.get(`/vms/list/${drone_name}`).then((res) => {
            const video_log_data:Video_Log[] = res.data
            video_log_data.forEach((data:Video_Log) => {
                const video_time = new Date(data.birth_time).getTime()
                if(video_time >= setStartDate_Time && video_time <= setEndDate_Time) {
                    this.video_log_data.push(data)
                }
            })
        })
        
        const LogDataGroup:Group_Log = {}
        this.fc_log_data.length != 0 ? LogDataGroup.fc = this.fc_log_data : null
        this.video_log_data.length != 0 ? LogDataGroup.video = this.video_log_data : null
        this.sorted_h2_log_list.length != 0 ? LogDataGroup.hydrogen = this.sorted_h2_log_list : null

        console.log('AsyncLogDataGroup', LogDataGroup, start_date)
        return LogDataGroup
    }


    SetFcDataParse(fc_data:FC_Log[]) {
        fc_data.forEach((data:FC_Log)=>{
            const FC_CSV:FC_CSV_FILE = {
                _date: data._datetime,
                _altitude:Number((data._status._gps_int._alt/1000).toFixed(3)),
                _latitude:Number((data._status._gps_int._lat/1e7).toFixed(5)),
                _longitude:Number((data._status._gps_int._lon/1e7).toFixed(5)),
                _pitch:Number(((data._status._attitude._pitch*180)/Math.PI).toFixed(2)),
                _roll:Number(((data._status._attitude._roll*180)/Math.PI).toFixed(2)),
                _yaw:Number(((data._status._attitude._yaw*180)/Math.PI).toFixed(2)), 
            }
            this.fc_csv_data.push(FC_CSV)     
        })
        return this.fc_csv_data
    }

    
    DownloadCSV(fc_data:FC_CSV_FILE[]) {
        const file_name = `FC_LogFile.csv`;
        const csv = [];
        let row = [];
        row.push( "Date", "Altitude", "Latitude", "Longitude", "Pitch", "Roll", "Yaw" );
        csv.push(row.join(","));
        $.each(fc_data, function (index, data) {
          row = [];
          row.push( data._date, data._altitude, data._latitude, data._longitude, data._pitch, data._roll, data._yaw,);
          csv.push(row.join(","));
        });

        const set_csv = csv.join("\n")

        const BOM = "\uFEFF"; //한글 처리를 해주기 위해 BOM 추가하기
        const set2_csv = BOM + set_csv;
      
        const csvFile = new Blob([set2_csv], { type: "text/csv" });
        const downloadLink = document.createElement("a");
        downloadLink.download = file_name;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
}

export default LogDataManager;
