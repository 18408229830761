
class ScreenManager {
    private static instance: ScreenManager;

    private z_index_number:number;

    public static getInstance() {
        return this.instance || (this.instance = new this());
    }

    constructor() {
        this.z_index_number = 0;
    }

    SetZ_Index(name:string) {
      const setZindex = document.getElementById(name)
      if(setZindex) {
        this.z_index_number = this.z_index_number + 1
        setZindex.style.zIndex = `${this.z_index_number}`
      }
    }
}

export default ScreenManager;
