
import store from "@/store";
import { computed, defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            show: false,
        };
    },
    setup() {
        // computed로 감싸줘야 해당 변수가 지속적으로 업데이트 됨
        const alert_data = computed(() => store.getters.GetAlertData);

        const text = computed(() => alert_data.value[0]);
        const position = computed(() => alert_data.value[1]);
        const color = computed(() => alert_data.value[2]);
        const timeout = computed(() => alert_data.value[3]);

        return { alert_data, text, position, color, timeout };
    },
    watch: {
        alert_data: {
            handler(n) {
                this.show = true
            },
        },
    },
});
