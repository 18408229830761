
import store from "@/store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      select: "Heavy Equipment",
    };
  },

  setup() {
    const gcs_guide_state = computed(() => store.getters.GetGcsGuideState);
    return { gcs_guide_state };
  },
  watch: {
    group() {
      this.gcs_guide_state = false;
    },
  },
  methods: {
    CloseBtn() {
      store.commit("SetGcsGuideState", false);
    },
    onClickfunction(data: any) {
      this.select = data;
    },
  },
});
