
import CESIUM_DRONE from "@/services/hg/cesium_drone";
import store from "@/store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      log_message_list : [] as string[]|undefined,
      log_state : false,
      log_time : 0,
    };
  },
  setup() {
    const log_message_state = computed(() => store.getters.GetLogMessageState);
    const single_drone = computed<CESIUM_DRONE|undefined>(() => store.getters.GetSingleDrone);
    return { log_message_state, single_drone };
  },
  mounted() {
    setInterval(() => {
      if(this.single_drone) {
        if(this.single_drone.GetState()?._status_text._text.length != 0) {
          this.log_time = 0
          const LogSplit:string[]|undefined = this.single_drone.GetState()?._status_text._text.split('\n')
          if(LogSplit) {
            this.log_state = true,
            this.log_message_list = LogSplit
          }
        } else {
          this.log_time = this.log_time + 1
          if(this.log_time >= 60){
            this.log_message_list = [];
            this.log_state = false
          }
        }
      }
    }, 1000)
  },

  methods: {
    onClickLogMessageClose() {
      store.commit("SetLogMessageState", false);
    },
  },
});
