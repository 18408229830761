import WS_HG_SERVER from "@/services/ws/ws_hg_server";
import { HG_Contents, HG_Coordinate, HG_Header, HG_Mission, HG_Packet } from "@/services/ws/ws_hg_server_packet_interface";
import { Command, E_MESSAGE_TYPE, SubCommand } from "@/services/ws/ws_hg_server_protocol";

export function ptz_move(dir:string, target_index:number, continuous = false) {

    const up = dir.includes('u');
    const down = dir.includes('d');
    const left = dir.includes('l');
    const right = dir.includes('r');
    const zoomin = dir.includes('zi');
    const zoomout = dir.includes('zo');

    
    const gimbal_coordinate: HG_Coordinate = {
        _alt: zoomin ? 1 : zoomout ? -1 : 0,
        _lon: up ? 1 : down ? -1 : 0,
        _lat: right ? 1 : left ? -1 : 0,
    };

    const gimbal_value: HG_Mission = {
        _index: 0,
        // 원래 9번 이였는데 지금은 80번임
        _command: Command.COMMAND_GIMBAL_GCS,
        _sub_command: continuous ? SubCommand.SUB_COMMAND_PTZ_CONTINOUS : SubCommand.SUB_COMMAND_NONE,
        _wait_time: 0,
        _coordinate: gimbal_coordinate
    }

    const gimbal_header: HG_Header = {
        _index: 1,
        _msg_type: E_MESSAGE_TYPE.MISSION_GIMBAL_CONTROL,
        _target_indicator: 1 << target_index,
    }

    const gimbal_contents: HG_Contents = {
        _drone_mission: gimbal_value
    }

    const gimbal_packet: HG_Packet = {
        _header: gimbal_header,
        _contents: gimbal_contents
    }

    console.log(gimbal_packet, gimbal_coordinate)

    WS_HG_SERVER.getInstance().send(gimbal_packet);
}

export function ptz_stop(target_index:number) {
    
    const gimbal_coordinate: HG_Coordinate = {
        _alt: 0,
        _lon: 0,
        _lat: 0,
    };

    const gimbal_value: HG_Mission = {
        _index: 0,
        // 원래 9번 이였는데 지금은 80번임
        _command: Command.COMMAND_GIMBAL_GCS,
        _sub_command: SubCommand.SUB_COMMAND_PTZ_STOP,
        _wait_time: 0,
        _coordinate: gimbal_coordinate
    }

    const gimbal_header: HG_Header = {
        _index: 1,
        _msg_type: E_MESSAGE_TYPE.MISSION_GIMBAL_CONTROL,
        _target_indicator: 1 << target_index,
    }

    const gimbal_contents: HG_Contents = {
        _drone_mission: gimbal_value
    }

    const gimbal_packet: HG_Packet = {
        _header: gimbal_header,
        _contents: gimbal_contents
    }

    console.log(gimbal_packet, gimbal_coordinate)

    WS_HG_SERVER.getInstance().send(gimbal_packet);
}

export function ptz_home(target_index:number) {
    
    const gimbal_coordinate: HG_Coordinate = {
        _alt: 0,
        _lon: 0,
        _lat: 0,
    };

    const gimbal_value: HG_Mission = {
        _index: 0,
        // 원래 9번 이였는데 지금은 80번임
        _command: Command.COMMAND_GIMBAL_GCS,
        _sub_command: SubCommand.SUB_COMMAND_PTZ_HOME,
        _wait_time: 0,
        _coordinate: gimbal_coordinate
    }

    const gimbal_header: HG_Header = {
        _index: 1,
        _msg_type: E_MESSAGE_TYPE.MISSION_GIMBAL_CONTROL,
        _target_indicator: 1 << target_index,
    }

    const gimbal_contents: HG_Contents = {
        _drone_mission: gimbal_value
    }

    const gimbal_packet: HG_Packet = {
        _header: gimbal_header,
        _contents: gimbal_contents
    }

    console.log(gimbal_packet, gimbal_coordinate)

    WS_HG_SERVER.getInstance().send(gimbal_packet);
}

// module.exports = ptz_move;