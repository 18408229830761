
import { cesiumService } from "@/services/cesium/cesium_service_inst";
import UserSettingManager from "@/services/hg/user_setting/user_setting_manager";
import { USER_SETTINGS } from "@/services/hg/user_setting/user_setting_protocol";
import store from "@/store";
import { computed, defineComponent } from "vue";

export default defineComponent({
  data() {

    const before:USER_SETTINGS = UserSettingManager.getInstance().Get();

    return {
      before: before,
      
      show: false,

      home_position: {
        x: before.home_position.x,
        y: before.home_position.y,
        z: before.home_position.z,
        h: before.home_position.h,
        p: before.home_position.p,
        r: before.home_position.r,
      },

      only_number_regx: /^[0-9]+$/,
      only_number_rule: [
        (v:string) => /^[0-9]+$/.test(v)
      ],

      takeoff_initial: before.takeoff_initial,
      takeoff_confirm: before.takeoff_confirm ? 1 : 0, // 0 = false, 1 = true

      color_waypoint_menu: false,
      color_waypoint: before.color_waypoint,

      color_mission_menu: false,
      color_mission: before.color_mission,

      color_fly_to_target_menu: false,
      color_fly_to_target: before.color_fly_to_target,

      color_drone_altitude_menu: false,
      color_drone_altitude: before.color_drone_altitude,

      yaw_speed: before.yaw_speed,
    }
  },
  setup() {
    const store_show_user_setting = computed<boolean>(() => store.getters.GetShowUserSetting);
    return {store_show_user_setting};
  },

  watch: {
    store_show_user_setting: {
      handler(val, oldVal) {
        this.show = val;
        if(this.show) {
          const before:USER_SETTINGS = UserSettingManager.getInstance().Get();
          this.home_position.x = before.home_position.x;
          this.home_position.y = before.home_position.y;
          this.home_position.z = before.home_position.z;
          this.home_position.h = before.home_position.h;
          this.home_position.p = before.home_position.p;
          this.home_position.r = before.home_position.r;
          this.takeoff_initial = before.takeoff_initial;
          this.takeoff_confirm = before.takeoff_confirm ? 1 : 0;
          this.color_waypoint = before.color_waypoint;
          this.color_mission = before.color_mission;
          this.color_fly_to_target = before.color_fly_to_target;
          this.color_drone_altitude = before.color_drone_altitude;
          this.yaw_speed = before.yaw_speed;

          this.before = before;
        }
      },
    },
  },
  methods: {
    SetShow(show:boolean) {
      store.commit('SetShowUserSetting', show);
      this.show = show;
    },

    ValidCheckTakeoffInitial(value:string) {
      this.$nextTick(() => {
        if(isNaN(Number(value))) {
        this.takeoff_initial = 5;
      }
      else {
        if(Number(value) < 3) {
          this.takeoff_initial = 3;
        }
        else if(Number(value) > 100) {
          this.takeoff_initial = 100;
        }
        else {
          this.takeoff_initial = Math.ceil(Number(value));
        }
      }
      })
    },

    ValidCheckYawSpeed(value:string) {
      this.$nextTick(() => {
        if(isNaN(Number(value))) {
        this.yaw_speed = 200;
      }
      else {
        if(Number(value) < 50) {
          this.yaw_speed = 50;
        }
        else if(Number(value) > 300) {
          this.yaw_speed = 300;
        }
        else {
          this.yaw_speed = Math.ceil(Number(value));
        }
      }
      })
    },

    AnythingChanged() {
      if(
        this.HomeChanged()
        || this.before.takeoff_initial != this.takeoff_initial
        || (this.before.takeoff_confirm ? 1 : 0) != this.takeoff_confirm
        || this.before.color_waypoint != this.color_waypoint
        || this.before.color_mission != this.color_mission
        || this.before.color_fly_to_target != this.color_fly_to_target
        || this.before.color_drone_altitude != this.color_drone_altitude
        || this.before.yaw_speed != this.yaw_speed
        ) {
          return true;
      }
      return false;
    },

    HomeChanged() {
      if(
        this.before.home_position.x != this.home_position.x
        || this.before.home_position.y != this.home_position.y
        || this.before.home_position.z != this.home_position.z
        || this.before.home_position.h != this.home_position.h
        || this.before.home_position.p != this.home_position.p
        || this.before.home_position.r != this.home_position.r
        ) {
          return true;
      }
      return false;
    },

    CaptureCurrentCameraPosition() {
      const Cesium = cesiumService.GetCesium();
      const viewer = cesiumService.GetViewer();

      if(viewer && viewer.camera) {

        const a = new Cesium.Cartesian3(viewer.camera.positionWC.x, viewer.camera.positionWC.y, viewer.camera.positionWC.z)
        const b = Cesium.Cartographic.fromCartesian(a);
        const lat = Cesium.Math.toDegrees(b.latitude);
        const lng = Cesium.Math.toDegrees(b.longitude);
        const alt = b.height;

        this.home_position.x = lng;
        this.home_position.y = lat;
        this.home_position.z = alt;

        this.home_position.h = viewer.camera.heading;
        this.home_position.p = viewer.camera.pitch;
        this.home_position.r = viewer.camera.roll;
      }
    },

    OnApply() {
      const user_setting:USER_SETTINGS = {
        account: store.getters.GetLoginData.id,
        home_position: this.home_position,
        takeoff_initial: this.takeoff_initial,
        takeoff_confirm: this.takeoff_confirm == 1 ? true : false,
        color_waypoint: this.color_waypoint,
        color_mission: this.color_mission,
        color_fly_to_target: this.color_fly_to_target,
        color_drone_altitude: this.color_drone_altitude,
        yaw_speed: this.yaw_speed,
      }
      UserSettingManager.getInstance().UpsertUserSetting(user_setting);

      this.SetShow(false);
    },

    OnDefault() {
      UserSettingManager.getInstance().SetDefault();
      this.SetShow(false);
    }
  },
});
