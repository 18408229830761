export default class DroneVirtualManager {
  private static instance: DroneVirtualManager;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  private constructor() {
    // 
  }

  DeleteVirtual(index: number) {
    // Request the server to delete a virtual drone
    console.error(`Request the server to create a virtual drone`, index);
  }

  CreateVirtualDrone(lng: number, lat: number, alt: number) {
    // Request the server to create a virtual drone
    console.error(`Request the server to create a virtual drone`, lng, lat, alt);
  }
}
