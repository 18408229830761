
import BottomSingle from "@/components/BottomSingle.vue";
import CesiumMap from "@/components/CesiumMap.vue"; // @ is an alias to /src
import DroneAi from "@/components/DroneAi.vue";
import FacialRecognition from "@/components/FacialRecognition.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import GenerateMission from "@/components/Mission/GenerateMission.vue";
import RightMissionDetails from "@/components/Mission/RightMissionDetails.vue";
import RightMissionList from "@/components/Mission/RightMissionList.vue";
import RightDroneList from "@/components/RightDroneList.vue";
import { Component, Vue } from "vue-property-decorator";
// import DroneState from "@/components/DroneState.vue";
import CustomAlert from "@/components/CustomAlert.vue";
import DroneTVWrapper from "@/components/DroneTV/DroneTVWrapper.vue";
import FCForceUpdate from "@/components/FCForceUpdate.vue";
import GCSGuide from "@/components/GCSGuide.vue";
import GCSLicense from "@/components/GCSLicense.vue";
import H2LogData from "@/components/H2LogData.vue";
import LogMessage from "@/components/LogMessage.vue";
import LogView from "@/components/LogView.vue";
import WayPointOption from "@/components/Mission/WayPointOption.vue";
import ModalWindow from "@/components/ModalWindow.vue";
import RightDroneDetails from "@/components/RightDroneDetails.vue";
import TopBar from "@/components/TopBar.vue";
import UserSetting from "@/components/UserSetting.vue";
import ExtensionLicense from "@/components/extension/ExtensionLicense.vue";
import FunctionWrapper from "@/components/extension/FunctionWrapper.vue";
// import QuickCommand from "@/components/QuickCommand.vue";

@Component({
  components: {
    CesiumMap,
    LeftMenu,
    RightDroneList,
    RightMissionList,
    RightMissionDetails,
    BottomSingle,
    DroneAi,
    ExtensionLicense,
    // DroneState,
    LogView,
    H2LogData,
    TopBar,
    FacialRecognition,
    WayPointOption,
    ModalWindow,
    DroneTVWrapper,
    GenerateMission,
    CustomAlert,
    LogMessage,
    FCForceUpdate,
    UserSetting,
    GCSLicense,
    FunctionWrapper,
    RightDroneDetails,
    // QuickCommand,
    GCSGuide,
  },
})
export default class CesiumView extends Vue { }
