export interface STACK_FAULT {
    time?: string,
    command: number,
    flag: string,
    code: number,
    level: number,
    tag: string,
    des: string,
}

export const StackFaults:STACK_FAULT[] = [
    // 80x : 328 A, B
    {command: 0x328, flag: 'A', code: 0x80000000, level: 4, tag: 'AnodeOverPressure', des: 'H2 over Pressure, when inlet valve is open'},
    {command: 0x328, flag: 'A', code: 0x40000000, level: 3, tag: 'AnodeUnderPressure', des: 'H2 under Pressure'},
    {command: 0x328, flag: 'A', code: 0x20000000, level: 3, tag: 'Stack1OverCurrent', des: 'Fuel Cell 1 Over Current '},
    {command: 0x328, flag: 'A', code: 0x10000000, level: 4, tag: 'Outlet1OverTemperature', des: 'Stack 1 outlet over temperature (TX03)'},
    {command: 0x328, flag: 'A', code: 0x08000000, level: 3, tag: 'Stack1MinCellUndervoltage', des: 'Stack 1 cell voltage measurement below threshold when expected to be above (i.e. hydrogen on and not fan pulsing)'},
    {command: 0x328, flag: 'A', code: 0x04000000, level: 4, tag: 'Inlet1OverTemperature', des: 'Stack 1 inlet over temperature (TX02)'},
    {command: 0x328, flag: 'A', code: 0x02000000, level: 4, tag: 'SafetyObserverWatchdogTrip', des: 'SBO has not received watchdog signal from SMB'},
    {command: 0x328, flag: 'A', code: 0x01000000, level: 4, tag: 'BoardOverTemperature', des: 'Board over temperature (TX68)'},
    {command: 0x328, flag: 'A', code: 0x00800000, level: 4, tag: 'SafetyObserverFanTrip', des: 'SBO reporting fan overrun or inlet/outlet blockage'},
    {command: 0x328, flag: 'A', code: 0x00400000, level: 4, tag: 'ValveDefeatCheckFault', des: 'Hydrogen inlet valve enabled when HBO has not been armed. PX01 increases when actuating SV01 with out having armed the Safety Observer. Occurs during start up.'},
    {command: 0x328, flag: 'A', code: 0x00200000, level: 3, tag: 'Stack1UnderVoltage', des: 'Fuel Cell Stack1 Under Voltage'},
    {command: 0x328, flag: 'A', code: 0x00100000, level: 3, tag: 'Stack1OverVoltage', des: 'Fuel Cell Stack1 Over Voltage'},
    {command: 0x328, flag: 'A', code: 0x00080000, level: 4, tag: 'SafetyObserverMismatch', des: 'There are individual Safety Observer signals and a Safety Observer Tripped signal which is all the individual signals OR’d together. This fault indicates that the Tripped signal was flagged with no individual fault flagged.'},
    {command: 0x328, flag: 'A', code: 0x00040000, level: 3, tag: 'Stack2MinCellUndervoltage', des: 'Stack 2 cell voltage measurement below threshold when expected to be above (i.e. hydrogen on and not fan pulsing)'},
    {command: 0x328, flag: 'A', code: 0x00020000, level: 4, tag: 'SafetyObserverPressureTrip', des: 'SBO reported over or under pressure when SV01 enabled'},
    {command: 0x328, flag: 'A', code: 0x00010000, level: 4, tag: 'SafetyObserverBoardTxTrip', des: 'SBO reported over temperature or sensor fault'},
    {command: 0x328, flag: 'A', code: 0x00008000, level: 3, tag: 'Stack3MinCellUndervoltage', des: 'Stack 3 cell voltage measurement below threshold when expected to be above (i.e. hydrogen on and not fan pulsing)'},
    {command: 0x328, flag: 'A', code: 0x00004000, level: 4, tag: 'SafetyObserverSoftwareTrip', des: 'SBO has a detected and reported an internal software fault'},
    {command: 0x328, flag: 'A', code: 0x00002000, level: 3, tag: 'Fan2NoTacho', des: 'Fan 2 not rotating when commanded'},
    {command: 0x328, flag: 'A', code: 0x00001000, level: 3, tag: 'Fan1NoTacho', des: 'Fan 1 not rotating when commanded'},
    {command: 0x328, flag: 'A', code: 0x00000800, level: 3, tag: 'Fan3NoTacho', des: 'Fan 3 not rotating when commanded'},
    {command: 0x328, flag: 'A', code: 0x00000400, level: 3, tag: 'Fan3ErrantSpeed', des: 'Fan 3 rotating uncommanded'},
    {command: 0x328, flag: 'A', code: 0x00000200, level: 3, tag: 'Fan2ErrantSpeed', des: 'Fan 2 rotating uncommanded'},
    {command: 0x328, flag: 'A', code: 0x00000100, level: 3, tag: 'Fan1ErrantSpeed', des: 'Fan 1 rotating uncommanded'},
    {command: 0x328, flag: 'A', code: 0x00000080, level: 3, tag: 'Sib1Fault', des: 'Fault reported by SIB1 (one or more of 17 possible faults has been reported)'},
    {command: 0x328, flag: 'A', code: 0x00000040, level: 3, tag: 'Sib2Fault', des: 'Fault reported by SIB2 (one or more of 17 possible faults has been reported)'},
    {command: 0x328, flag: 'A', code: 0x00000020, level: 3, tag: 'Sib3Fault', des: 'Fault reported by SIB3 (one or more of 17 possible faults has been reported)'},
    {command: 0x328, flag: 'A', code: 0x00000010, level: 3, tag: 'Inlet1TxSensorFault', des: 'Stack 1 inlet temperature sensor (TX02). Either disconnected or open circuit - sensor/loom fault.'},
    {command: 0x328, flag: 'A', code: 0x00000008, level: 3, tag: 'Outlet1TxSensorFault', des: 'Outlet temperature sensor (TX03). Either disconnected or open circuit - sensor/loom fault.'},
    {command: 0x328, flag: 'A', code: 0x00000004, level: 3, tag: 'InvalidSerialNumber', des: 'The product code should be stored as characters five to seven in the serial number. This fault indicates that the product code stored in the serial number is not the same/compatible with the software being run '},
    {command: 0x328, flag: 'A', code: 0x00000002, level: 3, tag: 'Dcdc1CurrentWhenDisabled', des: 'Errant current through DCDC when disabled'},
    {command: 0x328, flag: 'A', code: 0x00000001, level: 3, tag: 'Dcdc1OverCurrent', des: 'DCDC output is above DCDC specification; traps DCDC direct throughput of current - 45A'},
    {command: 0x328, flag: 'B', code: 0x80000000, level: 3, tag: 'AmbientOverTemperature', des: 'If inlet temperature is above 40C'},
    {command: 0x328, flag: 'B', code: 0x40000000, level: 3, tag: 'Sib1CommsFault', des: 'Lost communications to SIB1'},
    {command: 0x328, flag: 'B', code: 0x20000000, level: 3, tag: 'BoardTxSensorFault', des: 'Board temperature sensor (TX68). Either disconnected or open circuit - sensor/loom fault.'},
    {command: 0x328, flag: 'B', code: 0x10000000, level: 3, tag: 'Sib2CommsFault', des: 'Lost communications to SIB2'},
    {command: 0x328, flag: 'B', code: 0x08000000, level: 3, tag: 'LowLeakTestPressure', des: 'Low Anode pressure during leak test. Unable to perform test.'},
    {command: 0x328, flag: 'B', code: 0x04000000, level: 3, tag: 'Sib3CommsFault', des: 'Lost communications to SIB3'},
    {command: 0x328, flag: 'B', code: 0x02000000, level: 3, tag: 'LouverOpenFault', des: 'Louver drive command "OPEN" but no movement'},
    {command: 0x328, flag: 'B', code: 0x01000000, level: 4, tag: 'StateDependentUnexpectedCurrent1', des: 'Errant current through DCDC1 dependant on state '},
    {command: 0x328, flag: 'B', code: 0x00800000, level: 3, tag: 'SideBySideCanMessageFault (used on 801)', des: 'Another system is transmitting with the same System ID. The System ID is set in the Configuration.'},
    {command: 0x328, flag: 'B', code: 0x00400000, level: 1, tag: 'SideBySideCanMessageIndicator (used on 801)', des: 'Two other systems are transmitting with the same System ID and should shut themselves down. This system will continue to operate'},
    {command: 0x328, flag: 'B', code: 0x00200000, level: 3, tag: 'Dcdc2CurrentWhenDisabled', des: 'Errant current through DCDC when disabled'},
    {command: 0x328, flag: 'B', code: 0x00100000, level: 3, tag: 'Dcdc3CurrentWhenDisabled', des: 'Errant current through DCDC when disabled'},
    {command: 0x328, flag: 'B', code: 0x00080000, level: 3, tag: 'Dcdc2OverCurrent', des: 'DCDC output is above DCDC specification; traps DCDC direct throughput of current - 45A'},
    {command: 0x328, flag: 'B', code: 0x00040000, level: 3, tag: 'ReadConfigFault', des: 'Configuration Fault - Failed to read NVM. The I2C memory device has not provided data.'},
    {command: 0x328, flag: 'B', code: 0x00020000, level: 3, tag: 'CorruptConfigFault', des: 'Configuration Fault - Memory Corrupt or not set. The CRC of the data from NVM is incorrect'},
    {command: 0x328, flag: 'B', code: 0x00010000, level: 3, tag: 'ConfigValueRangeFault', des: 'Configuration Fault - Value out of range. A configuration value is out of range.'},
    {command: 0x328, flag: 'B', code: 0x00008000, level: 3, tag: 'Stack1VoltageMismatch', des: 'Voltage mismatch stack 1 between the CVM and VX01'},
    {command: 0x328, flag: 'B', code: 0x00004000, level: 3, tag: 'Dcdc3OverCurrent', des: 'DCDC output is above DCDC specification; traps DCDC direct throughput of current - 45A'},
    {command: 0x328, flag: 'B', code: 0x00002000, level: 3, tag: 'UnexpectedPurgeInhibit', des: 'Purge Inhibit signal and louver position do not match. Either louver closed and purge enabled OR louver open with purge inhibited'},
    {command: 0x328, flag: 'B', code: 0x00001000, level: 3, tag: 'FuelOnNoVolts', des: 'Start up state "Fuel On" times out waiting for voltage confirmation'},
    {command: 0x328, flag: 'B', code: 0x00000800, level: 4, tag: 'LeakDetected', des: 'Hydrogen Leak test fail. Pressure has dropped more than allowed.'},
    {command: 0x328, flag: 'B', code: 0x00000400, level: 4, tag: 'AirCheckFault', des: 'No Tacho from any fan for 20s when HBO is armed'},
    {command: 0x328, flag: 'B', code: 0x00000200, level: 4, tag: 'AirCheckFaultShadow', des: 'Redundancy: No Tacho from any fan for 20s when HBO is armed'},
    {command: 0x328, flag: 'B', code: 0x00000100, level: 2, tag: 'DenyStartUnderVoltage', des: 'External voltage below threshold specified in the configuration'},
    {command: 0x328, flag: 'B', code: 0x00000080, level: 4, tag: 'StateDependentUnexpectedCurrent2', des: 'Errant current through DCDC2 dependant on state '},
    {command: 0x328, flag: 'B', code: 0x00000040, level: 4, tag: 'StateDependentUnexpectedCurrent3', des: 'Errant current through DCDC3 dependant on state '},
    {command: 0x328, flag: 'B', code: 0x00000020, level: 3, tag: 'Stack2UnderVoltage', des: 'Fuel Cell Stack2 Under Voltage'},
    {command: 0x328, flag: 'B', code: 0x00000010, level: 3, tag: 'Stack3UnderVoltage', des: 'Fuel Cell Stack3 Under Voltage'},
    {command: 0x328, flag: 'B', code: 0x00000008, level: 3, tag: 'Stack2OverVoltage', des: 'Fuel Cell Stack2 Over Voltage'},
    {command: 0x328, flag: 'B', code: 0x00000004, level: 3, tag: 'Stack3OverVoltage', des: 'Fuel Cell Stack3 Over Voltage'},
    {command: 0x328, flag: 'B', code: 0x00000002, level: 3, tag: 'Stack2OverCurrent', des: 'Fuel Cell 2 Over Current '},
    {command: 0x328, flag: 'B', code: 0x00000001, level: 3, tag: 'Stack3OverCurrent', des: 'Fuel Cell 3 Over Current '},

    // 80x : 378 C, D
    {command: 0x378, flag: 'C', code: 0x80000000, level: 3, tag: 'Stack2VoltageMismatch', des: 'Voltage mismatch stack 2 between the CVM and VX01'},
    {command: 0x378, flag: 'C', code: 0x40000000, level: 3, tag: 'Stack3VoltageMismatch', des: 'Voltage mismatch stack 3 between the CVM and VX01'},
    {command: 0x378, flag: 'C', code: 0x20000000, level: 4, tag: 'Outlet2OverTemperature', des: 'Stack 2 outlet over temperature (TX03)'},
    {command: 0x378, flag: 'C', code: 0x10000000, level: 4, tag: 'Outlet3OverTemperature', des: 'Stack 3 outlet over temperature (TX03)'},
    {command: 0x378, flag: 'C', code: 0x08000000, level: 4, tag: 'Inlet2OverTemperature', des: 'Stack 2 inlet over temperature (TX02)'},
    {command: 0x378, flag: 'C', code: 0x04000000, level: 4, tag: 'Inlet3OverTemperature', des: 'Stack 3 inlet over temperature (TX02)'},
    {command: 0x378, flag: 'C', code: 0x02000000, level: 3, tag: 'Inlet2TxSensorFault', des: 'Stack 2 inlet temperature sensor (TX02). Either disconnected or open circuit - sensor/loom fault.'},
    {command: 0x378, flag: 'C', code: 0x01000000, level: 3, tag: 'Inlet3TxSensorFault', des: 'Stack 3 inlet temperature sensor (TX02). Either disconnected or open circuit - sensor/loom fault.'},
    {command: 0x378, flag: 'C', code: 0x00800000, level: 3, tag: 'Outlet2TxSensorFault', des: 'Stack 2 outlet temperature sensor (TX03). Either disconnected or open circuit - sensor/loom fault.'},
    {command: 0x378, flag: 'C', code: 0x00400000, level: 3, tag: 'Outlet3TxSensorFault', des: 'Stack 3 outlet temperature sensor (TX03). Either disconnected or open circuit - sensor/loom fault.'},
    {command: 0x378, flag: 'C', code: 0x00200000, level: 3, tag: 'FuelOn1LowMeanVoltage', des: 'Start up state "Fuel On" times out waiting for stack 1 mean voltage confirmation'},
    {command: 0x378, flag: 'C', code: 0x00100000, level: 3, tag: 'FuelOn2LowMeanVoltage', des: 'Start up state "Fuel On" times out waiting for stack 2 mean voltage confirmation'},
    {command: 0x378, flag: 'C', code: 0x00080000, level: 3, tag: 'FuelOn3LowMeanVoltage', des: 'Start up state "Fuel On" times out waiting for stack 3 mean voltage confirmation'},
    {command: 0x378, flag: 'C', code: 0x00040000, level: 3, tag: 'FuelOn1LowMinVoltage', des: 'Start up state "Fuel On" times out waiting for stack 1 minimum voltage confirmation'},
    {command: 0x378, flag: 'C', code: 0x00020000, level: 3, tag: 'FuelOn2LowMinVoltage', des: 'Start up state "Fuel On" times out waiting for stack 2 minimum voltage confirmation'},
    {command: 0x378, flag: 'C', code: 0x00010000, level: 3, tag: 'FuelOn3LowMinVoltage', des: 'Start up state "Fuel On" times out waiting for stack 3 minimum voltage confirmation'},
    {command: 0x378, flag: 'C', code: 0x00008000, level: 3, tag: 'SoftwareTripShutdown', des: '"This is a roll-up of 5 conditions. ADC interrupt count for 1 control cycle. DAC interrupt count for 1 control cycle. I2C2 fault. Tacho interrupt fault. Turn around time fault"'},
    {command: 0x378, flag: 'C', code: 0x00004000, level: 1, tag: 'SoftwareTripFault', des: 'This is a roll-up of 3 conditions; display fault, RhTx fault and i2c fault'},
    {command: 0x378, flag: 'C', code: 0x00002000, level: 1, tag: 'TurnAroundTimeWarning', des: 'The computation of the control cycle (turn around) must take less than 10ms to complete. This fault indicates that this has extended beyond 9ms '},
    {command: 0x378, flag: 'C', code: 0x00001000, level: 3, tag: 'PurgeCheckShutdown', des: 'Missed either the Open or Close pressure purge checks'},
    {command: 0x378, flag: 'C', code: 0x00000800, level: 3, tag: 'OutputUnderVoltage', des: 'System output voltage (VX03) below threshold'},
    {command: 0x378, flag: 'C', code: 0x00000400, level: 4, tag: 'OutputOverVoltage', des: 'System output voltage (VX03) above threshold'},
    {command: 0x378, flag: 'C', code: 0x00000200, level: 4, tag: 'SafetyObserverVoltRailTrip', des: 'SBO flagged a Fault from the Voltage Rail Monitors'},
    {command: 0x378, flag: 'C', code: 0x00000100, level: 4, tag: 'SafetyObserverDiffPressureTrip', des: 'SBO flagged a Fault from the Differential Pressure Transducer'},
    {command: 0x378, flag: 'C', code: 0x00000080, level: 1, tag: 'PurgeMissedOnePxOpen', des: 'Expected inlet pressure event not observed as purge valve opens'},
    {command: 0x378, flag: 'C', code: 0x00000040, level: 1, tag: 'PurgeMissedOnePxClose', des: 'Expected inlet pressure event not observed as purge valve closes'},
    {command: 0x378, flag: 'C', code: 0x00000020, level: 1, tag: 'PurgeMissedOneIxOpen', des: 'Expected current not observed as purge valve opens'},
    {command: 0x378, flag: 'C', code: 0x00000010, level: 1, tag: 'PurgeMissedOneIxSolSaver', des: 'Expected solonoid saving event not observed'},
    {command: 0x378, flag: 'C', code: 0x00000008, level: 1, tag: 'PurgeMissedOneIxClose', des: 'Expected current not observed as purge valve closes'},
    {command: 0x378, flag: 'C', code: 0x00000004, level: 4, tag: 'InRangeFaultPx01', des: 'If pressure signal unchanging by below the threshold during start up '},
    {command: 0x378, flag: 'C', code: 0x00000002, level: 4, tag: 'NoisyInputPx01', des: 'If pressure signal noise exceeds the threshold'},
    {command: 0x378, flag: 'C', code: 0x00000001, level: 4, tag: 'NoisyInputTx68', des: 'If board temperature signal noise exceeds the threshold'},
    {command: 0x378, flag: 'D', code: 0x80000000, level: 4, tag: 'NoisyInputDiffP', des: 'If differential pressure signal noise exceeds the threshold'},
    {command: 0x378, flag: 'D', code: 0x40000000, level: 4, tag: 'ValveClosedPxRising', des: 'If pressure is rising by above tolerance when the inlet valves are closed'},
    {command: 0x378, flag: 'D', code: 0x20000000, level: 4, tag: 'DiffPSensorFault', des: 'Pressure differential zero error greater than tolerable when fans not running'},
    {command: 0x378, flag: 'D', code: 0x10000000, level: 4, tag: 'LossOfVentilation', des: 'If pressure difference below threshold while SBO is enabled'},
    {command: 0x378, flag: 'D', code: 0x08000000, level: 4, tag: 'DiffPSensorHigh', des: 'Pressure differential greater than measurable'},
    {command: 0x378, flag: 'D', code: 0x04000000, level: 4, tag: 'FanOverrun', des: 'If any fan speed is above threshold'},
    {command: 0x378, flag: 'D', code: 0x02000000, level: 4, tag: 'BlockedAirFlow', des: 'If single fan speed is above threshold while SBO is enabled and there is no differential pressure'},
    {command: 0x378, flag: 'D', code: 0x01000000, level: 1, tag: 'WarningNoisyInputPx01', des: 'If pressure signal noise exceeds the warning threshold'},
    {command: 0x378, flag: 'D', code: 0x00800000, level: 1, tag: 'WarningNoisyInputTx68', des: 'If board temperature signal noise exceeds the warning threshold'},
    {command: 0x378, flag: 'D', code: 0x00400000, level: 1, tag: 'WarningNoisyInputDiffP', des: 'If differential pressure signal noise exceeds the warning threshold'},
    {command: 0x378, flag: 'D', code: 0x00200000, level: 3, tag: 'Dcdc1OutputFault', des: 'DCDC enabled AND output voltage more than allowable delta below set point AND output current more than allowable delta below current limit fault. This may indicate a broken DCDC or a broken DCDC output current sensor'},
    {command: 0x378, flag: 'D', code: 0x00100000, level: 2, tag: 'EmergencyPurge', des: 'Stack voltage or minimum cell voltage is below a threshold and triggered an emergency purge sequence in an attempt to recover it.'},
    {command: 0x378, flag: 'D', code: 0x00080000, level: 2, tag: 'EmergencyPurgeWarningA', des: 'More than 2 emergency purge cycles a too close together'},
    {command: 0x378, flag: 'D', code: 0x00040000, level: 2, tag: 'EmergencyPurgeWarningB', des: 'More than 4 emergency purge cycles a too close together'},
    {command: 0x378, flag: 'D', code: 0x00020000, level: 2, tag: 'EmergencyPurgeFault', des: 'More than 6 emergency purge cycles a too close together'},
    {command: 0x378, flag: 'D', code: 0x00010000, level: 3, tag: 'CalcCoreTxSensorFault', des: 'Calculated core temperature sensor; algorithm fault or input sensor fault (will be flagged separately)'},
    {command: 0x378, flag: 'D', code: 0x00008000, level: 4, tag: 'CalcCoreOverTemperature', des: 'Calculated Core over temperature'},
    {command: 0x378, flag: 'D', code: 0x00004000, level: 3, tag: 'LouverFailedToOpen', des: 'The louver failed to open when being commanded open'},
    {command: 0x378, flag: 'D', code: 0x00002000, level: 1, tag: 'LouverFailedToClose', des: 'The louver failed to close when being commanded closed. This will affect life of stacks. The unit can still safely produce power'},
    {command: 0x378, flag: 'D', code: 0x00001000, level: 3, tag: 'Dcdc2OutputFault', des: 'DCDC enabled AND output voltage more than allowable delta below set point AND output current more than allowable delta below current limit fault. This may indicate a broken DCDC or a broken DCDC output current sensor'},
    {command: 0x378, flag: 'D', code: 0x00000800, level: 3, tag: 'Dcdc3OutputFault', des: 'DCDC enabled AND output voltage more than allowable delta below set point AND output current more than allowable delta below current limit fault. This may indicate a broken DCDC or a broken DCDC output current sensor'},
    {command: 0x378, flag: 'D', code: 0x00000400, level: 0, tag: 'SidebySideTargetVoltagesShutdown', des: ''},
    {command: 0x378, flag: 'D', code: 0x00000200, level: 0, tag: 'SideBySideCanMessageFault (on 801)', des: ''},
    {command: 0x378, flag: 'D', code: 0x00000100, level: 0, tag: 'SideBySideCanMessageIndicator (on 801)', des: ''},
    {command: 0x378, flag: 'D', code: 0x00000080, level: 0, tag: 'AdcMonitorFault', des: ''},
    {command: 0x378, flag: 'D', code: 0x00000040, level: 0, tag: 'TachoIrqCounterFault', des: ''},
    {command: 0x378, flag: 'D', code: 0x00000020, level: 0, tag: 'TurnAroundTimeFault', des: ''},
    {command: 0x378, flag: 'D', code: 0x00000010, level: 0, tag: 'i2c2DacsInfo.i2cErrors.ackFailure', des: ''},
    {command: 0x378, flag: 'D', code: 0x00000008, level: 0, tag: 'i2c2DacsInfo.i2cErrors.arbLost', des: ''},
    {command: 0x378, flag: 'D', code: 0x00000004, level: 0, tag: 'i2c2DacsInfo.i2cErrors.busError', des: ''},
    {command: 0x378, flag: 'D', code: 0x00000002, level: 0, tag: 'i2c2DacsInfo.i2cErrors.timeout', des: ''},
    {command: 0x378, flag: 'D', code: 0x00000001, level: 0, tag: 'I2c2DacsFault', des: ''},

    // 2.4KW PCM : 408 A, B
    {command: 0x408, flag: 'A', code: 0x80000000, level: 4, tag: 'StartStateTimeOut', des: 'Start State has taken too long (>60s)'},
    {command: 0x408, flag: 'A', code: 0x40000000, level: 4, tag: 'StopStateTimeOut', des: 'Stop State has taken too long (>120s)'},
    {command: 0x408, flag: 'A', code: 0x20000000, level: 4, tag: 'PcmPwrCtrlSafety1Indicator', des: 'The safety 1 is not enabled by the PCM when running'},
    {command: 0x408, flag: 'A', code: 0x10000000, level: 4, tag: 'PcmPwrCtrlSafety2Indicator', des: 'The safety 2 is not enabled by the PCM when running'},
    {command: 0x408, flag: 'A', code: 0x08000000, level: 4, tag: 'PcmPwrCtrlSafety1DenyStart', des: 'The safety 1 is not enabled by the PCM when Off (this will deny start)'},
    {command: 0x408, flag: 'A', code: 0x04000000, level: 4, tag: 'PcmPwrCtrlSafety2DenyStart', des: 'The safety 1 is not enabled by the PCM when Off (this will deny start)'},
    {command: 0x408, flag: 'A', code: 0x02000000, level: 4, tag: 'PcmPowerGoodFaultInOperation', des: 'When running power good not returned from PCM board'},
    {command: 0x408, flag: 'A', code: 0x01000000, level: 4, tag: 'PcmPowerGoodFaultStartFailed', des: 'Before starting power good not returned from PCM board'},
    {command: 0x408, flag: 'A', code: 0x00800000, level: 4, tag: 'PomPowerGoodFaultInOperation', des: 'When running power good not returned from POM board'},
    {command: 0x408, flag: 'A', code: 0x00400000, level: 4, tag: 'PomPowerGoodFaultStartFailed', des: 'Before starting power good not returned from POM board'},
    {command: 0x408, flag: 'A', code: 0x00200000, level: 4, tag: 'BatteryUVCritical', des: 'Critical Battery Under Voltage (<44.3V)'},
    {command: 0x408, flag: 'A', code: 0x00100000, level: 4, tag: 'BatteryUVShutdown', des: 'Shutdown Battery Under Voltage (<45.7V)'},
    {command: 0x408, flag: 'A', code: 0x00080000, level: 4, tag: 'BatteryUVWarning', des: 'Battery voltage below limit (<50.4V)'},
    {command: 0x408, flag: 'A', code: 0x00040000, level: 4, tag: 'TankUPShutdown', des: 'Tank Under Pressure Fault (<5.0Bar)'},
    {command: 0x408, flag: 'A', code: 0x00020000, level: 4, tag: 'TankUPDenyStart', des: 'Tank Under Pressure Deny Start (<6.0Bar)'},
    {command: 0x408, flag: 'A', code: 0x00010000, level: 4, tag: 'TankLowPressure', des: 'Tank Low Pressure Indicator (<15.0Bar)'},
    {command: 0x408, flag: 'A', code: 0x00008000, level: 4, tag: 'TankOPShutdown', des: 'Tank Over Pressure Fault (>310.0Bar)'},
    {command: 0x408, flag: 'A', code: 0x00004000, level: 4, tag: 'TankHighPressure', des: 'Tank High Pressure Indicator (>305.0Bar)'},
    {command: 0x408, flag: 'A', code: 0x00002000, level: 4, tag: 'SafetyPairFault', des: 'Both safety voltages lost from SPM while SPM is not Of'},
    {command: 0x408, flag: 'A', code: 0x00001000, level: 4, tag: 'PreStartTimeout', des: 'If the system is still waiting for start event after 60s'},
    {command: 0x408, flag: 'A', code: 0x00000800, level: 4, tag: 'SPM1NotRunning', des: 'This indicates that SPM is not running when requested'},
    {command: 0x408, flag: 'A', code: 0x00000400, level: 4, tag: 'SPM2NotRunning', des: 'This indicates that SPM is not running when requested'},
    {command: 0x408, flag: 'A', code: 0x00000200, level: 4, tag: 'ReadConfigFault', des: 'Configuration Fault – failed to read NVM. The I2C memory device has not provided data.'},
    {command: 0x408, flag: 'A', code: 0x00000100, level: 4, tag: 'CorruptConfigFault', des: 'Configuration Fault – memory corrupt or not set. The CRC of the data from NVM is incorrect'},
    {command: 0x408, flag: 'A', code: 0x00000080, level: 4, tag: 'ConfigValueRangeFault', des: 'Configuration Fault – value out of range. A configuration value is out of range.'},
    {command: 0x408, flag: 'A', code: 0x00000040, level: 4, tag: 'PreStartSleepCommand', des: 'The system is off/idle and has been commanded to go to sleep'},
    {command: 0x408, flag: 'B', code: 0x00000002, level: 4, tag: 'CanInterSysUnexpectedDlc', des: 'CAN Coms Fault'},
    {command: 0x408, flag: 'B', code: 0x00000001, level: 4, tag: 'EngineeringFault', des: 'This is to capture issues during development' },
    
    // 2.4KW PCM : 410 C, D
    {command: 0x410, flag: 'C', code: 0x80000000, level: 4, tag: 'CanInterSysRxOverflow', des: 'CAN Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x40000000, level: 4, tag: 'CanInterSysFailedInitialisation', des: 'CAN Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x20000000, level: 4, tag: 'CanCustomerUnexpectedDlc', des: 'CAN Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x10000000, level: 4, tag: 'CanCustomerRxOverflow', des: 'CAN Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x08000000, level: 4, tag: 'CanCustomerFailedInitialisation', des: 'CAN Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x04000000, level: 4, tag: 'RtcLseNotReady', des: 'Real time clock fault'},
    {command: 0x410, flag: 'C', code: 0x02000000, level: 4, tag: 'RtcRegisterFault', des: 'Real time clock fault'},
    {command: 0x410, flag: 'C', code: 0x01000000, level: 4, tag: 'InitialisedRtc', des: 'Real time clock fault'},
    {command: 0x410, flag: 'C', code: 0x00800000, level: 4, tag: 'RtcSetFault', des: 'Real time clock fault'},
    {command: 0x410, flag: 'C', code: 0x00400000, level: 4, tag: 'ReferenceVoltageHigh', des: 'Sensor reference voltage above limit (>3.35V)'},
    {command: 0x410, flag: 'C', code: 0x00200000, level: 4, tag: 'ReferenceVoltageLow', des: 'Sensor reference voltage above limit (<3.15V)'},
    {command: 0x410, flag: 'C', code: 0x00100000, level: 4, tag: 'ReferenceVoltageVeryHigh', des: 'Sensor reference voltage high limit (>3.5V)'},
    {command: 0x410, flag: 'C', code: 0x00080000, level: 4, tag: 'ReferenceVoltageVeryLow', des: 'Sensor reference voltage Low limit (<3.0V)'},
    {command: 0x410, flag: 'C', code: 0x00040000, level: 4, tag: 'SerialCustomerRxOverflow', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00020000, level: 4, tag: 'SerialCustomerTxOverflow', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00010000, level: 4, tag: 'SerialCustomerRxOverrun', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00008000, level: 4, tag: 'SerialDebugRxOverflow', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00004000, level: 4, tag: 'SerialDebugTxOverflow', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00002000, level: 4, tag: 'SerialDebugRxOverrun', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00001000, level: 4, tag: 'SerialSpm1RxLineLengthOverflow', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00000800, level: 4, tag: 'SerialSpm1RxLineBufferOverflow', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00000400, level: 4, tag: 'SerialSpm1TxBufferOverflow', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00000200, level: 4, tag: 'SerialSpm1TxExtrainterrupt', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00000100, level: 4, tag: 'SerialSpm1RxOverrun', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00000080, level: 4, tag: 'SerialSpm2RxLineLengthOverflow', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00000040, level: 4, tag: 'SerialSpm2RxLineBufferOverflow', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00000020, level: 4, tag: 'SerialSpm2TxBufferOverflow', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00000010, level: 4, tag: 'SerialSpm2TxExtrainterrupt', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00000008, level: 4, tag: 'SerialSpm2RxOverrun', des: 'Serial Coms Fault'},
    {command: 0x410, flag: 'C', code: 0x00000004, level: 4, tag: 'I2c2DacsIrqCount', des: 'DAC Fault'},
    {command: 0x410, flag: 'C', code: 0x00000002, level: 4, tag: 'I2c2DacsTimeout', des: 'DAC Fault'},
    {command: 0x410, flag: 'C', code: 0x00000001, level: 4, tag: 'TurnaroundTimeWarning', des: 'Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x80000000, level: 4, tag: 'SoftwareTripShutdown', des: 'This is to capture issues during development'},
    {command: 0x410, flag: 'D', code: 0x40000000, level: 4, tag: 'TurnaroundTimeFault', des: 'Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x20000000, level: 4, tag: 'Spm1RxCommsLengthFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x10000000, level: 4, tag: 'Spm1RxCyclicCounterFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x08000000, level: 4, tag: 'Spm1RxLineCounterFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x04000000, level: 4, tag: 'Spm1RxShortLengthFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x02000000, level: 4, tag: 'Spm1RxDecodeEncodeFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x01000000, level: 4, tag: 'Spm1RxecodeCrcFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00800000, level: 4, tag: 'Spm2RxCommsLengthFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00400000, level: 4, tag: 'Spm2RxCyclicCounterFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00200000, level: 4, tag: 'Spm2RxLineCounterFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00100000, level: 4, tag: 'Spm2RxShortLengthFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00080000, level: 4, tag: 'Spm2RxDecodeEncodeFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00040000, level: 4, tag: 'Spm2RxecodeCrcFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00020000, level: 4, tag: 'Spm1TxLengthFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00010000, level: 4, tag: 'Spm1TxEncodeFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00008000, level: 4, tag: 'Spm2TxLengthFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00004000, level: 4, tag: 'Spm2TxEncodeFault', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00002000, level: 4, tag: 'BadSpmIdentity', des: 'SPM Coms Fault'},
    {command: 0x410, flag: 'D', code: 0x00001000, level: 4, tag: 'I2c1AckFailure', des: 'I2C Fault'},
    {command: 0x410, flag: 'D', code: 0x00000800, level: 4, tag: 'I2c1ArbitrationLost', des: 'I2C Fault'},
    {command: 0x410, flag: 'D', code: 0x00000400, level: 4, tag: 'I2c1BusError', des: 'I2C Fault'},
    {command: 0x410, flag: 'D', code: 0x00000200, level: 4, tag: 'I2c1Timeout', des: 'I2C Fault'},
    {command: 0x410, flag: 'D', code: 0x00000100, level: 4, tag: 'Adc1BufferOverwritten', des: 'ADC Fault'},
    {command: 0x410, flag: 'D', code: 0x00000080, level: 4, tag: 'Adc1MonitorHighIrqCount', des: 'ADC Fault'},
    {command: 0x410, flag: 'D', code: 0x00000040, level: 4, tag: 'Adc1MonitorLowIrqCount', des: 'ADC Fault'},
    {command: 0x410, flag: 'D', code: 0x00000020, level: 4, tag: 'Adc3BufferOverwritten', des: 'ADC Fault'},
    {command: 0x410, flag: 'D', code: 0x00000010, level: 4, tag: 'Adc3MonitorHighIrqCount', des: 'ADC Fault'},
    {command: 0x410, flag: 'D', code: 0x00000008, level: 4, tag: 'Adc3MonitorLowIrqCount', des: 'ADC Fault'},
    {command: 0x410, flag: 'D', code: 0x00000004, level: 4, tag: 'I2c2DacsAckFailure', des: 'I2C Fault'},
    {command: 0x410, flag: 'D', code: 0x00000002, level: 4, tag: 'I2c2DacsArbitrationLost', des: 'I2C Fault'},
    {command: 0x410, flag: 'D', code: 0x00000001, level: 4, tag: 'I2c2DacsBusError', des: 'I2C Fault' },
    
    // 2.4KW SPM1 : 418 A, B
    {command: 0x418, flag: 'A', code: 0x80000000, level: 4, tag: 'StartStateTimeOut', des: 'Start state has taken too long to complete (>60s)'},
    {command: 0x418, flag: 'A', code: 0x40000000, level: 4, tag: 'StopStateTimeOut', des: 'Stop state has taken too long to complete (>120s)'},
    {command: 0x418, flag: 'A', code: 0x20000000, level: 4, tag: 'AirFlowPowerFault', des: 'Fan powered but no electrical output (Fan power >5W)'},
    {command: 0x418, flag: 'A', code: 0x10000000, level: 4, tag: 'StackUnderVoltage', des: 'Fuel inlet open but no volts on stack (Stack Volt <60V)'},
    {command: 0x418, flag: 'A', code: 0x08000000, level: 4, tag: 'FanDcdcFaultStartFailed', des: 'DCDC status is fault during start state'},
    {command: 0x418, flag: 'A', code: 0x04000000, level: 4, tag: 'FanDcdcFaultInOperation', des: 'DCDC status is fault during operation state'},
    {command: 0x418, flag: 'A', code: 0x02000000, level: 4, tag: 'PowerGoodFaultStartFailed', des: 'DCDC status is fault during start state'},
    {command: 0x418, flag: 'A', code: 0x01000000, level: 4, tag: 'PowerGoodFaultInOperation', des: 'DCDC status is fault during operation state'},
    {command: 0x418, flag: 'A', code: 0x00800000, level: 4, tag: 'PcmPwrCtrlLowPower1Indicator', des: 'Low power rail 1 is not enabled'},
    {command: 0x418, flag: 'A', code: 0x00400000, level: 4, tag: 'PcmPwrCtrlLowPower2Indicator', des: 'Low power rail 2 is not enabled'},
    {command: 0x418, flag: 'A', code: 0x00200000, level: 4, tag: 'PcmPwrCtrlSafety1Indicator', des: 'Safety 1 is not enabled'},
    {command: 0x418, flag: 'A', code: 0x00100000, level: 4, tag: 'PcmPwrCtrlSafety2Indicator', des: 'Safety 2 is not enabled'},
    {command: 0x418, flag: 'A', code: 0x00080000, level: 4, tag: 'PcmPwrCtrlLowPower1DenyStart', des: 'Low power rail 1 is not enabled, before start command'},
    {command: 0x418, flag: 'A', code: 0x00040000, level: 4, tag: 'PcmPwrCtrlLowPower2DenyStart', des: 'Low power rail 2 is not enabled, before start command'},
    {command: 0x418, flag: 'A', code: 0x00020000, level: 4, tag: 'PcmPwrCtrlSafety1DenyStart', des: 'Safety 1 is not enabled, before start command'},
    {command: 0x418, flag: 'A', code: 0x00010000, level: 4, tag: 'PcmPwrCtrlSafety2DenyStart', des: 'Safety 2 is not enabled, before start command'},
    {command: 0x418, flag: 'A', code: 0x00008000, level: 4, tag: 'FanPulseLoadOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 0x418, flag: 'A', code: 0x00004000, level: 4, tag: 'StackSensor1OverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 0x418, flag: 'A', code: 0x00002000, level: 4, tag: 'StackSensor2OverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 0x418, flag: 'A', code: 0x00001000, level: 4, tag: 'negativeEndPlateHeaterOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 0x418, flag: 'A', code: 0x00000800, level: 4, tag: 'PositiveEndPlateHeaterOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 0x418, flag: 'A', code: 0x00000400, level: 4, tag: 'FanDcdcEnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x418, flag: 'A', code: 0x00000200, level: 4, tag: 'FanPulseLoadEnableOutputCheck', des: 'Digital output tell-back not the same as set (externally held: processor not powerful enough to set voltage correctly)'},
    {command: 0x418, flag: 'A', code: 0x00000100, level: 4, tag: 'LoadDcdcEnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x418, flag: 'A', code: 0x00000080, level: 4, tag: 'StackTxSensor1Fault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 0x418, flag: 'A', code: 0x00000040, level: 4, tag: 'StackTxSensor2Fault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 0x418, flag: 'A', code: 0x00000020, level: 4, tag: 'NegativeEndPlateTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 0x418, flag: 'A', code: 0x00000010, level: 4, tag: 'PositiveEndPlateTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 0x418, flag: 'A', code: 0x00000008, level: 4, tag: 'AirInletTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 0x418, flag: 'A', code: 0x00000004, level: 4, tag: 'DisableBatteryOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x418, flag: 'A', code: 0x00000002, level: 4, tag: 'DisableExternalOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x418, flag: 'A', code: 0x00000001, level: 4, tag: 'EnableStackOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x418, flag: 'B', code: 0x80000000, level: 4, tag: 'StackTxSensor1OverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 0x418, flag: 'B', code: 0x40000000, level: 4, tag: 'StackTxSensor2OverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 0x418, flag: 'B', code: 0x20000000, level: 4, tag: 'NegativeEndPlateTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 0x418, flag: 'B', code: 0x10000000, level: 4, tag: 'PositiveEndPlateTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 0x418, flag: 'B', code: 0x08000000, level: 4, tag: 'AirInletTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 0x418, flag: 'B', code: 0x04000000, level: 4, tag: 'Rail5V1EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x418, flag: 'B', code: 0x02000000, level: 4, tag: 'Rail5V2EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x418, flag: 'B', code: 0x01000000, level: 4, tag: 'Rail12V1EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x418, flag: 'B', code: 0x00800000, level: 4, tag: 'Rail12V2EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x418, flag: 'B', code: 0x00400000, level: 4, tag: 'StackOverVoltage', des: 'Stack voltage above threshold (>80V)'},
    {command: 0x418, flag: 'B', code: 0x00200000, level: 4, tag: 'StackUnderVoltage', des: 'Stack voltage below threshold when fuel on and not POC (<36V)'},
    {command: 0x418, flag: 'B', code: 0x00100000, level: 4, tag: 'StackOutputOverCurrent', des: 'Stack output current above DCDC input max current (>40A)'},
    {command: 0x418, flag: 'B', code: 0x00080000, level: 4, tag: 'UnexpectedStackOutputCurrent', des: 'Stack output current above threshold when the DCDC is not enabled (>3A)'},
    {command: 0x418, flag: 'B', code: 0x00040000, level: 4, tag: 'FanPulseOverCurrent', des: 'Current to POC load resistor above threshold (>250A)'},
    {command: 0x418, flag: 'B', code: 0x00020000, level: 4, tag: 'UnexpectedFanPulseCurrent', des: 'POC load resistor current above threshold when the load resistor is not enabled (>3A)'},
    {command: 0x418, flag: 'B', code: 0x00010000, level: 4, tag: 'LoadDcdcOutputOverVoltage', des: 'Output voltage is above threshold (>85A)'},
    {command: 0x418, flag: 'B', code: 0x00008000, level: 4, tag: 'LoadDcdcOutputControlLost', des: 'Output voltage is significantly above set point (>3A than setpoint)'},
    {command: 0x418, flag: 'B', code: 0x00004000, level: 4, tag: 'LoadDcdc1OuputCheck', des: 'SPM 1 Output voltage below setpoint (difference >2V) AND output current is below limit (difference >3A). Implies a DCDC output power fault'},
    {command: 0x418, flag: 'B', code: 0x00002000, level: 4, tag: 'LoadDcdc2OuputCheck', des: 'SPM2 Output voltage below setpoint (difference >2V) AND output current is below limit (difference >3A). Implies a DCDC output power fault'},
    {command: 0x418, flag: 'B', code: 0x00001000, level: 4, tag: 'BalanceOfPlantUVWarningA', des: 'BOP voltage below limit (<35V)'},
    {command: 0x418, flag: 'B', code: 0x00000800, level: 4, tag: 'BalanceOfPlantUVDenyStart', des: 'BOP Under Voltage before start command (<35V) (this will deny start)'},
    {command: 0x418, flag: 'B', code: 0x00000400, level: 4, tag: 'BalanceOfPlantUVWarningB', des: 'BOP Under Voltage warning flag (<30V)'},
    {command: 0x418, flag: 'B', code: 0x00000200, level: 4, tag: 'FanEscTelemetryCrcFault', des: 'Fan ESC telemetry fault'},
    {command: 0x418, flag: 'B', code: 0x00000100, level: 4, tag: 'FanEscTelemetryNoMessages', des: 'No communications from the fan ESC'},
    {command: 0x418, flag: 'B', code: 0x00000080, level: 4, tag: 'HighFanPulseCurrentAbort', des: 'POC aborted due to higher than expected current (<45V)'},
    {command: 0x418, flag: 'B', code: 0x00000040, level: 4, tag: 'SafetyPairFault', des: 'Both safety voltages lost from SPM while SPM is operating'},
    {command: 0x418, flag: 'B', code: 0x00000020, level: 4, tag: 'SelfArbitrate', des: 'Communications from PCM lost'},
    {command: 0x418, flag: 'B', code: 0x00000010, level: 4, tag: 'PcmCommsLostShutdown', des: 'Cyclic Counter lost'},
    {command: 0x418, flag: 'B', code: 0x00000008, level: 4, tag: 'FanEscTelemetryStartFailed', des: 'Esc Telemetry failed during start up'},
    {command: 0x418, flag: 'B', code: 0x00000004, level: 4, tag: 'MaxCooling', des: 'Fans are at their maximum set point'},
    {command: 0x418, flag: 'B', code: 0x00000001, level: 4, tag: 'EngineeringFault', des: 'Development warning flag' },
    
    // 2.4KW SPM1 : 420 C, D
    {command: 0x420, flag: 'D', code: 0x80000000, level: 4, tag: 'SoftwareTripShutdown', des: 'Software Shutdown'},
    {command: 0x420, flag: 'D', code: 0x40000000, level: 4, tag: 'Adc12BufferOverwritten', des: 'ADC Buffer fault'},
    {command: 0x420, flag: 'D', code: 0x20000000, level: 4, tag: 'PcmRxCommsLengthFault', des: 'PCM Coms Fault'},
    {command: 0x420, flag: 'D', code: 0x10000000, level: 4, tag: 'PcmRxCyclicCounterFault', des: 'PCM Coms Fault'},
    {command: 0x420, flag: 'D', code: 0x08000000, level: 4, tag: 'PcmRxLineCounterFault', des: 'PCM Coms Fault'},
    {command: 0x420, flag: 'D', code: 0x04000000, level: 4, tag: 'PcmRxShortLengthFault', des: 'PCM Coms Fault'},
    {command: 0x420, flag: 'D', code: 0x02000000, level: 4, tag: 'PcmRxDecodeEncodeFault', des: 'PCM Coms Fault'},
    {command: 0x420, flag: 'D', code: 0x01000000, level: 4, tag: 'PcmRxecodeCrcFault', des: 'PCM Coms Fault'},
    {command: 0x420, flag: 'D', code: 0x00800000, level: 4, tag: 'PcmTxLengthFault', des: 'PCM Coms Fault'},
    {command: 0x420, flag: 'D', code: 0x00400000, level: 4, tag: 'PcmTxEncodeFault', des: 'PCM Coms Fault'},
    {command: 0x420, flag: 'D', code: 0x00200000, level: 4, tag: 'I2c1AckFailure', des: 'I2C fault'},
    {command: 0x420, flag: 'D', code: 0x00100000, level: 4, tag: 'I2c1ArbitrationLost', des: 'I2C fault'},
    {command: 0x420, flag: 'D', code: 0x00080000, level: 4, tag: 'I2c1BusError', des: 'I2C fault'},
    {command: 0x420, flag: 'D', code: 0x00040000, level: 4, tag: 'I2c1Timeout', des: 'I2C fault'},
    {command: 0x420, flag: 'D', code: 0x00020000, level: 4, tag: 'Adc12MonitorHighIrqCount', des: 'ADC High fault'},
    {command: 0x420, flag: 'D', code: 0x00010000, level: 4, tag: 'Adc12MonitorLowIrqCount', des: 'ADC Low fault'},
    {command: 0x420, flag: 'D', code: 0x00008000, level: 4, tag: 'I2c2DacsAckFailure', des: 'I2C fault'},
    {command: 0x420, flag: 'D', code: 0x00004000, level: 4, tag: 'I2c2DacsArbitrationLost', des: 'I2C fault'},
    {command: 0x420, flag: 'D', code: 0x00002000, level: 4, tag: 'I2c2DacsBusError', des: 'I2C fault'},
    {command: 0x420, flag: 'D', code: 0x00001000, level: 4, tag: 'I2c2DacsIrqCount', des: 'I2C fault'},
    {command: 0x420, flag: 'D', code: 0x00000800, level: 4, tag: 'I2c2DacsTimeout', des: 'I2C fault'},
    {command: 0x420, flag: 'D', code: 0x00000400, level: 4, tag: 'TurnaroundTimeWarning', des: 'Coms Fault'},
    {command: 0x420, flag: 'D', code: 0x00000200, level: 4, tag: 'TurnaroundTimeFault', des: 'Coms Fault'},
    {command: 0x420, flag: 'D', code: 0x00000100, level: 4, tag: 'Adc3BufferOverwritten', des: 'ADC Buffer fault'},
    {command: 0x420, flag: 'D', code: 0x00000080, level: 4, tag: 'Adc3MonitorHighIrqCount', des: 'ADC High fault'},
    {command: 0x420, flag: 'D', code: 0x00000040, level: 4, tag: 'Adc3MonitorLowIrqCount', des: 'ADC Low fault'},
    {command: 0x420, flag: 'D', code: 0x00000020, level: 4, tag: 'ReferenceVoltageHigh', des: 'Sensor reference voltage above limit (>3.35V)'},
    {command: 0x420, flag: 'D', code: 0x00000010, level: 4, tag: 'ReferenceVoltageLow', des: 'Sensor reference voltage above limit (<3.15V)'},
    {command: 0x420, flag: 'D', code: 0x00000008, level: 4, tag: 'ReferenceVoltageVeryHigh', des: 'Sensor reference voltage high limit (>3.5V)'},
    {command: 0x420, flag: 'D', code: 0x00000004, level: 4, tag: 'ReferenceVoltageVeryLow', des: 'Sensor reference voltage Low limit (<3.0V)'},

    // 2.4KW SPM2 : 428 A, B
    {command: 0x428, flag: 'A', code: 0x80000000, level: 4, tag: 'StartStateTimeOut', des: 'Start state has taken too long to complete (>60s)'},
    {command: 0x428, flag: 'A', code: 0x40000000, level: 4, tag: 'StopStateTimeOut', des: 'Stop state has taken too long to complete (>120s)'},
    {command: 0x428, flag: 'A', code: 0x20000000, level: 4, tag: 'AirFlowPowerFault', des: 'Fan powered but no electrical output (Fan power >5W)'},
    {command: 0x428, flag: 'A', code: 0x10000000, level: 4, tag: 'StackUnderVoltage', des: 'Fuel inlet open but no volts on stack (Stack Volt <60V)'},
    {command: 0x428, flag: 'A', code: 0x08000000, level: 4, tag: 'FanDcdcFaultStartFailed', des: 'DCDC status is fault during start state'},
    {command: 0x428, flag: 'A', code: 0x04000000, level: 4, tag: 'FanDcdcFaultInOperation', des: 'DCDC status is fault during operation state'},
    {command: 0x428, flag: 'A', code: 0x02000000, level: 4, tag: 'PowerGoodFaultStartFailed', des: 'DCDC status is fault during start state'},
    {command: 0x428, flag: 'A', code: 0x01000000, level: 4, tag: 'PowerGoodFaultInOperation', des: 'DCDC status is fault during operation state'},
    {command: 0x428, flag: 'A', code: 0x00800000, level: 4, tag: 'PcmPwrCtrlLowPower1Indicator', des: 'Low power rail 1 is not enabled'},
    {command: 0x428, flag: 'A', code: 0x00400000, level: 4, tag: 'PcmPwrCtrlLowPower2Indicator', des: 'Low power rail 2 is not enabled'},
    {command: 0x428, flag: 'A', code: 0x00200000, level: 4, tag: 'PcmPwrCtrlSafety1Indicator', des: 'Safety 1 is not enabled'},
    {command: 0x428, flag: 'A', code: 0x00100000, level: 4, tag: 'PcmPwrCtrlSafety2Indicator', des: 'Safety 2 is not enabled'},
    {command: 0x428, flag: 'A', code: 0x00080000, level: 4, tag: 'PcmPwrCtrlLowPower1DenyStart', des: 'Low power rail 1 is not enabled, before start command'},
    {command: 0x428, flag: 'A', code: 0x00040000, level: 4, tag: 'PcmPwrCtrlLowPower2DenyStart', des: 'Low power rail 2 is not enabled, before start command'},
    {command: 0x428, flag: 'A', code: 0x00020000, level: 4, tag: 'PcmPwrCtrlSafety1DenyStart', des: 'Safety 1 is not enabled, before start command'},
    {command: 0x428, flag: 'A', code: 0x00010000, level: 4, tag: 'PcmPwrCtrlSafety2DenyStart', des: 'Safety 2 is not enabled, before start command'},
    {command: 0x428, flag: 'A', code: 0x00008000, level: 4, tag: 'FanPulseLoadOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 0x428, flag: 'A', code: 0x00004000, level: 4, tag: 'StackSensor1OverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 0x428, flag: 'A', code: 0x00002000, level: 4, tag: 'StackSensor2OverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 0x428, flag: 'A', code: 0x00001000, level: 4, tag: 'negativeEndPlateHeaterOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 0x428, flag: 'A', code: 0x00000800, level: 4, tag: 'PositiveEndPlateHeaterOverTemperature', des: 'Hardware over temperature trip detected'},
    {command: 0x428, flag: 'A', code: 0x00000400, level: 4, tag: 'FanDcdcEnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x428, flag: 'A', code: 0x00000200, level: 4, tag: 'FanPulseLoadEnableOutputCheck', des: 'Digital output tell-back not the same as set (externally held: processor not powerful enough to set voltage correctly)'},
    {command: 0x428, flag: 'A', code: 0x00000100, level: 4, tag: 'LoadDcdcEnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x428, flag: 'A', code: 0x00000080, level: 4, tag: 'StackTxSensor1Fault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 0x428, flag: 'A', code: 0x00000040, level: 4, tag: 'StackTxSensor2Fault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 0x428, flag: 'A', code: 0x00000020, level: 4, tag: 'NegativeEndPlateTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 0x428, flag: 'A', code: 0x00000010, level: 4, tag: 'PositiveEndPlateTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 0x428, flag: 'A', code: 0x00000008, level: 4, tag: 'AirInletTxSensorFault', des: 'Sensor Reading out of bounds (<-20°C or >90°C)'},
    {command: 0x428, flag: 'A', code: 0x00000004, level: 4, tag: 'DisableBatteryOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x428, flag: 'A', code: 0x00000002, level: 4, tag: 'DisableExternalOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x428, flag: 'A', code: 0x00000001, level: 4, tag: 'EnableStackOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x428, flag: 'B', code: 0x80000000, level: 4, tag: 'StackTxSensor1OverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 0x428, flag: 'B', code: 0x40000000, level: 4, tag: 'StackTxSensor2OverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 0x428, flag: 'B', code: 0x20000000, level: 4, tag: 'NegativeEndPlateTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 0x428, flag: 'B', code: 0x10000000, level: 4, tag: 'PositiveEndPlateTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 0x428, flag: 'B', code: 0x08000000, level: 4, tag: 'AirInletTxSensorOverTemperature', des: 'Above temperature limit (>58°C)'},
    {command: 0x428, flag: 'B', code: 0x04000000, level: 4, tag: 'Rail5V1EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x428, flag: 'B', code: 0x02000000, level: 4, tag: 'Rail5V2EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x428, flag: 'B', code: 0x01000000, level: 4, tag: 'Rail12V1EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x428, flag: 'B', code: 0x00800000, level: 4, tag: 'Rail12V2EnableOutputCheck', des: 'Digital output tell-back not the same as set'},
    {command: 0x428, flag: 'B', code: 0x00400000, level: 4, tag: 'StackOverVoltage', des: 'Stack voltage above threshold (>80V)'},
    {command: 0x428, flag: 'B', code: 0x00200000, level: 4, tag: 'StackUnderVoltage', des: 'Stack voltage below threshold when fuel on and not POC (<36V)'},
    {command: 0x428, flag: 'B', code: 0x00100000, level: 4, tag: 'StackOutputOverCurrent', des: 'Stack output current above DCDC input max current (>40A)'},
    {command: 0x428, flag: 'B', code: 0x00080000, level: 4, tag: 'UnexpectedStackOutputCurrent', des: 'Stack output current above threshold when the DCDC is not enabled (>3A)'},
    {command: 0x428, flag: 'B', code: 0x00040000, level: 4, tag: 'FanPulseOverCurrent', des: 'Current to POC load resistor above threshold (>250A)'},
    {command: 0x428, flag: 'B', code: 0x00020000, level: 4, tag: 'UnexpectedFanPulseCurrent', des: 'POC load resistor current above threshold when the load resistor is not enabled (>3A)'},
    {command: 0x428, flag: 'B', code: 0x00010000, level: 4, tag: 'LoadDcdcOutputOverVoltage', des: 'Output voltage is above threshold (>85A)'},
    {command: 0x428, flag: 'B', code: 0x00008000, level: 4, tag: 'LoadDcdcOutputControlLost', des: 'Output voltage is significantly above set point (>3A than setpoint)'},
    {command: 0x428, flag: 'B', code: 0x00004000, level: 4, tag: 'LoadDcdc1OuputCheck', des: 'SPM 1 Output voltage below setpoint (difference >2V) AND output current is below limit (difference >3A). Implies a DCDC output power fault'},
    {command: 0x428, flag: 'B', code: 0x00002000, level: 4, tag: 'LoadDcdc2OuputCheck', des: 'SPM2 Output voltage below setpoint (difference >2V) AND output current is below limit (difference >3A). Implies a DCDC output power fault'},
    {command: 0x428, flag: 'B', code: 0x00001000, level: 4, tag: 'BalanceOfPlantUVWarningA', des: 'BOP voltage below limit (<35V)'},
    {command: 0x428, flag: 'B', code: 0x00000800, level: 4, tag: 'BalanceOfPlantUVDenyStart', des: 'BOP Under Voltage before start command (<35V) (this will deny start)'},
    {command: 0x428, flag: 'B', code: 0x00000400, level: 4, tag: 'BalanceOfPlantUVWarningB', des: 'BOP Under Voltage warning flag (<30V)'},
    {command: 0x428, flag: 'B', code: 0x00000200, level: 4, tag: 'FanEscTelemetryCrcFault', des: 'Fan ESC telemetry fault'},
    {command: 0x428, flag: 'B', code: 0x00000100, level: 4, tag: 'FanEscTelemetryNoMessages', des: 'No communications from the fan ESC'},
    {command: 0x428, flag: 'B', code: 0x00000080, level: 4, tag: 'HighFanPulseCurrentAbort', des: 'POC aborted due to higher than expected current (<45V)'},
    {command: 0x428, flag: 'B', code: 0x00000040, level: 4, tag: 'SafetyPairFault', des: 'Both safety voltages lost from SPM while SPM is operating'},
    {command: 0x428, flag: 'B', code: 0x00000020, level: 4, tag: 'SelfArbitrate', des: 'Communications from PCM lost'},
    {command: 0x428, flag: 'B', code: 0x00000010, level: 4, tag: 'PcmCommsLostShutdown', des: 'Cyclic Counter lost'},
    {command: 0x428, flag: 'B', code: 0x00000008, level: 4, tag: 'FanEscTelemetryStartFailed', des: 'Esc Telemetry failed during start up'},
    {command: 0x428, flag: 'B', code: 0x00000004, level: 4, tag: 'MaxCooling', des: 'Fans are at their maximum set point'},
    {command: 0x428, flag: 'B', code: 0x00000001, level: 4, tag: 'EngineeringFault', des: 'Development warning flag'},

    // 2.4KW SPM2 : 430 C, D
    {command: 0x430, flag: 'D', code: 0x80000000, level: 4, tag: 'SoftwareTripShutdown', des: 'Software Shutdown'},
    {command: 0x430, flag: 'D', code: 0x40000000, level: 4, tag: 'Adc12BufferOverwritten', des: 'ADC Buffer fault'},
    {command: 0x430, flag: 'D', code: 0x20000000, level: 4, tag: 'PcmRxCommsLengthFault', des: 'PCM Coms Fault'},
    {command: 0x430, flag: 'D', code: 0x10000000, level: 4, tag: 'PcmRxCyclicCounterFault', des: 'PCM Coms Fault'},
    {command: 0x430, flag: 'D', code: 0x08000000, level: 4, tag: 'PcmRxLineCounterFault', des: 'PCM Coms Fault'},
    {command: 0x430, flag: 'D', code: 0x04000000, level: 4, tag: 'PcmRxShortLengthFault', des: 'PCM Coms Fault'},
    {command: 0x430, flag: 'D', code: 0x02000000, level: 4, tag: 'PcmRxDecodeEncodeFault', des: 'PCM Coms Fault'},
    {command: 0x430, flag: 'D', code: 0x01000000, level: 4, tag: 'PcmRxecodeCrcFault', des: 'PCM Coms Fault'},
    {command: 0x430, flag: 'D', code: 0x00800000, level: 4, tag: 'PcmTxLengthFault', des: 'PCM Coms Fault'},
    {command: 0x430, flag: 'D', code: 0x00400000, level: 4, tag: 'PcmTxEncodeFault', des: 'PCM Coms Fault'},
    {command: 0x430, flag: 'D', code: 0x00200000, level: 4, tag: 'I2c1AckFailure', des: 'I2C fault'},
    {command: 0x430, flag: 'D', code: 0x00100000, level: 4, tag: 'I2c1ArbitrationLost', des: 'I2C fault'},
    {command: 0x430, flag: 'D', code: 0x00080000, level: 4, tag: 'I2c1BusError', des: 'I2C fault'},
    {command: 0x430, flag: 'D', code: 0x00040000, level: 4, tag: 'I2c1Timeout', des: 'I2C fault'},
    {command: 0x430, flag: 'D', code: 0x00020000, level: 4, tag: 'Adc12MonitorHighIrqCount', des: 'ADC High fault'},
    {command: 0x430, flag: 'D', code: 0x00010000, level: 4, tag: 'Adc12MonitorLowIrqCount', des: 'ADC Low fault'},
    {command: 0x430, flag: 'D', code: 0x00008000, level: 4, tag: 'I2c2DacsAckFailure', des: 'I2C fault'},
    {command: 0x430, flag: 'D', code: 0x00004000, level: 4, tag: 'I2c2DacsArbitrationLost', des: 'I2C fault'},
    {command: 0x430, flag: 'D', code: 0x00002000, level: 4, tag: 'I2c2DacsBusError', des: 'I2C fault'},
    {command: 0x430, flag: 'D', code: 0x00001000, level: 4, tag: 'I2c2DacsIrqCount', des: 'I2C fault'},
    {command: 0x430, flag: 'D', code: 0x00000800, level: 4, tag: 'I2c2DacsTimeout', des: 'I2C fault'},
    {command: 0x430, flag: 'D', code: 0x00000400, level: 4, tag: 'TurnaroundTimeWarning', des: 'Coms Fault'},
    {command: 0x430, flag: 'D', code: 0x00000200, level: 4, tag: 'TurnaroundTimeFault', des: 'Coms Fault'},
    {command: 0x430, flag: 'D', code: 0x00000100, level: 4, tag: 'Adc3BufferOverwritten', des: 'ADC Buffer fault'},
    {command: 0x430, flag: 'D', code: 0x00000080, level: 4, tag: 'Adc3MonitorHighIrqCount', des: 'ADC High fault'},
    {command: 0x430, flag: 'D', code: 0x00000040, level: 4, tag: 'Adc3MonitorLowIrqCount', des: 'ADC Low fault'},
    {command: 0x430, flag: 'D', code: 0x00000020, level: 4, tag: 'ReferenceVoltageHigh', des: 'Sensor reference voltage above limit (>3.35V)'},
    {command: 0x430, flag: 'D', code: 0x00000010, level: 4, tag: 'ReferenceVoltageLow', des: 'Sensor reference voltage above limit (<3.15V)'},
    {command: 0x430, flag: 'D', code: 0x00000008, level: 4, tag: 'ReferenceVoltageVeryHigh', des: 'Sensor reference voltage high limit (>3.5V)'},
    {command: 0x430, flag: 'D', code: 0x00000004, level: 4, tag: 'ReferenceVoltageVeryLow', des: 'Sensor reference voltage Low limit (<3.0V)'},
]